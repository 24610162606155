import {Injectable} from '@angular/core';
import {finalize, map, Observable} from 'rxjs';
import {
  EClientFilter,
  EClientFilterTranslation,
  ECodeTables,
  EFilterCategories,
  EIntermediaryFilter,
  EIntermediaryFilterTranslation,
  EOrgPositionFilterTranslation,
  EPortfolioFilter,
  EPortfolioFilterTranslation,
  EPositionFilter,
  EPositionFilterTranslation,
  ERangeWeightFilter,
} from '../util/enum';
import {FilterTag, IFilterTag} from '../models/filter-tag.model';
import {ConfigService} from './config.service';
import {NotificationService} from './notification.service';
import {Campaign, CodeTableEntry, Data, FilterBody, FilterService, Story} from '../api/core';
import {CodeTableService} from './code-table.service';
import {DataService} from './data.service';
import {
  AssetRange,
  FilterClient,
  FilterConfig,
  FilterIntermediaries,
  FilterOrgPosition,
  FilterPortfolio,
  FilterPosition,
  WeightRange,
} from '../models/filter.model';
import {convertFilterConfigToFilterBody} from './filter.config-body';
import {convertFilterBodyToConfig} from './filter.body-config';
import {PermissionService} from "./permission.service";
import {UiFilterConfigService} from "./ui-filter-config.service";

/**
 * Service for handling all functionality related to filters
 */
@Injectable({
  providedIn: 'root',
})
export class FilterUtilsService {
  assetClasses: CodeTableEntry[];
  currencies: CodeTableEntry[];
  regions: CodeTableEntry[];
  gics: CodeTableEntry[];

  constructor(
    protected configService: ConfigService,
    protected notificationService: NotificationService,
    protected filterService: FilterService,
    protected codeTableService: CodeTableService,
    protected dataService: DataService,
    protected permissionService: PermissionService,
 ) {
    this.codeTableService
      .getCodeTable(ECodeTables.assetClass)
      .subscribe((data) => (this.assetClasses = data));
    this.codeTableService
      .getCodeTable(ECodeTables.currency)
      .subscribe((data) => (this.currencies = data));
    this.codeTableService
      .getCodeTable(ECodeTables.gics)
      .subscribe((data) => (this.gics = data));
    this.codeTableService
      .getCodeTable(ECodeTables.region)
      .subscribe((data) => (this.regions = data));
  }

  /**
   * Generates an empty initial filter config
   */
  getInitialFilterForm(): FilterConfig {
    return {
      assetsInclude: {operator: 'mor', children: []},
      assetsExclude: {operator: 'mor', children: []},
      assetClass: {operator: 'mor', children: []},
      client: {
        ageRange: {
          from: null,
          to: null,
        },
        domiciles: [],
        gender: [],
      },
      intermediaries: {
        excludeEAM: false,
        excludeEWA: false,
      },
      currency: {operator: 'mor', children: []},
      gics: {operator: 'mor', children: []},
      portfolio: {
        allowOptOut: false,
        returnRange: {
          max: null,
          min: null,
        },
        model: [],
        portfolioType: [],
        referenceCurrency: [],
        riskRange: {
          max: null,
          min: null,
        },
        valueRange: {
          max: null,
          min: null,
        },
        liquidityRange: {
          min: null,
          max: null,
        },
        bu: [],
        waiveOfAdvice: null,
        mifid: [],
        fidleg: [],
        qualifiedInvestor: null,
        rmLocation: [],
        rmMarket: [],
        rmDepartment: null,
        manager: [],
        advisor: [],
        advisoryType: [],
        serviceCenter: [],
        businessDivision: [],
        sustainabilityProfile: [],
        riskBreachOnly: false,
        excludeRiskBreach: false,
      },
      position: {
        ids: [],
        excludedIds: [],
        assetClass: [],
        assetSubClass: [],
        assetType: [],
        ratingMoody: [],
        rating: [],
        ratingSP: [],
        sustainabilityRating: [],
        productRatingApac: [],
        productRatingMe: [],
        productInvestmentHorizon: [],
        issuerName: null,
        ratingSourceLGT: [],
        ranking: [],
        nextCallDate: {
          from: null,
          to: null,
          expires: null,
        },
        maturityDate: {
          from: null,
          to: null,
          expires: null,
        },
        value: {
          max: null,
          min: null,
        },
      },
      region: {operator: 'mor', children: []},
      orgPosition: {
        positions: [],
      },
    };
  }

  /**
   * Generates tags for currently active filter of the provided filter configuration
   * @param filterConfig Current filter configuration
   */
  getActiveFilterTags(filterConfig: FilterConfig): IFilterTag[] {
    return [
      ...this.getActiveClientFilterTags(filterConfig.client),
      ...this.getActiveIntermediaryFilterTags(filterConfig.intermediaries),
      ...this.getActivePortfolioFilterTags(filterConfig.portfolio),
      ...this.getActivePositionFilterTags(filterConfig.position),
      ...this.getActiveAssetClassFilterTags(filterConfig?.assetClass?.children),
      ...this.getActiveCurrencyFilterTags(filterConfig?.currency.children),
      ...this.getActiveRegionFilterTags(filterConfig?.region.children),
      ...this.getActiveGICSFilterTags(filterConfig.gics.children),
      ...this.getActiveAdvancedFilterTags(filterConfig?.advanced),
      ...this.getActiveAssetFilterTags(filterConfig?.assetsInclude?.children, 'assetsInclude'),
      ...this.getActiveAssetFilterTags(filterConfig?.assetsExclude?.children, 'assetsExclude'),
      ...this.getActiveOrgPositionsFilterTags(filterConfig?.orgPosition),
    ];
  }

  /**
   * Generates tags for active asset filters
   * @param filters Current asset class filters
   * @param type type translation (include|exclude)
   */
  private getActiveAssetFilterTags(filters: AssetRange[], type: string): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters == null) {
      return tags;
    }
    filters.forEach((f) => {
      if (f.range.max != null || f.range.min != null || f.hasExposure) {
        tags.push(
          new FilterTag(`${f.name} (${f.key})`, EFilterCategories.assets, f.key, undefined, type)
        );
      }
    });
    return tags;
  }

  /**
   * Generates tags for active asset class filters
   * @param filters Current asset class filters
   */
  private getActiveAssetClassFilterTags(filters: WeightRange[]): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters == null) {
      return tags;
    }
    filters.forEach((f) => {
      if (f.weight != null || f.range.max != null || f.range.min != null) {
        tags.push(
          new FilterTag(
            this.assetClasses.find((a) => a.ident === f.key).name,
            EFilterCategories.assetClass,
            f.key
          )
        );
      }
    });
    return tags;
  }

  /**
   * Generates tags for active currency filters
   * @param filters Current currency filters
   */
  private getActiveCurrencyFilterTags(filters: WeightRange[]): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters == null) {
      return tags;
    }
    filters.forEach((f) => {
      if (f.weight != null || f.range.max != null || f.range.min != null) {
        tags.push(
          new FilterTag(
            this.currencies.find((c) => c.ident === f.key).name,
            EFilterCategories.currency,
            f.key
          )
        );
      }
    });
    return tags;
  }

  /**
   * Generates tags for active gics filters
   * @param filters Current gics filters
   */
  private getActiveGICSFilterTags(filters: WeightRange[]): IFilterTag[] {
    const tags: IFilterTag[] = [];
    filters?.forEach((f) => {
      if (f.weight != null || f.range.max != null || f.range.min != null) {
        tags.push(
          new FilterTag(
            this.gics.find((c) => c.ident === f.key).name,
            EFilterCategories.sector,
            f.key
          )
        );
      }
    });
    return tags;
  }

  /**
   * Generates tags for active region filters
   * @param filters Current region filters
   */
  private getActiveRegionFilterTags(filters: WeightRange[]): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters == null) {
      return tags;
    }
    filters.forEach((f) => {
      if (f.weight != null || f.range.max != null || f.range.min != null) {
        tags.push(
          new FilterTag(
            this.regions.find((c) => c.ident === f.key).name,
            EFilterCategories.region,
            f.key
          )
        );
      }
    });
    return tags;
  }

  /**
   * Generates tags for active client filters
   * @param filters Current client filters
   */
  private getActiveClientFilterTags(filters: FilterClient): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (
      !(filters?.ageRange?.from === 0 && filters?.ageRange?.to === 100) &&
      (filters?.ageRange?.from != null || filters?.ageRange?.to != null)
    ) {
      tags.push(
        new FilterTag(
          EClientFilterTranslation.ageRange,
          EFilterCategories.client,
          EClientFilter.ageRange
        )
      );
    }
    if (filters?.domiciles?.length) {
      tags.push(
        new FilterTag(
          EClientFilterTranslation.domiciles,
          EFilterCategories.client,
          EClientFilter.domiciles
        )
      );
    }
    if (filters?.gender?.length) {
      tags.push(
        new FilterTag(
          EClientFilterTranslation.gender,
          EFilterCategories.client,
          EClientFilter.gender
        )
      );
    }
    return tags;
  }

  /**
   * Generates tags for active intermediary filters
   * @param filters Current intermediary filters
   */
  private getActiveIntermediaryFilterTags(filters: FilterIntermediaries): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters.excludeEAM) {
      tags.push(
        new FilterTag(
          EIntermediaryFilterTranslation.excludeEAM,
          EFilterCategories.intermediaries,
          EIntermediaryFilter.excludeEAM
        )
      );
    }
    if (filters.excludeEWA) {
      tags.push(
        new FilterTag(
          EIntermediaryFilterTranslation.excludeEWA,
          EFilterCategories.intermediaries,
          EIntermediaryFilter.excludeEWA
        )
      );
    }
    return tags;
  }

  /**
   * Generates tags for active portfolio filters
   * @param filters Current portfolio filters
   */
  private getActivePortfolioFilterTags(filters: FilterPortfolio): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters?.allowOptOut) {
      tags.push(
        new FilterTag(
          'opt-out',
          EFilterCategories.portfolio,
          EPortfolioFilter.allowOptOut
        )
      );
    }
    if (filters?.portfolioType?.length) {
      tags.push(
        new FilterTag(
          'type',
          EFilterCategories.portfolio,
          EPortfolioFilter.portfolioType
        )
      );
    }
    if (filters?.model?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.model,
          EFilterCategories.portfolio,
          EPortfolioFilter.model
        )
      );
    }
    if (filters?.referenceCurrency?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.referenceCurrency,
          EFilterCategories.portfolio,
          EPortfolioFilter.referenceCurrency
        )
      );
    }
    if (this.isNotEmpty(filters?.riskRange)) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.riskRange,
          EFilterCategories.portfolio,
          EPortfolioFilter.riskRange
        )
      );
    }
    if (this.isNotEmpty(filters?.returnRange)) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.returnRange,
          EFilterCategories.portfolio,
          EPortfolioFilter.returnRange
        )
      );
    }
    if (this.isNotEmpty(filters?.valueRange)) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.valueRange,
          EFilterCategories.portfolio,
          EPortfolioFilter.valueRange
        )
      );
    }
    if (this.isNotEmpty(filters?.liquidityRange)) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.liquidityRange,
          EFilterCategories.portfolio,
          EPortfolioFilter.liquidityRange
        )
      );
    }
    if (filters?.bu?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.bu,
          EFilterCategories.portfolio,
          EPortfolioFilter.bu
        )
      );
    }
    if (filters?.serviceCenter?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.serviceCenter,
          EFilterCategories.portfolio,
          EPortfolioFilter.serviceCenter
        )
      );
    }
    if (filters?.businessDivision?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.businessDivision,
          EFilterCategories.portfolio,
          EPortfolioFilter.businessDivision
        )
      );
    }
    if (filters?.advisoryType?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.advisoryType,
          EFilterCategories.portfolio,
          EPortfolioFilter.advisoryType
        )
      );
    }
    if (filters?.sustainabilityProfile?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.sustainabilityProfile,
          EFilterCategories.portfolio,
          EPortfolioFilter.sustainabilityProfile
        )
      );
    }
    if (filters?.qualifiedInvestor != null) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.qualifiedInvestor,
          EFilterCategories.portfolio,
          EPortfolioFilter.qualifiedInvestor
        )
      );
    }
    if (filters?.mifid?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.mifid,
          EFilterCategories.portfolio,
          EPortfolioFilter.mifid
        )
      );
    }
    if (filters?.fidleg?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.fidleg,
          EFilterCategories.portfolio,
          EPortfolioFilter.fidleg
        )
      );
    }
    if (filters?.waiveOfAdvice != null) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.waiveOfAdvice,
          EFilterCategories.portfolio,
          EPortfolioFilter.waiveOfAdvice
        )
      );
    }
    if (filters?.rmDepartment) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.rmDepartment,
          EFilterCategories.portfolio,
          EPortfolioFilter.rmDepartment
        )
      );
    }
    if (filters?.rmLocation?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.rmLocation,
          EFilterCategories.portfolio,
          EPortfolioFilter.rmLocation
        )
      );
    }
    if (filters?.rmMarket?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.rmMarket,
          EFilterCategories.portfolio,
          EPortfolioFilter.rmMarket
        )
      );
    }
    if (filters?.manager?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.manager,
          EFilterCategories.portfolio,
          EPortfolioFilter.manager
        )
      );
    }
    if (filters?.advisor?.length) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.advisor,
          EFilterCategories.portfolio,
          EPortfolioFilter.advisor
        )
      );
    }
    if (filters?.riskBreachOnly) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.riskBreachOnly,
          EFilterCategories.portfolio,
          EPortfolioFilter.riskBreachOnly
        )
      );
    }
    if (filters?.excludeRiskBreach) {
      tags.push(
        new FilterTag(
          EPortfolioFilterTranslation.excludeRiskBreach,
          EFilterCategories.portfolio,
          EPortfolioFilter.excludeRiskBreach
        )
      );
    }

    return tags;
  }

  /**
   * Generates tags for active position filters
   * @param filters Current position filters
   */
  private getActivePositionFilterTags(filters: FilterPosition): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters?.value?.max || filters?.value?.min) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.value,
          EFilterCategories.position,
          EPositionFilter.value
        )
      );
    }
    if (filters?.assetClass?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.assetClass,
          EFilterCategories.position,
          EPositionFilter.assetClass
        )
      );
    }
    if (filters?.assetSubClass?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.assetSubClass,
          EFilterCategories.position,
          EPositionFilter.assetSubClass
        )
      );
    }
    if (filters?.assetType?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.assetType,
          EFilterCategories.position,
          EPositionFilter.assetType
        )
      );
    }
    if (filters?.ids?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.ids,
          EFilterCategories.position,
          EPositionFilter.ids
        )
      );
    }
    if (filters?.excludedIds?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.excludedIds,
          EFilterCategories.position,
          EPositionFilter.excludeIds
        )
      );
    }
    if (filters?.ratingMoody?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.ratingMoody,
          EFilterCategories.position,
          EPositionFilter.ratingMoody
        )
      );
    }
    if (filters?.rating?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.rating,
          EFilterCategories.position,
          EPositionFilter.rating
        )
      );
    }
    if (filters?.ratingSP?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.ratingSP,
          EFilterCategories.position,
          EPositionFilter.ratingSP
        )
      );
    }
    if (filters?.sustainabilityRating?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.sustainabilityRating,
          EFilterCategories.position,
          EPositionFilter.sustainabilityRating
        )
      );
    }
    if (filters?.productRatingApac?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.productRatingApac,
          EFilterCategories.position,
          EPositionFilter.productRatingApac
        )
      );
    }
    if (filters?.productRatingMe?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.productRatingMe,
          EFilterCategories.position,
          EPositionFilter.productRatingMe
        )
      );
    }
    if (filters?.productInvestmentHorizon?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.productInvestmentHorizon,
          EFilterCategories.position,
          EPositionFilter.productInvestmentHorizon
        )
      );
    }
    if (filters?.issuerName) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.issuerName,
          EFilterCategories.position,
          EPositionFilter.issuerName
        )
      );
    }
    if (filters?.ranking?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.ranking,
          EFilterCategories.position,
          EPositionFilter.ranking
        )
      );
    }
    if (filters?.ratingSourceLGT?.length) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.ratingSourceLGT,
          EFilterCategories.position,
          EPositionFilter.ratingSourceLGT
        )
      );
    }
    if (
      filters?.nextCallDate?.to ||
      filters?.nextCallDate?.from ||
      filters?.nextCallDate?.expires
    ) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.nextCallDate,
          EFilterCategories.position,
          EPositionFilter.nextCallDate
        )
      );
    }
    if (
      filters?.maturityDate?.to ||
      filters?.maturityDate?.from ||
      filters?.maturityDate?.expires
    ) {
      tags.push(
        new FilterTag(
          EPositionFilterTranslation.maturityDate,
          EFilterCategories.position,
          EPositionFilter.maturityDate
        )
      );
    }
    return tags;
  }


  /**
   * Generates tags for active OrgPosition filters
   * @param filters Current OrgPosition filters
   */
  private getActiveOrgPositionsFilterTags(filters: FilterOrgPosition): IFilterTag[] {
    const tags: IFilterTag[] = [];
    if (filters?.positions?.length) {
      tags.push(
        new FilterTag(
          EOrgPositionFilterTranslation.orgPosition,
          EFilterCategories.orgPosition,
          EOrgPositionFilterTranslation.orgPosition,
        )
      );
    }
    return tags;
  }

  private getActiveAdvancedFilterTags(filters: object): IFilterTag[] {
    if (filters) {
      return [
        new FilterTag(
          'Advanced filter',
          EFilterCategories.advanced,
          'advanced'
        ),
      ];
    }
    return [];
  }

  upsertFilter(
    story: Story,
    campaign: Campaign,
    inputConfig: FilterConfig
  ): Observable<FilterConfig> {
    const filterConfig = JSON.parse(JSON.stringify(inputConfig));
    filterConfig.id = story?.filter || campaign?.filter;
    const filterBody = convertFilterConfigToFilterBody(
      filterConfig,
      this.permissionService.userRoleData);
    const upsertFilter = campaign
      ? this.filterService.upsertCampaignFilter.bind(this.filterService, campaign.id)
      : this.filterService.upsertStoryFilter.bind(this.filterService, story.id);
    return upsertFilter(filterBody)
      .pipe(map(v => convertFilterBodyToConfig(v)));
  }

  getStatFromFilters(hub: string, filterConfig: FilterConfig, ...options: string[]): Observable<Data> {
    this.dataService.updateChartDataLoading(true);
    const filterBody: FilterBody =
      convertFilterConfigToFilterBody(
        filterConfig,
        this.permissionService.userRoleData);
    return this.filterService
      .getStatFromFilters(hub, filterBody, options.join(','))
      .pipe(finalize(() => this.dataService.updateChartDataLoading(false)));
  }

  getCampaignFilterById(campaignId: number): Observable<FilterConfig> {
    return this.filterService.getCampaignFilterById(campaignId)
      .pipe(map(v => convertFilterBodyToConfig(v)));
  }

  getStoryFilterById(storyId: number): Observable<FilterConfig> {
    return this.filterService.getStoryFilterById(storyId)
      .pipe(map(v => convertFilterBodyToConfig(v)));
  }

  private isNotEmpty(range: DoubleRange | undefined) {
    return (range?.max || range?.max === 0) || (range?.min || range?.min === 0);
  }
}

export function isFilterRangeWeightFilterActivePipe(
  filterConfigService: UiFilterConfigService,
  filter: ERangeWeightFilter,
  filterCategory: EFilterCategories
): boolean {
  const config = filterConfigService.getConfigFlags();
  switch (filter) {
    case ERangeWeightFilter.exposure:
      switch (filterCategory) {
        case EFilterCategories.assetClass:
          return config.assetClass.filters.exposure;
        case EFilterCategories.currency:
          return config.currency.filters.exposure;
        case EFilterCategories.region:
          return config.region.filters.exposure;
        case EFilterCategories.sector:
          return config.sector.filters.exposure;
        default:
          return false;
      }
    case ERangeWeightFilter.weight:
      switch (filterCategory) {
        case EFilterCategories.assetClass:
          return config.assetClass.filters.weight;
        case EFilterCategories.currency:
          return config.currency.filters.weight;
        case EFilterCategories.region:
          return config.region.filters.weight;
        case EFilterCategories.sector:
          return config.sector.filters.weight;
        default:
          return false;
      }
    default:
      return false;
  }
}

export function isFilterCategoryActivePipe(
  filterConfigService: UiFilterConfigService,
  category: EFilterCategories
): boolean {
  const config = filterConfigService.getConfigFlags();
  switch (category) {
    case EFilterCategories.client:
      return config.client.active;
    case EFilterCategories.intermediaries:
      return config.intermediaries.active;
    case EFilterCategories.portfolio:
      return config.portfolio.active;
    case EFilterCategories.position:
      return config.position.active;
    case EFilterCategories.assets:
      return config.asset.active;
    case EFilterCategories.assetClass:
      return config.assetClass.active;
    case EFilterCategories.currency:
      return config.currency.active;
    case EFilterCategories.region:
      return config.region.active;
    case EFilterCategories.sector:
      return config.sector.active;
    case EFilterCategories.orgPosition:
      return config.orgPositions.active;
    case EFilterCategories.advanced:
      return config.advanced.active;
    default:
      return false;
  }
}
