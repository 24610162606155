import {Injectable} from '@angular/core';
import {FilterConfigEntry} from "../api/core";

export interface FilterConfigFlagsEntry {
  [key: string]: boolean;
}

export interface FilterConfigFlagsSection {
  active: boolean;
  filters?: FilterConfigFlagsEntry;
}

export interface FilterConfigFlagsAdvanced {
  active: boolean;
  client: FilterConfigFlagsSection;
  portfolio: FilterConfigFlagsSection;
  position: FilterConfigFlagsSection;
  asset: FilterConfigFlagsSection;
  assetClass: FilterConfigFlagsSection;
  currency: FilterConfigFlagsSection;
  region: FilterConfigFlagsSection;
  sector: FilterConfigFlagsSection;
}

export interface UiFilterConfigFlags {
  version: string;
  client: FilterConfigFlagsSection;
  intermediaries: FilterConfigFlagsSection;
  portfolio: FilterConfigFlagsSection;
  position: FilterConfigFlagsSection;
  asset: FilterConfigFlagsSection;
  assetClass: FilterConfigFlagsSection;
  currency: FilterConfigFlagsSection;
  region: FilterConfigFlagsSection;
  sector: FilterConfigFlagsSection;
  advanced: FilterConfigFlagsAdvanced;
  orgPositions: FilterConfigFlagsSection;
}

const FILTER_SECTIONS = ['client', 'portfolio', 'intermediaries', 'position', 'asset', 'assetClass', 'currency', 'region', 'sector', 'orgPositions'];

@Injectable({providedIn: 'root'})
export class UiFilterConfigService {
  private filterConfigFlags: UiFilterConfigFlags = {
    version: 'v1.0',
    client: {
      active: true,
      filters: {
        ageRange: true,
        domiciles: true,
        gender: true,
      },
    },
    intermediaries: {
      active: true,
      filters: {
        excludeAll: true,
        excludeEWA: true,
        excludeEAM: true,
      },
    },
    portfolio: {
      active: true,
      filters: {
        advisoryType: true,
        bu: true,
        businessDivision: true,
        fidleg: true,
        manager: true,
        advisor: true,
        mifid: true,
        model: true,
        portfolioType: true,
        qualifiedInvestor: true,
        referenceCurrency: true,
        returnRange: true,
        riskRange: true,
        rmDepartment: true,
        rmLocation: true,
        rmMarket: true,
        serviceCenter: true,
        sustainabilityProfile: true,
        valueRange: true,
        liquidityRange: true,
        waiveOfAdvice: true,
        allowOptOut: true,
      },
    },
    position: {
      active: true,
      filters: {
        assetClass: true,
        assetSubClass: true,
        assetType: true,
        bestPerformance: false,
        excludeIds: true,
        ids: true,
        nextCallDate: true,
        maturityDate: true,
        ranking: true,
        ratingMoody: true,
        ratingSourceLGT: true,
        ratingSP: true,
        rating: true,
        sustainabilityRating: true,
        worstPerformance: false,
        subclass: true,
        riskBreachOnly: true,
        excludeRiskBreach: true,
        value: true,
      },
    },
    asset: {
      active: true,
    },
    assetClass: {
      active: true,
      filters: {
        exposure: true,
        weight: false,
      },
    },
    currency: {
      active: true,
      filters: {
        exposure: true,
        weight: false,
      },
    },
    region: {
      active: true,
      filters: {
        exposure: true,
        weight: false,
      },
    },
    sector: {
      active: true,
      filters: {
        exposure: true,
        weight: false,
      },
    },
    orgPositions: {
      active: true,
    },
    advanced: {
      active: true,
      client: {
        active: true,
        filters: {
          ageRange: true,
          domiciles: true,
          gender: true,
          allowOptOut: false, // Disable opt-out filtering as described in https://github.com/confinale/aspark/issues/3909
        },
      },
      portfolio: {
        active: true,
        filters: {
          advisoryType: true,
          bu: true,
          businessDivision: true,
          fidleg: true,
          mifid: true,
          model: true,
          portfolioType: true,
          qualifiedInvestor: true,
          referenceCurrency: true,
          returnRange: true,
          riskRange: true,
          rmDepartment: true,
          rmLocation: true,
          rmMarket: true,
          serviceCenter: true,
          sustainabilityProfile: true,
          valueRange: true,
          liquidityRange: true,
          waiveOfAdvice: true,
          optOut: true,
          riskBreachOnly: true,
          excludeRiskBreach: true,
          manager: true,
        },
      },
      position: {
        active: true,
        filters: {
          assetClass: true,
          assetType: true,
          bestPerformance: false,
          nextCallDate: true,
          ranking: true,
          ratingMoody: true,
          ratingSourceLGT: true,
          rating: true,
          ratingSP: true,
          sustainabilityRating: true,
          worstPerformance: false,
          assetSubClass: true,
          ids: true,
          excludedIds: true,
          maturityDate: true,
          riskBreachOnly: true,
          excludeRiskBreach: true,
          value: true,
          valueRange: true,
          performance: true,
          productRatingApac: true,
          productRatingMe: true,
          productInvestmentHorizon: true,
          issuerName: true,
        },
      },
      asset: {
        active: true,
      },
      assetClass: {
        active: true,
        filters: {
          exposure: true,
          weight: false,
        },
      },
      currency: {
        active: true,
        filters: {
          exposure: true,
          weight: false,
        },
      },
      region: {
        active: true,
        filters: {
          exposure: true,
          weight: false,
        },
      },
      sector: {
        active: true,
        filters: {
          exposure: true,
          weight: false,
        },
      },
    },
  };

  getConfigFlags(): UiFilterConfigFlags {
    return this.filterConfigFlags;
  }

  /**
   * Updates the ui filter config flags depending on enabled feature flags
   * @param filterConfigs
   */
  updateConfigFlags(filterConfigs: FilterConfigEntry[]) {
    this.applyFilterConfigs(filterConfigs);
  }

  /**
   * Applies the user-specific filter configs to the filter config flags that will enable or disable all different filters
   * @param filterConfigs
   * @private
   */
  private applyFilterConfigs(filterConfigs: FilterConfigEntry[]) {
    FILTER_SECTIONS.forEach(section => {
      const inputConfig = filterConfigs.find((f) => f.ident === section);
      const filterConfigFlags = this.filterConfigFlags[section];
      filterConfigFlags.active = inputConfig.enabled;
      inputConfig.children.forEach(inputChildConfig => {
        filterConfigFlags.filters[inputChildConfig.ident] = inputChildConfig.enabled;
      })
    });
  }
}
