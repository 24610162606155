import {Component, OnDestroy, OnInit} from "@angular/core";
import {PublicationTypeData} from "../../publication-type-data";
import {
  CodeTableEntry,
  PublicationType,
  PublicationTypeEditMainData,
  PublicationTypeService,
  PublicationTypeTimeFrame
} from "../../../api/core";
import {finalize, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ECodeTables} from "../../../util/enum";
import {CodeTableService} from "../../../services/code-table.service";
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";

type TimeFrame = {
  text: string;
  value: PublicationTypeTimeFrame;
  showValue: boolean;
};

@Component({
  selector: 'app-publication-type-main-data',
  templateUrl: './publication-type-main-data.component.html'
})
export class PublicationTypeMainDataComponent implements OnInit, OnDestroy {
  publicationType: PublicationType;
  hubs: CodeTableEntry[];
  timeFrames: TimeFrame[] = Object.values(PublicationTypeTimeFrame).map(tf => this.newTimeFrame(tf));
  currentTimeFrame = this.timeFrames[0];
  subscriptions: Subscription[] = [];
  readonly: boolean = false;

  formGroup = this.fb.group({
    nameEn: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    nameDe: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    hubs: new FormControl([], [
      Validators.required,
    ]),
    advised: new FormControl(false, [
      Validators.required,
    ]),
    timeFrame: new FormControl('NONE', [
      Validators.required,
    ]),
    timeValue: new FormControl(0, [
      Validators.required,
    ]),
  });

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
    readonly publicationTypeService: PublicationTypeService,
    readonly codeTableService: CodeTableService,
    readonly permissionService: PermissionService,
  ) {
    this.subscriptions.push(this.publicationTypeData.pubType$.subscribe(data => {
      this.setPubType(data)
    }));
    codeTableService.getCodeTable(ECodeTables.hub).subscribe(hubs => {
      this.hubs = hubs;
    });
  }

  ngOnInit() {
    if (!this.permissionService.hasAnyPermission(EProtectedActions.publicationTypeEdit)) {
      Object.keys(this.formGroup.controls).forEach(field => {
        this.formGroup.get(field).disable();
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private newTimeFrame(value: PublicationTypeTimeFrame): TimeFrame {
    return {
      text: this.publicationTypeData.timeFrameTranslationKey(value),
      value,
      showValue: this.publicationTypeData.timeFrameHasValue(value),
    }
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    this.readonly = this.publicationTypeData.isReadonly(data);
    this.formGroup.patchValue({
      ...data,
      hubs: data.hubs.map(h => h.ident),
    });
    this.publicationTypeData.getFormControls(this.formGroup, 'nameEn', 'nameDe', 'timeFrame', 'timeValue', 'advised')
      .forEach(ctrl => this.readonly ? ctrl.disable() : ctrl.enable());
    this.currentTimeFrame = this.timeFrames.find(tf => tf.value == data.timeFrame);
  }

  onSave(field: string) {
    const values = this.formGroup.getRawValue();
    switch (field) {
      case 'timeFrame':
        this.currentTimeFrame = this.timeFrames.find(tf => tf.value == values.timeFrame);
        break;
    }
    this.publicationTypeData.setLoading(true);
    this.publicationTypeService.editPageMainData(this.publicationType.id, values as PublicationTypeEditMainData)
      .pipe(
        finalize(() => {
          this.publicationTypeData.setLoading(false);
        })
      ).subscribe(data => {
      this.publicationTypeData.setPublicationType(data);
    });
  }

  hasHub(hub: string) {
    return this.publicationType.hubs.find(h => h.ident == hub) !== undefined;
  }

  onHub(hub: string) {
    const values = this.formGroup.getRawValue();
    const hubs: string[] = values.hubs;
    const hasHub = hubs.indexOf(hub) >= 0;
    this.formGroup.patchValue({
      hubs: hasHub ? hubs.filter(h => h != hub) : [...hubs, hub]
    });
    this.onSave('hubs');
  }
}
