<app-card>
  <div card-header>
    <div class="header-container">
      <mat-tab-group (selectedIndexChange)="onTabChange($event)">
        <mat-tab label="{{ 'directDelivery' | translate }}"></mat-tab>
        <mat-tab label="Opt Out"></mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div card-body>
    <ng-container [ngSwitch]="selectedTabIndex">
      <div *ngSwitchCase="0" class="tab-content">
        <div *ngFor="let item of publicationTypeDirectSend; let i = index">
          <mat-slide-toggle
            color="primary"
            [checked]="item.publicationTypeActive"
            (change)="onDirectSendToggleChanged($event, item)"
            [disabled]="!canEdit"
          >
            {{item.type.name}}
          </mat-slide-toggle>
        </div>
        <div id="explanation" *ngIf="showNote">
          <span>{{ 'directDeliveryExplanationClient' | translate }}</span>
        </div>
      </div>
      <div *ngSwitchCase="1" class="tab-content">
        <div *ngFor="let item of publicationTypeOptOut; let i = index">
          <mat-slide-toggle
            color="primary"
            [checked]="item.publicationTypeActive"
            (change)="onOptOutToggleChanged($event, item)"
            [disabled]="!canEdit"
          >
            {{item.type.name}}
          </mat-slide-toggle>
        </div>
      </div>
    </ng-container>
  </div>
</app-card>
