/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PublicationTypeTimeFrame = 'NONE' | 'NUMBER_OF_DAYS' | 'NUMBER_OF_WEEKS' | 'NUMBER_OF_MONTHS' | 'END_OF_CURRENT_MONTH' | 'END_OF_NEXT_MONTH' | 'START_OF_NEXT_MONTH';

export const PublicationTypeTimeFrame = {
    NONE: 'NONE' as PublicationTypeTimeFrame,
    NUMBEROFDAYS: 'NUMBER_OF_DAYS' as PublicationTypeTimeFrame,
    NUMBEROFWEEKS: 'NUMBER_OF_WEEKS' as PublicationTypeTimeFrame,
    NUMBEROFMONTHS: 'NUMBER_OF_MONTHS' as PublicationTypeTimeFrame,
    ENDOFCURRENTMONTH: 'END_OF_CURRENT_MONTH' as PublicationTypeTimeFrame,
    ENDOFNEXTMONTH: 'END_OF_NEXT_MONTH' as PublicationTypeTimeFrame,
    STARTOFNEXTMONTH: 'START_OF_NEXT_MONTH' as PublicationTypeTimeFrame
};

