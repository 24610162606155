import {Component, OnDestroy, OnInit} from "@angular/core";
import {finalize, Subscription} from "rxjs";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {PublicationTypeData} from "../../publication-type-data";
import {
  PublicationType,
  PublicationTypeEditSendControls, PublicationTypeSalutation,
  PublicationTypeSendApproach, PublicationTypeSender,
  PublicationTypeService
} from "../../../api/core";
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";

type Option = {
  key: string;
  value: string;
}
const sendApproachKeys: Record<PublicationTypeSendApproach, string> = {
  FORCED_DIRECT: 'publicationTypeSendApproachForcedDirect',
  VIA_RM: 'publicationTypeSendApproachViaRm'
};
const senderKeys: Record<PublicationTypeSender, string> = {
  CENTRAL: 'publicationTypeSenderCentral',
  RM: 'publicationTypeSenderRm',
}
const salutationKeys: Record<PublicationTypeSalutation, string> = {
  GENERIC: 'publicationTypeSalutationGeneric',
  PERSONALIZED: 'publicationTypeSalutationPersonalized',
}

@Component({
  selector: 'app-publication-type-send-controls',
  templateUrl: './publication-type-send-controls.component.html'
})
export class PublicationTypeSendControlsComponent implements OnInit, OnDestroy {
  publicationType: PublicationType;
  sendApproachOptions: Option[] = Object.values(PublicationTypeSendApproach)
    .map(key => ({key: sendApproachKeys[key], value: key}));
  senderOptions: Option[] = Object.values(PublicationTypeSender)
    .map(key => ({key: senderKeys[key], value: key}));
  salutationOptions: Option[] = Object.values(PublicationTypeSalutation)
    .map(key => ({key: salutationKeys[key], value: key}));
  senderIsCentral: boolean = false;
  readonly: boolean;

  formGroup = this.fb.group({
    sendApproach: new FormControl('FORCED_DIRECT', [Validators.required]),
    sender: new FormControl('RM', [Validators.required]),
    senderEmail: new FormControl('', [Validators.email, Validators.maxLength(512)]),
    senderName: new FormControl('', [Validators.maxLength(255)]),
    salutation: new FormControl('GENERIC', [Validators.required]),
  });
  subscriptions: Subscription[] = [];

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
    readonly publicationTypeService: PublicationTypeService,
    readonly permissionService: PermissionService,
  ) {
    this.subscriptions.push(
      this.publicationTypeData.pubType$.subscribe(data => this.setPubType(data)),
    );
  }

  ngOnInit() {
    if (!this.permissionService.hasAnyPermission(EProtectedActions.publicationTypeEdit)) {
      Object.keys(this.formGroup.controls).forEach(field => {
        this.formGroup.get(field).disable();
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    this.senderIsCentral = data.sender == 'CENTRAL';
    this.readonly = this.publicationTypeData.isReadonly(data);
    this.formGroup.patchValue(data);
    this.publicationTypeData.getFormControls(this.formGroup, 'sendApproach', 'sender', 'senderName', 'senderEmail', 'salutation')
      .forEach(ctrl => this.readonly ? ctrl.disable() : ctrl.enable());
  }

  onSave(field: string) {
    if (!this.formGroup.valid) return;
    const values = this.formGroup.getRawValue() as PublicationTypeEditSendControls;
    this.publicationTypeData.setLoading(true);
    this.publicationTypeService.editPageSendControls(this.publicationType.id, values)
      .pipe(
        finalize(() => {
          this.publicationTypeData.setLoading(false);
        })
      ).subscribe(data => {
      this.publicationTypeData.setPublicationType(data);
    });
  }

}
