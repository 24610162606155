import {AfterViewInit, Component, Inject, OnInit} from "@angular/core";
import {CodeTableEntry, MonitoringBucket, Salutation} from "../../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {EModalType} from "../../../../../util/enum";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {codeTableEntries} from "../../../../../services/code-table.service";

interface Selected {
  pt: CodeTableEntry;
  selected: boolean;
}

@Component({
  selector: 'app-bucket-dialog',
  templateUrl: './bucket-dialog.component.html'
})
export class BucketDialogComponent implements AfterViewInit, ModalSubComponent {

  readonly title: string;
  hubs: CodeTableEntry[];
  publicationTypes: CodeTableEntry[];
  readonly bucket: MonitoringBucket;
  readonly isCreating: boolean;
  readonly hidePubTypes: boolean;
  formGroup: FormGroup;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly toastService: ToastrService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      bucket: MonitoringBucket;
      hubs: CodeTableEntry[];
      publicationTypes: CodeTableEntry[];
      hidePubTypes?: boolean;
    } }
  ) {
    const bucket = this.bucket = data.data.bucket;
    this.isCreating = !bucket.id;
    this.hidePubTypes = !!data.data.hidePubTypes
    this.hubs = data.data.hubs;
    this.publicationTypes = data.data.publicationTypes;
    this.title = bucket.id ? 'bucketEdit' : 'bucketAdd';
    this.formGroup = this.fb.group({
      name: new FormControl(
        bucket.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(500)
        ]
      ),
      hub: new FormControl(
        this.hubs.find(b => b.id == bucket.hub?.id),
        Validators.required,
      ),
      publicationTypes: new FormControl(
        bucket.publicationTypes.map(
          pt => this.publicationTypes.find(p => p.id === pt.id)
        )
      ),
    });
    this.formGroup.valueChanges.subscribe(() => this.verifyButtons());
    if (!this.isCreating)
      this.formGroup.controls["hub"].disable();
    this.updateCodeTableEntries();
  }

  protected updateCodeTableEntries() {
    this.publicationTypes = codeTableEntries(this.publicationTypes, this.formGroup.value.publicationTypes);
    this.hubs = codeTableEntries(this.hubs, this.formGroup.value.hub);
  }

  ngAfterViewInit() {
    setTimeout(() => this.verifyButtons(), 100);
  }

  modalAction(modalType: EModalType | undefined) {
    const bucket = this.getBucketFromForm();
    const err = this.checkBucket(bucket);
    if (err) {
      this.toastService.info(
        this.translateService.instant(err)
      );
      return;
    }
    this.dialogRef.close(bucket);
  }

  private getBucketFromForm(): MonitoringBucket {
    const name: string = this.formGroup.get('name').value;
    const hub: CodeTableEntry = this.formGroup.get('hub').value;
    const publicationTypes: CodeTableEntry[] = this.formGroup.get('publicationTypes').value;
    return {
      id: this.bucket.id,
      name,
      hub,
      publicationTypes
    };
  }

  private checkBucket(bucket: MonitoringBucket): string {
    if (!bucket.name) return 'bucketNameRequired';
    if (!bucket.hub) return 'bucketHubRequired';
    if (!bucket.publicationTypes.length) return 'bucketPublicationTypeRequired';
    return '';
  }

  verifyButtons() {
    const bucketDialog = document.querySelector('app-bucket-dialog');
    const dialog = bucketDialog?.parentElement?.parentElement;
    const okButton = dialog?.querySelector('button[color="primary"]')
    if (!okButton) return;
    const bucket = this.getBucketFromForm();
    const err = this.checkBucket(bucket)
    if (err) {
      okButton.setAttribute('disabled', 'disabled');
    } else {
      okButton.removeAttribute('disabled');
    }
  }
}
