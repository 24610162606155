import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, filter, first, Observable, Subscription} from 'rxjs';
import {Campaign, CampaignService, CampaignStatus, UserInteraction, UserService,} from 'src/app/api/core';
import {DataService} from 'src/app/services/data.service';
import {CAMPAIGN_COMPACT_OVERVIEW_TABS,} from 'src/app/util/tab.constants';
import {Tab} from 'src/app/models/tabs.model';
import {FilterConfig} from "../../../models/filter.model";
import {PermissionService} from "../../../services/permission.service";
import {FilterUtilsService} from "../../../services/filter-utils.service";
import {ActionType} from "../campaign-overview/campaign-actions-list-utils";
import {PageBarButton} from "../../../services/page.bar.service";
import {EProtectedActions} from "../../../util/protected-actions";
import {
  CampaignIntermediaryListComponent
} from "../campaign-overview/campaign-intermediary-list/campaign-intermediary-list.component";
import {ModalData} from "../../../models/modal.model";
import {EModalType} from "../../../util/enum";
import {
  CampaignIntermediaryAddComponent
} from "../campaign-overview/campaign-intermediary-add/campaign-intermediary-add.component";
import {TranslateService} from "@ngx-translate/core";
import {DialogHeight, DialogWidth, ModalService} from "../../../services/modal.service";
import {PipeLogicService} from "../../../services/pipe.logic.service";
import {
  CampaignSendToMyselfComponent
} from "../campaign-overview/campaign-send-to-myself/campaign-send-to-myself.component";

/**
 * Component for fast-track campaign overview.
 */
@Component({
  selector: 'app-campaign-compact-overview',
  templateUrl: './campaign-compact-overview.component.html',
})
export class CampaignCompactOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(CampaignIntermediaryListComponent)
  intermediaryList: CampaignIntermediaryListComponent;

  campaign: Campaign;
  tabs: Tab[] = CAMPAIGN_COMPACT_OVERVIEW_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;
  currentFilter: FilterConfig;
  useCompactSend = false;
  private subscriptions: Subscription[] = [];

  collapseAll$: Observable<void>;
  expandAll$: Observable<void>;
  private collapseAllSource = new BehaviorSubject<void>(undefined);
  private expandAllSource = new BehaviorSubject<void>(undefined);


  intermediaryButtons: PageBarButton[] = [
    {
      icon: 'add',
      text: 'addIntermediaries',
      click: () => {this.addIntermediaries();},
      show: () => this.permissionService.hasAnyPermission(EProtectedActions.addIntermediaryCampaign) &&
        this.pipeLogicService.isCampaignEditablePipe(this.campaign.status),
    },
    {
      icon: 'mail',
      text: 'sendToMyself',
      click: () => {this.onSendToMyself();},
      show: () => this.permissionService.hasAnyPermission(EProtectedActions.executeActionCampaign) &&
        this.campaign.status === CampaignStatus.LAUNCHED,
    },
    {
      icon: 'expand',
      text: 'expandAll',
      click: () => this.expandAllSource.next(),
    },
    {
      icon: 'compress',
      text: 'collapseAll',
      click: () => this.collapseAllSource.next(),
    }
  ]

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly permissionService: PermissionService,
    private readonly userService: UserService,
    private readonly filterService: FilterUtilsService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly pipeLogicService: PipeLogicService,
    private readonly campaignService: CampaignService,
  ) {
    this.expandAll$ = this.expandAllSource.asObservable();
    this.collapseAll$ = this.collapseAllSource.asObservable();
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(first()).subscribe((data) => {
      this.dataService.updateCampaign(data.campaign);
    });
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.campaign = campaign;
          this.updateIntermediaryTabView();
          this.filterService
            .getCampaignFilterById(this.campaign.id)
            .subscribe((f) => this.dataService.updateFilter(f));
        }),
      this.permissionService.user$.subscribe(user => {
        this.updateIntermediaryTabView();
      })
    );
    this.subscriptions.push(
      this.dataService.filter$.subscribe(
        (f) => {
          this.currentFilter = f;
        }
      )
    );
    this.subscriptions.push(
      this.permissionService.user$.subscribe(user => {
        this.useCompactSend = user.useCompactSend;
      })
    );
    this.updateFlatView();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.dataService.updateCampaign(null);
    this.dataService.updateFilter(null);
    this.dataService.updateData(null);
  }

  private updateFlatView() {
    const campaignOverviewTab = localStorage.getItem('campaignOverviewTab');
    if (!campaignOverviewTab) return;
    switch (campaignOverviewTab) {
      case 'flat':
        this.defaultTab = this.tabs[1].text;
        this.activeTab = this.defaultTab;
        break;
      case 'tree':
        const isRM = this.permissionService.hasAnyPermission(EProtectedActions.viewCompactCampaigns)
        this.defaultTab = this.tabs[isRM ? 0 : 1].text;
        this.activeTab = this.defaultTab;
        break;
      case 'intermediaries':
        this.defaultTab = this.tabs[2].text;
        this.activeTab = this.defaultTab;
        localStorage.removeItem('campaignOverviewTab');
        break;
    }
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }

  onNext() {
    this.activeTab = this.tabs[1].text;
  }

  private updateIntermediaryTabView() {
    if (!this.campaign) return;
    this.campaignService.hasIntermediaryVisibility(this.campaign.id)
      .pipe(
        first()
      )
      .subscribe(ok => {
        this.tabs = this.tabs.map((t, k) => ({
          ...t,
          hidden: k == 2 ? !ok.ok || undefined : undefined
        }));
        if (!ok.ok && this.activeTab == this.tabs[2].text) {
          this.activeTab = this.tabs[1].text;
        }
      })
  }

  protected switchToCompactOverview() {
    const data = {...this.permissionService.userRoleData, useCompactSend: !this.useCompactSend};
    this.userService.updateUserSettings(data).subscribe(updated => {
      this.permissionService.updateUser(updated);
    });
    this.userService.recordUserInteraction(
      data.useCompactSend ? UserInteraction.SWITCHEDTOQUICKSCREEN : UserInteraction.SWITCHEDTODETAILEDSCREEN
    ).pipe(first()).subscribe();
  }

  private addIntermediaries() {
    const modalTitleRef = 'addIntermediaries';
    const modalData: ModalData = {
      type: EModalType.addCampaignIntermediaries,
      title: modalTitleRef,
      data: {entity: this.campaign},
      submitBtn: {
        label: this.translateService.instant('addIntermediaries'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: CampaignIntermediaryAddComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, true, true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.intermediaryList.refreshGrid();
      }
    });
  }

  private onSendToMyself() {
    const modalData: ModalData = {
      type: EModalType.sendToMyself,
      title: EModalType.sendToMyself,
      data: {
        campaign: this.campaign,
      },
      submitBtn: {
        label: this.translateService.instant('send')
      },
      cancelBtn: {
        label: this.translateService.instant('cancel')
      },
      component: CampaignSendToMyselfComponent,
    };
    this.modalService.openDefaultDialog(
      modalData, undefined, true, true, DialogWidth.HALF, DialogHeight.HALF
    );
  }

    protected readonly ActionType = ActionType;
}
