import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ApplyColumnStateParams, ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { finalize } from 'rxjs';
import { first } from 'rxjs/operators';
import { Campaign, CampaignService, GridFilterOptionsParams, Portfolio, PortfolioService, } from 'src/app/api/core';
import { ModalSubComponent } from 'src/app/models/modal.model';
import { CodeTableService } from 'src/app/services/code-table.service';
import { GlobalService } from 'src/app/services/global.service';
import { GridDataProvider } from 'src/app/shared/grid/data-source';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ECodeTables, EModalType } from 'src/app/util/enum';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genRiskStateColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
} from 'src/app/util/grid/grid-renderer.util';
import { LabelBuilder } from "../../../../util/label-builder";

/**
 * Component to edit portfolios in a campaign.
 */
@Component({
  selector: 'app-add-portfolios',
  templateUrl: './campaign-portfolio-add.component.html',
})
export class CampaignPortfolioAddComponent
  implements OnInit, ModalSubComponent {
  portfolios: Portfolio[];

  columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      suppressHeaderMenuButton: true,
      lockPosition: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      width: 60,
      sortable: false,
    },
    genTextColumnWithAutoCompleteFilter({
      // sven: https://github.com/confinale/aspark/issues/8436
      field: 'number',
      headerName: this.translateService.instant('number'),
      autoCompleteParams: {
        apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
        autoCompleteField: 'number',
      },
    }),
    genCodeTableColumn({
      field: 'type',
      dtoField: 'portfolioType',
      headerName: this.translateService.instant('portfolioType'),
      observable: this.codeTableService.getCodeTable(ECodeTables.portfolioType),
      filterHubs: () => [this.campaign.hub.ident],
    }),
    genCodeTableColumn({
      field: 'advisoryType',
      headerName: this.translateService.instant('advisoryType'),
      observable: this.codeTableService.getCodeTable(ECodeTables.advisoryType),
      filterHubs: () => [this.campaign.hub.ident],
    }),
    genCodeTableColumn({
      field: 'strategy',
      headerName: this.translateService.instant('strategy'),
      observable: this.codeTableService.getCodeTable(ECodeTables.portfolioStrategy),
      filterHubs: () => [this.campaign.hub.ident],
    }),
    genTextColumn(
      'referenceCurrency.ident',
      this.translateService.instant('referenceCurrency')
    ),
    genNumberColumn(
      'portfolioValue',
      this.labelBuilder.labelWithCurrency('portfolioValue'),
      this.globalService
    ),
    genPercentageNumberColumn(
      'risk',
      this.translateService.instant('risk'),
      this.globalService
    ),
    genPercentageNumberColumn(
      'riskSpreadMin',
      this.translateService.instant('riskSpreadMin'),
      this.globalService
    ),
    genPercentageNumberColumn(
      'riskSpreadMax',
      this.translateService.instant('riskSpreadMax'),
      this.globalService
    ),
    genRiskStateColumn({
      field: 'riskState',
      headerName: this.translateService.instant('riskState'),
      observable: this.codeTableService.getCodeTable(ECodeTables.riskState),
      filterHubs: () => [this.campaign.hub.ident],
    }),
    {
      ...genTextColumn('bpName', this.translateService.instant('bpName')),
      floatingFilter: false,
      sortable: false,
    },
    genTextColumnWithAutoCompleteFilter({
      // sven: https://github.com/confinale/aspark/issues/8436
      field: 'bpNumber',
      headerName: this.translateService.instant('bpNumber'),
      autoCompleteParams: {
        apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
        autoCompleteField: 'bpNumber',
      },
    }),
    genCodeTableColumn({
      field: 'preferredLanguage',
      headerName: this.translateService.instant('preferredLanguage'),
      observable: this.codeTableService.getCodeTable(ECodeTables.language),
    }),
    genTextColumn(
      'relationshipManager.username',
      this.translateService.instant('relationshipManager')
    ),
    genTextColumn('advisor.username', this.translateService.instant('advisor')),
    genBooleanColumn(
      'closed',
      this.translateService.instant('CLOSED'),
      this.translateService
    ),
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
    rowSelection: 'multiple',
    getRowId: (params) => params.data.id,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => this.rowSelected(event),
    onModelUpdated: (event: ModelUpdatedEvent) => this.modelUpdated(event),
  };
  gridApi: GridApi;
  gridData: GridDataProvider;

  private campaign: Campaign;

  constructor(
    protected readonly portfolioService: PortfolioService,
    protected readonly campaignService: CampaignService,
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly codeTableService: CodeTableService,
    protected readonly labelBuilder: LabelBuilder,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { entity: Campaign } }
  ) {
    this.campaign = data.data.entity;
    this.gridData =
      this.portfolioService.getUnrelatedPortfoliosForCampaign.bind(
        this.portfolioService,
        this.campaign.id
      );
    this.portfolios = [];
  }

  ngOnInit(): void { }

  /**
   * Triggered by parent component story-modal
   */
  modalAction(modalType: EModalType): void {
    switch (modalType) {
      case EModalType.addCampaignPortfolios:
        this.campaignService
          .addCampaignPortfolios(
            this.campaign.id,
            this.portfolios.map((t) => t.ident)
          )
          .pipe(
            first(),
            finalize(() =>
              this.dialogRef.componentInstance.resetToolbarActionButtons()
            )
          )
          .subscribe({
            next: () => {
              this.dialogRef.close({ success: true });
            },
          });
        break;
      default:
        break;
    }
  }

  private isSelected(id: number): boolean {
    return this.portfolios.findIndex((it) => it.id === id) !== -1;
  }

  /**
   * Grid ready function. Gets triggered when grid is finished loading.
   * @param event GridReadyEvent
   */
  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    // set default sort if nothing is set
    if (event.api.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'bpNumber',
            sort: 'asc',
          },
        ],
      };
      event.api.applyColumnState(columnState);
    }
  }

  private rowSelected(event: RowSelectedEvent): void {
    const selectedIdx = this.portfolios.findIndex(
      (it) => it.id === event.data.id
    );
    if (event.node.isSelected()) {
      if (selectedIdx === -1) {
        this.portfolios.push({ ...event.data });
      }
    } else {
      if (selectedIdx > -1) {
        this.portfolios.splice(selectedIdx, 1);
      }
    }
    this.dialogRef.componentInstance.submitBtn.label =
      this.translateService.instant('addPortfoliosSelected', {
        count: event.api.getSelectedRows().length,
      });
  }

  private modelUpdated(event: ModelUpdatedEvent): void {
    event.api.forEachNode((node) =>
      this.isSelected(node.data?.id)
        ? node.setSelected(true)
        : node.setSelected(false)
    );
  }
}
