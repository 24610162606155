import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Content, ContentAttachment} from "../../api/core";
import {DocumentTransferService} from "../../services/document-transfer.service";
import {finalize} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../modal/modal.component";

@Component({
  selector: 'app-content-attachments',
  templateUrl: './content-attachments.component.html'
})
export class ContentAttachmentsComponent {
  @Input()
  content: Content;
  @Input()
  attachments: ContentAttachment[];
  @Input()
  isEditable: boolean;
  @Output()
  attachmentEditUpdated = new EventEmitter<void>();

  constructor(
    readonly documentTransferService: DocumentTransferService,
    public dialogRef: MatDialogRef<ModalComponent>,
  ) {
    this.dialogRef.afterClosed().subscribe(() => this.handleDialogClose());
  }
  fileNamePattern = '^[a-zA-Z0-9_\\-\\.]+$';

  onAdd(e: Event) {
    if (!this.isEditable) return;
    const input = e.target as HTMLInputElement;
    const file = input.files[0];
    const sanitizedFilename = this.sanitizeFilename(file.name);
    this.documentTransferService.uploadAttachment(sanitizedFilename,file)
      .pipe(finalize(() => (input.value = '')))
      .subscribe((info) => {
        this.attachments.push({
          filename: sanitizedFilename,
          source: info.documentId
        });
      });
  }

  onDownload(e: Event, a: ContentAttachment) {
    e.preventDefault();
    this.documentTransferService.downloadAttachment(a);
  }

  onDelete(a: ContentAttachment) {
    if (!this.isEditable) return;
    const index = this.attachments.findIndex(ca => a.id == ca.id)
    if (index >=0) {
      this.attachments.splice(index, 1);
    }
  }

  onSaveFilename(a: ContentAttachment) {
    // Get sanitized filename
    const sanitizedFilename = this.sanitizeFilename(a.newFilename || '');

    // If sanitized filename is not empty, then update it, otherwise keep the original filename
    if (sanitizedFilename) {
      a.filename = sanitizedFilename;
    }

    a.isEditing = false;
    this.attachmentEditUpdated.emit();
  }

  onEdit(a: any): void {
    a.newFilename = a.filename;
    a.isEditing = true;
    this.attachmentEditUpdated.emit();
  }

  adjustWidth(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.style.width = `${input.value.length + 1}ch`;
  }

  handleDialogClose(): void {
    this.attachments.forEach(a => a.isEditing = false);
  }

  sanitizeFilename(filename: string): string {
    return filename.replace(/[^a-zA-Z0-9_\\.-]/g, '');
  }
}
