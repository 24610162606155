/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PublicationTypeProductMode = 'NONE' | 'BUY_ONLY' | 'SELL_ONLY' | 'BOTH';

export const PublicationTypeProductMode = {
    NONE: 'NONE' as PublicationTypeProductMode,
    BUYONLY: 'BUY_ONLY' as PublicationTypeProductMode,
    SELLONLY: 'SELL_ONLY' as PublicationTypeProductMode,
    BOTH: 'BOTH' as PublicationTypeProductMode
};

