import {ContentImageHandlingType, FieldDefinition} from '../api/core';
import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {OAuthService} from "angular-oauth2-oidc";
import {DataService} from "./data.service";
import {Subscription} from "rxjs";

const MAX_IMAGE_SIZE = 200 * 1024;
const MAX_IMAGE_WIDTH = 1000;
const MAX_IMAGE_HEIGHT = 1000;
const IMAGE_QUALITY_START = 0.8;
const IMAGE_QUALITY_DELTA = 0.1;
const IMAGE_QUALITY_MIN = 0.5

type ImageData = {
  dataURL: string;
  imageType: string;
};

export function loadImageFile(imageFile: File): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve({
        dataURL: e.target.result.toString(),
        imageType: "image/jpeg",
      });
    };
    reader.readAsDataURL(imageFile);
  });
}

export async function resizeImage(src: ImageData, blobCallback: BlobCallback = undefined): Promise<ImageData> {
  const img = await loadImage(src.dataURL);
  const scale = Math.min(
    MAX_IMAGE_WIDTH / img.width,
    MAX_IMAGE_HEIGHT / img.height,
    1
  );
  let quality = IMAGE_QUALITY_START;
  //console.log({quality, scale, width: img.width, height: img.height, imageSize: (src.dataURL.length/1024)});
  let scaled = await scaleImage(src, scale, quality, blobCallback);
  while (scaled.dataURL.length > MAX_IMAGE_SIZE && quality >= IMAGE_QUALITY_MIN) {
    quality -= IMAGE_QUALITY_DELTA;
    //console.log({quality, imageSize: (scaled.dataURL.length/1024)});
    scaled = await scaleImage(src, scale, quality, blobCallback);
  }
  return scaled;
}

function loadImage(dataURL: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      resolve(img);
    };
    img.src = dataURL;
  });
}

async function scaleImage(src: ImageData, scale: number, quality: number, blobCallback: BlobCallback): Promise<ImageData> {
  const img = await loadImage(src.dataURL);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const width0 = img.width;
  const height0 = img.height;
  const width1 = width0 * scale;
  const height1 = height0 * scale;
  canvas.width = width1;
  canvas.height = height1;
  ctx.drawImage(img, 0, 0, width0, height0, 0, 0, width1, height1);
  if (blobCallback) {
    canvas.toBlob(blobCallback, 'image/jpeg', quality);
  }
  return {
    ...src,
    dataURL: canvas.toDataURL(src.imageType, quality),
  };
}

@Injectable({providedIn: 'root'})
export class FroalaEditorService implements OnDestroy{
  // current froala defaults
  defaultFontSize = 14;
  defaultLineHeight = 1.6;
  defaultTextInputPadding = 20;
  defaultParagraphMargin = 14;

  private editorOptions = {
    attribution: false,
    // heightMin: 400,
    // heightMax: 401, // 400 adds a scrollbar
    toolbarSticky: false,
    quickInsertButtons: ['image',/* 'video', 'embedly',*/ 'table', 'ul', 'ol', 'hr'],
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'textColor',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'quote',
      '-',
      'insertLink',
      'insertImage',
      // 'insertVideo',
      // 'insertFile',
      'insertTable',
      '|',
      // 'emoticons',
      'specialCharacters',
      'insertHR',
      'selectAll',
      'clearFormatting',
      'html',
      // '|',
      // 'print',
      // 'help',
      // 'html',
      '|',
      'undo',
      'redo',
      // 'trackChanges',
      // 'markdown'
    ],
  };

  private froalaLicenseKey: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private oauthService: OAuthService,
    private dataService: DataService,
    ) {
    this.subscriptions.push(
      this.dataService.guiConfig$.subscribe(
        config => (this.froalaLicenseKey = config.general.froalaLicense)
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getImageOptions(contentImageHandling?: ContentImageHandlingType): any {
    const token = this.oauthService.getRefreshToken();
    const base = {
      requestWithCORS: false,
      requestHeaders: {
        Authorization: `${this.oauthService.authorizationHeader()} ${token.substring(0, token.lastIndexOf(' '))}`
      }
    }
    if (!contentImageHandling) {
      return {
        ...base,
        toolbarButtons: this.editorOptions.toolbarButtons.filter(d => !d.toLowerCase().includes('image')),
        events: {},
      };
    }
    let alreadyUploaded = false;
    if (contentImageHandling === ContentImageHandlingType.LINKED) {
      return {
        ...base,
        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageManager'],
        imageManagerLoadURL: '/api/image/gallery?closed=false',
        imageUploadURL: '/api/image/gallery', // will create a request with mutipart/form-data
        events: {
          // resize the image before uploading
          "image.beforeUpload": function (files) {
            const editor = this;
            if (files.length && !files[0].resized) {
              alreadyUploaded = false;
              loadImageFile(files[0])
                .then(src => resizeImage(src, (blob) => {
                  blob['resized'] = true; // add a flag to avoid resizing the image again
                  editor.image.upload([blob])
                }));
              // Returning false will cancel the event processing,
              // allowing us to intercept, process the image, and upload it manually.
              return false;
            } else if (alreadyUploaded) {
              return false; // prevent multiple uploads
            } else {
              alreadyUploaded = true;
              return  true;
            }
          }
        }
      }
    } else {
      return {
        ...base,
        imageInsertButtons: ['imageBack', '|', 'imageUpload'],
        events: {
          // Handle images as base 64 and avoid uploading it
          "image.beforeUpload": function (files) {
            const editor = this;
            if (files.length) {
              loadImageFile(files[0])
                .then(src => resizeImage(src, undefined))
                .then(src => {
                  editor.image.insert(src.dataURL, null, null, editor.image.get());
                });
            }
            editor.popups.hideAll();
            // Stop default upload chain.
            return false;
          }
        }
      }
    }
  }

  getEditorOptions(
    fieldDefinition: FieldDefinition,
    contentImageHandling?: ContentImageHandlingType,
    isDisabled: Boolean = false,
  ): any {
    const lines = fieldDefinition.heightInLines;
    const label = this.translateService.currentLang === 'de' ? fieldDefinition.labelDe : fieldDefinition.labelEn;
    const imageOptions = this.getImageOptions(contentImageHandling);
    const options = {
      ...this.editorOptions,
      ...imageOptions,
      placeholderText: label,
      events: {
        ...imageOptions.events,
        initialized: function () {
          if (isDisabled) {
            this.edit.off();
            this.toolbar.disable();
          }
        }
      },
    };
    if (lines === 1) {
      return {
        ...options,
        multiLine: false, // disables adding new lines with enter
      };
    }

    const lineHeight = this.defaultFontSize * this.defaultLineHeight;
    const height = 2 * this.defaultTextInputPadding + 2 * this.defaultParagraphMargin + lineHeight * lines;
    return {
      ...options,
      key: this.froalaLicenseKey,
      heightMin: height,
      heightMax: height + 1, // height adds a scrollbar
    };
  }

}
