import {Component, OnDestroy, OnInit} from "@angular/core";
import {
  CodeTableEntry,
  PublicationType,
  PublicationTypeEditClientComms,
  PublicationTypeProductMode,
  PublicationTypeService
} from "../../../api/core";
import {finalize, of, Subscription, switchMap, combineLatest} from "rxjs";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {PublicationTypeData} from "../../publication-type-data";
import {CodeTableService} from "../../../services/code-table.service";
import {ECodeTables} from "../../../util/enum";
import {tap} from "rxjs/operators";
import {FroalaEditorService} from "../../../services/froala-editor.service";
import {MatSelectChange} from "@angular/material/select";
import {PermissionService} from "../../../services/permission.service";

type Option = {
  key: string;
  value: string;
}

const productModeKeys: Record<PublicationTypeProductMode, string> = {
  NONE: 'publicationTypeProductModeNone',
  BUY_ONLY: 'publicationTypeProductModeBuyOnly',
  SELL_ONLY: 'publicationTypeProductModeSellOnly',
  BOTH: 'publicationTypeProductModeBoth',
}

@Component({
  selector: 'app-publication-type-client-comms',
  templateUrl: './publication-type-client-comms.component.html'
})
export class PublicationTypeClientCommsComponent implements OnInit, OnDestroy {
  publicationType: PublicationType;
  subscriptions: Subscription[] = [];
  channelTypes: CodeTableEntry[] = [];
  languages: CodeTableEntry[] = [];
  displayDisclaimer: boolean = false;
  productModes: Option[] = Object.values(PublicationTypeProductMode)
    .map(value => ({key: productModeKeys[value], value}));

  readonly: boolean = false;
  formGroup = this.fb.group({
    applicableChannels: new FormControl([], [Validators.required]),
    disclaimerLanguage: new FormControl(''),
    disclaimerContent: new FormControl(''),
    productMode: new FormControl(''),
  });

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
    readonly publicationTypeService: PublicationTypeService,
    readonly codeTableService: CodeTableService,
    readonly froalaEditorService: FroalaEditorService,
    readonly permissionService: PermissionService,
  ) {
    this.onImageUpload = this.onImageUpload.bind(this);
    this.onImageDelete = this.onImageDelete.bind(this);
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.channelType),
      this.codeTableService.getCodeTable(ECodeTables.language)
    ]).subscribe(
      ([channelTypes, languages]) => {
        this.channelTypes = channelTypes;
        this.languages = languages;
      }
    )
    this.subscriptions.push(
      this.publicationTypeData.pubType$.subscribe(data => this.setPubType(data)),
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    this.readonly = this.publicationTypeData.isReadonly(data);
    this.displayDisclaimer = false;
    this.formGroup.patchValue({
      applicableChannels: data.applicableChannels.map(ct => ct.ident),
      productMode: data.productMode,
      disclaimerLanguage: '',
      disclaimerContent: '',
    });
    this.publicationTypeData.getFormControls(this.formGroup, 'applicableChannels', 'productMode', 'disclaimerContent')
      .forEach(ctrl => this.readonly ? ctrl.disable() : ctrl.enable());
  }

  private getCurrentValues() {
    const values = this.formGroup.getRawValue() as PublicationTypeEditClientComms;
    values.productMode = values.productMode || null;
    values.disclaimerLanguage = values.disclaimerLanguage || null;
    values.clientSubject = this.publicationType.clientSubject;
    values.clientBanner = this.publicationType.clientBannerImage;
    return values;
  }

  onSave(field: string) {
    if (this.readonly) return;
    const values = this.getCurrentValues();
    if (field === 'disclaimerDelete') {
      values.disclaimerContent = undefined;
    }
    this.publicationTypeData.setLoading(true);
    this.publicationTypeService.editPageClientComms(this.publicationType.id, values)
      .pipe(
        finalize(() => {
          this.publicationTypeData.setLoading(false);
        })
      ).subscribe(data => {
      this.publicationTypeData.setPublicationType(data);
    });
  }

  onImageUpload(documentId: string) {
    const values = {
      clientBanner: documentId,
    };
    this.publicationTypeData.setLoading(true);
    return this.publicationTypeService.editPageClientComms(this.publicationType.id, values)
      .pipe(
        switchMap(data => {
          this.setPubType(data);
          this.publicationTypeData.setLoading(false);
          return of(data.bannerImageUrl);
        })
      );
  }

  onImageDelete() {
    const values = {
      clientBanner: null,
    };
    this.publicationTypeData.setLoading(true);
    return this.publicationTypeService.editPageClientComms(this.publicationType.id, values)
      .pipe(tap(data => this.setPubType(data)));
  }

  onSubjectChanged(subject: string) {
    this.publicationType.clientSubject = subject;
    this.onSave('clientSubject');
  }

  isChannelSelected(ident: string) {
    const values = this.formGroup.getRawValue();
    return values.applicableChannels.indexOf(ident) >= 0;
  }

  toggleApplicableChannel(ident: string) {
    const values = this.formGroup.getRawValue();
    const applicableChannels: string[] = values.applicableChannels;
    const isSelected = applicableChannels.indexOf(ident) >= 0;
    this.formGroup.patchValue({
      applicableChannels: isSelected ? applicableChannels.filter(ac => ac !== ident) : [...applicableChannels, ident]
    });
    this.onSave('applicableChannels');
  }

  toggleLanguage(ident: string) {
    const exists = this.disclaimerLanguageExists(ident);
    this.formGroup.patchValue({
      disclaimerLanguage: ident
    });
    if (exists) {
      this.onSave('disclaimerDelete');
    } else {
      this.onSave('disclaimer');
    }
  }

  getEditorOptions() {
    const options = this.froalaEditorService.getEditorOptions({
      labelEn: this.translateService.instant('disclaimer'),
      labelDe: this.translateService.instant('disclaimer'),
    } as any, undefined, this.readonly);
    if (!this.readonly) {
      options.events.blur = () => this.onDisclaimerContentChanged();
    }
    options.height = 'calc(100% - 140px)';
    return options;
  }

  disclaimerLanguageExists(lang: string) {
    return this.publicationType.disclaimers.find(d => d.language.ident === lang)!!
  }

  onDisclaimerLanguageChanged(e: MatSelectChange) {
    const values = this.getCurrentValues();
    this.displayDisclaimer = !!values.disclaimerLanguage;
    if (!this.displayDisclaimer) return;
    const disclaimerContent = this.publicationType.disclaimers.find(d => d.language.ident === values.disclaimerLanguage)?.disclaimer ?? '';
    this.formGroup.patchValue({
      disclaimerContent
    });
  }

  onDisclaimerContentChanged() {
    this.onSave('disclaimer');
  }
}
