<app-card class="action story-details" [showSpinner]="loading$ | async">
  <div card-header class="story-title">
    <span>{{ 'storyDetails' | translate }}</span>
  </div>
  <app-config-icon
    *ngIf="story?.hot"
    iconIdentifier="whatshot"
    class="details-hot-story"
    tooltip="{{ 'isHotStory' | translate }}"
    card-action
  ></app-config-icon>
  <app-config-icon
    iconIdentifier="edit_m"
    [tooltip]="'editStoryDetails' | translate"
    (click)="editStoryDetails()"
    *ngIf="story.editable && protectedActions.editStoryDetails | protectedAction"
    card-action
  ></app-config-icon>
  <div class="h4" card-body>
    <div class="content-wrapper">
      <div class="detail-items-wrapper">
        <div class="detail-items">
          <app-overview-detail-item
            labelRef="useCase"
            [value]="story?.useCase?.name"
            icon="business_center"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="permissionService.userRoleData.hubs.length > 1"
            labelRef="hub"
            [value]="story.hub.name"
            icon="hub"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="publicationType"
            [value]="story?.publicationType?.name"
            *ngIf="story?.publicationType"
            icon="business_center"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="story?.author"
            labelRef="author"
            [value]="
              story?.author?.fullname + ' (' + story?.author?.username + ')'
            "
            icon="person"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="!story?.author"
            labelRef="author"
            [value]="story?.author"
            icon="person"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="valid"
            [value]="validPeriod"
            icon="date_range"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="status"
            [value]="combinedStatus"
            icon="info"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="story.info && story.info.trim().length > 0"
            labelRef="storyInfo"
            value="{{ 'externalLinkShow' | translate }}"
            icon="admin_panel_settings"
            valueIcon="pageview"
            [clickable]="protectedActions.editStoryInfo | protectedAction"
            (editClicked)="showInfo()"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="story.externalLinks.length"
            labelRef="externalLinkLabel"
            valueIcon="pageview"
            value="{{ 'externalLinkShow' | translate }}"
            icon="list"
            [clickable]="true"
            (editClicked)="showExternalLinks()"
          ></app-overview-detail-item>
          <app-overview-detail-item
            *ngIf="story.errors"
            [labelRef]="story.errors.length > 1 ? 'errors' : 'error'"
            valueIcon="pageview"
            [value]="story.errors"
            icon="warning"
            [clickable]="true"
            (editClicked)="showErrors()"
          ></app-overview-detail-item>

        </div>
      </div>
      <app-story-details-image
        [coverImageUrl]="story.coverImageUrl"
        [canEdit]="story.editable && protectedActions.editStory | protectedAction"
        [canDelete]="protectedActions.editStory | protectedAction"
        [onUpload]="onImageUpload"
        [onDelete]="onImageDelete"
        ></app-story-details-image>

      <div class="story-detail-item">
        <mat-chip-listbox>
          <mat-chip-row *ngFor="let tag of story?.tags">{{
            tag.name
            }}</mat-chip-row>
        </mat-chip-listbox>
      </div>
    </div>
  </div>
</app-card>
