import {Component, Input, OnInit} from '@angular/core';
import {
  ECodeTables,
  EFilterSubType,
  EFilterType,
  EPositionFilter,
  EPositionFilterTranslation,
} from 'src/app/util/enum';
import {FilterMetaData, IFilterMetaData,} from 'src/app/models/filter-meta-data.model';
import {IKeyValue} from 'src/app/models/key-value.model';
import {CodeTableService} from 'src/app/services/code-table.service';
import {forkJoin} from 'rxjs';
import {FilterPosition} from 'src/app/models/filter.model';
import {moodyRatingOrder, spRatingOrder, sustainabilityRatingOrder} from '../../../services/ratings.service';
import {UiFilterConfigService} from '../../../services/ui-filter-config.service';
import {filteredOptions} from "../filter-details.component";

/**
 * Position Filter Details Component.
 * Component for filter details for category position
 */
@Component({
  selector: 'app-filter-details-position',
  templateUrl: './filter-details-position.component.html',
})
export class FilterDetailsPositionComponent implements OnInit {
  /**
   * Filter values for all position filters
   */
  @Input() positionForm: FilterPosition;

  @Input() readOnly = false;
  /**
   * Asset class key value pairs
   */
  assetClasses: IKeyValue[] = [];
  /**
   * Asset sub classes key value pairs
   */
  assetSubClasses: IKeyValue[] = [];
  /**
   * Asset type key value pairs
   */
  assetTypes: IKeyValue[] = [];
  /**
   * Ranking key value pairs
   */
  rankings: IKeyValue[] = [];
  /**
   * Moody rating key value pairs
   */
  moodyRatings: IKeyValue[] = [];
  /**
   * S&P rating key value pairs
   */
  spRatings: IKeyValue[] = [];
  /**
   * Sustainability rating key value pairs
   */
  sustainabilityRatings: IKeyValue[] = [];

  productRatingsApac: IKeyValue[] = [];

  productRatingsMe: IKeyValue[] = [];

  productInvestmentHorizons: IKeyValue[] = [];

  ratings: IKeyValue[] = [];

  /**
   * Rating source key value pairs
   */
  ratingSources: IKeyValue[] = [];

  constructor(
    protected codeTableService: CodeTableService,
    protected filterConfigService: UiFilterConfigService
  ) {
  }

  ngOnInit() {
    this.initCodeTables();
  }

  private initCodeTables(): void {
    forkJoin({
      assetClasses: this.codeTableService.getCodeTable(ECodeTables.assetClass),
      assetSubClasses: this.codeTableService.getCodeTable(
        ECodeTables.assetSubClass
      ),
      assetTypes: this.codeTableService.getCodeTable(ECodeTables.assetType),
      rankings: this.codeTableService.getCodeTable(ECodeTables.ranking),
      moodyRatings: this.codeTableService.getCodeTable(ECodeTables.ratingMoody),
      ratings: this.codeTableService.getCodeTable(ECodeTables.rating),
      spRatings: this.codeTableService.getCodeTable(ECodeTables.ratingSp),
      sustainabilityRatings: this.codeTableService.getCodeTable(
        ECodeTables.ratingSustainability
      ),
      ratingSources: this.codeTableService.getCodeTable(
        ECodeTables.ratingSource
      ),
      productRatingsApac: this.codeTableService.getCodeTable(ECodeTables.productRatingApac),
      productRatingsMe: this.codeTableService.getCodeTable(ECodeTables.productRatingMe),
      productInvestmentHorizons: this.codeTableService.getCodeTable(ECodeTables.productInvestmentHorizon),
    }).subscribe((data) => {
      this.assetClasses = filteredOptions(data.assetClasses, this.positionForm.assetType);
      this.assetSubClasses = filteredOptions(data.assetSubClasses, this.positionForm.assetSubClass);
      this.assetTypes = filteredOptions(data.assetTypes, this.positionForm.assetType);
      this.rankings = filteredOptions(data.rankings, this.positionForm.ranking);
      this.moodyRatings = moodyRatingOrder
        .sort(filteredOptions(data.moodyRatings, this.positionForm.ratingMoody), r => r.value);
      this.ratings = filteredOptions(data.ratings, this.positionForm.rating);
      this.spRatings = spRatingOrder
        .sort(filteredOptions(data.spRatings, this.positionForm.ratingSP), r => r.value);
      this.sustainabilityRatings = sustainabilityRatingOrder
        .sort(filteredOptions(data.sustainabilityRatings, this.positionForm.sustainabilityRating), r => r.value);
      this.ratingSources = filteredOptions(data.ratingSources, this.positionForm.ratingSourceLGT);
      this.productRatingsApac = filteredOptions(data.productRatingsApac, this.positionForm.productRatingApac);
      this.productRatingsMe = filteredOptions(data.productRatingsMe, this.positionForm.productRatingMe);
      this.productInvestmentHorizons = filteredOptions(data.productInvestmentHorizons, this.positionForm.productInvestmentHorizon);
    });
  }

  /**
   * Provides position filter enum to html
   */
  get fields() {
    return EPositionFilter;
  }

  /**
   * Generates meta data for given position filter
   * @param filter Position filter
   */
  getFilterMetaData(filter: EPositionFilter): IFilterMetaData {
    switch (filter) {
      case EPositionFilter.value:
        return new FilterMetaData(
          EFilterType.rangeSlider,
          EPositionFilterTranslation.value,
          EFilterSubType.rangeMinMaxPercentage
        );
      case EPositionFilter.ids:
        return new FilterMetaData(
          EFilterType.chips,
          EPositionFilterTranslation.ids,
          EFilterSubType.chipsProduct
        );
      case EPositionFilter.excludeIds:
        return new FilterMetaData(
          EFilterType.chips,
          EPositionFilterTranslation.excludedIds,
          EFilterSubType.chipsProduct
        );
      case EPositionFilter.assetClass:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.assetClass,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.assetSubClass:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.assetSubClass,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.assetType:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.assetType,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.ratingMoody:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.ratingMoody,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.rating:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.rating,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.ratingSP:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.ratingSP,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.sustainabilityRating:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.sustainabilityRating,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.productRatingApac:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.productRatingApac,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.productRatingMe:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.productRatingMe,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.productInvestmentHorizon:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.productInvestmentHorizon,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.issuerName:
        return new FilterMetaData(
          EFilterType.autocomplete,
          EPositionFilterTranslation.issuerName,
          EFilterSubType.autoIssuer,
          [
            { key: 'clearSuffix', value: 'clear'}
          ],
          undefined,
          "chooseIssuer"
        );
      case EPositionFilter.ranking:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.ranking,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.ratingSourceLGT:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPositionFilterTranslation.ratingSourceLGT,
          undefined,
          this.getOptions(filter)
        );
      case EPositionFilter.nextCallDate:
        return new FilterMetaData(
          EFilterType.dateRange,
          EPositionFilterTranslation.nextCallDate
        );
      case EPositionFilter.maturityDate:
        return new FilterMetaData(
          EFilterType.dateRange,
          EPositionFilterTranslation.maturityDate
        );
      default:
        return null;
    }
  }

  /**
   * Generate select options for products filter
   * @param filter Position filter
   */
  private getOptions(filter: EPositionFilter): IKeyValue[] {
    switch (filter) {
      case EPositionFilter.assetClass:
        return this.assetClasses;
      case EPositionFilter.assetSubClass:
        return this.assetSubClasses;
      case EPositionFilter.assetType:
        return this.assetTypes;
      case EPositionFilter.ratingMoody:
        return this.moodyRatings;
      case EPositionFilter.rating:
        return this.ratings;
      case EPositionFilter.ratingSP:
        return this.spRatings;
      case EPositionFilter.sustainabilityRating:
        return this.sustainabilityRatings;
      case EPositionFilter.productRatingApac:
        return this.productRatingsApac;
      case EPositionFilter.productRatingMe:
        return this.productRatingsMe;
      case EPositionFilter.productInvestmentHorizon:
        return this.productInvestmentHorizons;
      case EPositionFilter.ranking:
        return this.rankings;
      case EPositionFilter.ratingSourceLGT:
        return this.ratingSources;
      default:
        return [];
    }
  }

  /**
   * Provide filter categories to html
   */
  get positionFilters() {
    return EPositionFilter;
  }

  /**
   * Provides boolean for html for whether or not
   * basics should be visible.
   */
  get basicsVisible() {
    const config = this.filterConfigService.getConfigFlags();
    return (
      config.position.filters.assetType ||
      config.position.filters.assetClass ||
      config.position.filters.ids ||
      config.position.filters.excludedIds ||
      config.position.filters.ranking ||
      config.position.filters.ratingMoody ||
      config.position.filters.sustainabilityRating ||
      config.position.filters.rating ||
      config.position.filters.ratingSP ||
      config.position.filters.ratingSourceLGT ||
      config.position.filters.nextCallDate ||
      config.position.filters.maturityDate
    );
  }

  formHasErrors(hasErrors: boolean): void {
    this.positionForm.formHasErrors = hasErrors;
  }
}
