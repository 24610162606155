<form [formGroup]="formGroup">
  <div  class="send-approach">
    <div class="publication-type-col">
      <mat-label>{{'publicationTypeSendApproach' | translate}}</mat-label>
      <mat-radio-group formControlName="sendApproach" (change)="onSave('sendApproach')" class="publication-type-background" color="primary">
        <mat-radio-button *ngFor="let option of sendApproachOptions" [value]="option.value">{{option.key | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="sender">
    <div class="publication-type-col">
      <mat-label>{{'publicationTypeSender' | translate}}</mat-label>
      <mat-radio-group formControlName="sender" (change)="onSave('sender')" class="publication-type-background" color="primary">
        <mat-radio-button *ngFor="let option of senderOptions" [value]="option.value">{{option.key | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field *ngIf="senderIsCentral">
      <mat-label>{{'publicationTypeSenderEmail' | translate}}</mat-label>
      <input type="email" matInput formControlName="senderEmail" (blur)="onSave('senderEmail')" />
    </mat-form-field>
    <mat-form-field *ngIf="senderIsCentral">
      <mat-label>{{'publicationTypeSenderName' | translate}}</mat-label>
      <input type="email" matInput formControlName="senderName" (blur)="onSave('senderName')" />
    </mat-form-field>
  </div>
  <div class="salutation">
    <div class="publication-type-col">
      <mat-label>{{'publicationTypeSalutation' | translate}}</mat-label>
      <mat-radio-group formControlName="salutation" (change)="onSave('salutation')" class="publication-type-background" color="primary">
        <mat-radio-button *ngFor="let option of salutationOptions" [value]="option.value">{{option.key | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
