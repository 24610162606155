<form [formGroup]="formGroup">
  <div class="applicable-channels">
    <label class="h4" id="applicable-channels">{{'publicationTypeApplicableChannels' | translate}}</label>
    <ul class="publication-type-checkbox-list publication-type-background">
      <li *ngFor="let ct of channelTypes">
        <mat-checkbox
          color="primary"
          [checked]="isChannelSelected(ct.ident)"
          (change)="toggleApplicableChannel(ct.ident)"
          [disabled]="readonly">
          {{ct.name}}</mat-checkbox>
      </li>
    </ul>
  </div>
  <div class="languages">
    <label class="h4" id="languages">{{'publicationTypeLanguages' | translate}}</label>
    <ul class="publication-type-checkbox-list publication-type-background">
      <li *ngFor="let ct of languages">
        <mat-checkbox
          color="primary"
          [checked]="disclaimerLanguageExists(ct.ident)"
          (change)="toggleLanguage(ct.ident)"
          [disabled]="readonly">
          {{ct.name}}</mat-checkbox>
      </li>
    </ul>
  </div>
  <div class="product-mode publication-type-col">
    <label class="h4">{{'publicationTypeProductMode' | translate}}</label>
    <mat-form-field class="product-mode">
      <mat-select formControlName="productMode" (selectionChange)="onSave('productMode')">
        <mat-option *ngFor="let item of productModes" [value]="item.value">{{item.key | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="disclaimer">
    <div class="disclaimer-label">
      <h4 class="h4">{{'disclaimer' | translate}}</h4>
    </div>

    <mat-form-field>
      <mat-select formControlName="disclaimerLanguage" (selectionChange)="onDisclaimerLanguageChanged($event)">
        <mat-option
          *ngFor="let disc of publicationType.disclaimers"
          [value]="disc.language.ident"
        >{{disc.language.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <textarea
      *ngIf="displayDisclaimer"
      [froalaEditor]="getEditorOptions()"
      matInput
      formControlName="disclaimerContent"
      maxlength="10000"
    ></textarea>
    <mat-error *ngIf="formGroup.get('content')?.errors?.required">
      {{ 'inputRequired' | translate }}
    </mat-error>
  </div>
  <div class="banner">
    <div class="banner-header h4">{{'publicationTypeBanner' | translate}}</div>
    <app-story-details-image
      [coverImageUrl]="publicationType.clientBannerUrl"
      [canEdit]="!readonly"
      [canDelete]="!readonly"
      [onUpload]="onImageUpload"
      [onDelete]="onImageDelete"
    ></app-story-details-image>
  </div>
  <app-publication-type-subject class="subject" field="clientSubject" (changed)="onSubjectChanged($event)" [readonly]="readonly">
  </app-publication-type-subject>
</form>
