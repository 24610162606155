import {Component, OnDestroy} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ModalService} from "../../../services/modal.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../services/notification.service";
import {PublicationTypeService} from "../../../api/core";
import {Subscription} from "rxjs";
import {PublicationTypeData} from "../../publication-type-data";
import {PUBLICATION_TYPE_OVERVIEW_TABS} from "../../../util/tab.constants";
import {Tab} from "../../../models/tabs.model";

@Component({
  selector: 'app-publication-type-overview',
  templateUrl: './publication-type-overview.component.html'
})
export class PublicationTypeOverviewComponent implements OnDestroy {

  tabs: Tab[] = PUBLICATION_TYPE_OVERVIEW_TABS;
  activeTabIndex: number = 0;
  defaultTab = this.tabs[this.activeTabIndex].text
  activeTab = this.tabs[this.activeTabIndex].text;
  canPrev: boolean = false;
  canNext: boolean = true;
  loading: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    protected publicationTypeData: PublicationTypeData,
    protected publicationTypeService: PublicationTypeService,
  ) {
    this.subscriptions.push(
      activatedRoute.data.subscribe((data) => {
        this.publicationTypeData.setPublicationType(data.publicationType || null);
      }),
      publicationTypeData.loading$.subscribe(data => {
        this.loading = data;
      })
    );
    this.onTab();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.publicationTypeData.setPublicationType(null);
  }

  private onTab() {
    this.activeTab = this.tabs[this.activeTabIndex].text;
    this.canPrev = this.activeTabIndex > 0;
    this.canNext = this.activeTabIndex < (this.tabs.length - 1);
  }

  onTabChange(tab: string) {
    const index = this.tabs.findIndex(t => t.text == tab);
    if (index == -1) return;
    this.activeTabIndex = index;
    this.onTab();
  }

  onPrev() {
    if (!this.canPrev) return;
    this.activeTabIndex--;
    this.onTab();
  }

  onNext() {
    if (!this.canNext) return;
    this.activeTabIndex++;
    this.onTab();
  }

  onTabChanged(tab: string): void {
    this.activeTabIndex = this.tabs.findIndex(t => t.text === tab);
    this.onTab();
  }

}
