<form [formGroup]="formGroup" class="modal-form">
  <div class="field-names">
    <mat-form-field>
      <mat-label>{{ 'publicationTypeNameEn' | translate }}</mat-label>
      <input matInput formControlName="nameEn" type="text" (blur)="onSave('nameEn')" />
      <mat-hint *ngIf="publicationType.ident == 'new'">{{ 'publicationTypeEditFirst' | translate}}</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'publicationTypeNameDe' | translate }}</mat-label>
      <input matInput formControlName="nameDe" type="text" (blur)="onSave('nameDe')" />
    </mat-form-field>
  </div>
  <div class="field-hubs">
    <mat-label>{{'hub' | translate}}</mat-label>
    <ul class="publication-type-checkbox-list publication-type-background">
      @for (option of hubs; track option) {
        <li>
          <mat-checkbox color="primary" [checked]="hasHub(option.ident)" (change)="onHub(option.ident)" [disabled]="readonly">{{option.name}}</mat-checkbox>
        </li>
      }
    </ul>
    <div class="field-advised">
      <mat-label>{{'publicationTypeAdvised' | translate}}</mat-label>
      <mat-slide-toggle color="primary" formControlName="advised"  (change)="onSave('advised')"></mat-slide-toggle>
    </div>
  </div>
  <div class="field-time-frame">
    <mat-form-field>
      <mat-label>{{'publicationTypeTimeFrame' | translate}}</mat-label>
      <mat-select formControlName="timeFrame" (selectionChange)="onSave('timeFrame')">
        @for (option of timeFrames; track option) {
          <mat-option [value]="option.value">{{option.text | translate}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="currentTimeFrame.showValue">
      <mat-label>{{ 'publicationTypeTimeValue' | translate }}</mat-label>
      <input matInput formControlName="timeValue" type="number" (blur)="onSave('timeValue')" />
    </mat-form-field>
  </div>
</form>
