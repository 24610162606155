<div class="buckets">
  <h4>{{'buckets' | translate}}</h4>
  <div class="bucket-buttons" *ngIf="!readonly">
    <button class="add-bucket" type="button" mat-raised-button color="primary" (click)="onCreateBucket()"
      [disabled]="!canAddBucket">
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{'publicationTypeBucketCreateNew' | translate}}
    </button>
    <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="add_bucket" [disabled]="addBuckets.length === 0" >
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{'publicationTypeBucketAddExisting' | translate}}
    </button>
  </div>
  <mat-menu #add_bucket=matMenu>
    <button mat-menu-item *ngFor="let bucket of addBuckets" (click)="onAddBucket(bucket)">{{bucket.name}} ({{bucket.hub.name}})</button>
  </mat-menu>
  <app-grid
    tableId="pub-type-monitoring-buckets"
    [columnDefs]="columnDefs"
    [rowData]="publicationType.monitoringBuckets"
    [gridOptions]="gridOptions"
  ></app-grid>
</div>
