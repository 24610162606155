import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {PublicationType} from "../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {PublicationTypeData} from "../../publication-type-data";
import {Subscription} from "rxjs";
import {Text} from "@angular/compiler";

export type ExampleValue = {
  key: string;
  value: string;
};


@Component({
  selector: 'app-publication-type-subject',
  templateUrl: './publication-type-subject.component.html'
})
export class PublicationTypeSubjectComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('publication_type_subject')
  elSubjectRef: ElementRef;
  @ViewChild('publication_type_example')
  elExampleRef: ElementRef;
  @Input()
  field: 'subject' | 'clientSubject';
  @Output()
  changed = new EventEmitter<string>();

  publicationType: PublicationType;
  subjects: string[] = [
    'publicationTypeName',
    'campaignName',
    'currentMonth',
    'currentDate',
  ];
  elSubject: HTMLElement;
  subjectRes = this.subjects.map(s => `{${s}}`);
  subjectKeys: Record<string, string> = {
    publicationTypeName: 'publicationTypeSubjectFieldName',
    campaignName: 'publicationTypeSubjectFieldCampaignName',
    currentMonth: 'publicationTypeSubjectFieldCurrentMonth',
    currentDate: 'publicationTypeSubjectFieldCurrentDate',
  }
  example: Record<string, string> = {
    publicationTypeName: 'Other Investments',
    campaignName: 'Artificial Intelligence Markets',
  }
  exampleValues: ExampleValue[] = [];
  exampleStr: string = '';
  dfCurrentMonth: Intl.DateTimeFormat;
  dfCurrentDate: Intl.DateTimeFormat;
  subjectPressed = false;
  subscriptions: Subscription[] = [];
  @Input()
  readonly = false;

  constructor(
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
  ) {
    const locale = this.translateService.currentLang;
    this.dfCurrentMonth = new Intl.DateTimeFormat(locale, {
      month: 'long',
    });
    this.dfCurrentDate = new Intl.DateTimeFormat(locale, {
      dateStyle: 'medium',
      timeStyle: 'medium',
    } as Intl.DateTimeFormatOptions);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.elSubject = this.elSubjectRef.nativeElement;
    const elExample = this.elExampleRef.nativeElement as HTMLElement;
    const elParent = this.elSubject.parentElement;
    const width = elParent.offsetWidth;
    [elParent, elExample.parentElement].forEach(el => {
      el.setAttribute('style', `max-width: ${width}px; width: ${width}px;`);
      el.classList.add('scrollable');
    })
    this.subscriptions.push(
      this.publicationTypeData.pubType$.subscribe(data => this.setPubType(data)),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    setTimeout(() => {
      this.addSubjectNodes(data[this.field]);
      this.updateSubjectExample();
    });
  }

  private createSubjectNode(subject: string) {
    const el = document.createElement('span');
    el.classList.add('subject-field');
    el.dataset.subject = subject;
    el.setAttribute('contenteditable', 'false');
    el.innerText = this.translateService.instant(this.subjectKeys[subject]);
    return el;
  }

  private getSubjectNodes(text: string) {
    const reTextSubject = /\{([a-zA-Z0-9]+)}/g;
    const nodes: Node[] = [];
    let index = 0;
    const matches = (text.match(reTextSubject) || []);
    let currentText = '';
    matches
      .filter(s => this.subjectRes.includes(s))
      .map(s => {
        const newIndex = text.indexOf(s, index);
        currentText = text.slice(index, newIndex);
        index = newIndex + s.length;
        nodes.push(
          document.createTextNode(currentText),
          this.createSubjectNode(s.slice(1, -1)),
        );
      });
    text = text.slice(index);
    if (text) {
      nodes.push(document.createTextNode(text));
    }
    return nodes;
  }

  private addSubjectNodes(text: string) {
    this.elSubject.innerHTML = '';
    this.getSubjectNodes(text)
      .forEach(node => this.elSubject.appendChild(node));
  }
  private updateSubjectExample() {
    const now = new Date();
    this.example.currentMonth = this.dfCurrentMonth.format(now);
    this.example.currentDate = this.dfCurrentDate.format(now);
    let exampleText = '';
    let dataSubject = '';
    this.elSubject.childNodes.forEach(node => {
      switch(node.nodeType) {
        case Node.TEXT_NODE:
          exampleText += node.textContent || '';
          break;
        case Node.ELEMENT_NODE:
          dataSubject = (node as HTMLElement).dataset.subject;
          if (dataSubject && this.subjects.find(s => s === dataSubject))
            exampleText += this.example[dataSubject];
          break;
      }
    });
    this.exampleValues = Object.entries(this.example)
      .map(([key, value]) => ({key, value}));
    this.exampleStr = exampleText;
  }

  onSubjectField(subject: string) {
    const sel0 = window.getSelection() as any;
    if (sel0.baseNode != this.elSubject) this.elSubject.focus();
    this.subjectPressed = true;
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    range.deleteContents();
    range.insertNode(this.createSubjectNode(subject));
    this.onBlurSubject();
  }

  onSubjectEdit() {
    this.updateSubjectExample();
  }
  onSubjectKeyPress(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      e.preventDefault();
      return;
    }
  }

  onSave() {
    let subjectText = '';
    let dataSubject = '';
    this.elSubject.childNodes.forEach(node => {
      switch(node.nodeType) {
        case Node.TEXT_NODE:
          subjectText += node.textContent;
          break;
        case Node.ELEMENT_NODE:
          dataSubject = (node as HTMLElement).dataset.subject;
          if (dataSubject && this.subjects.find(s => s === dataSubject))
            subjectText += `{${dataSubject}}`;
          break;
      }
    });
    this.changed.next(subjectText);
  }
  onBlurSubject() {
    this.subjectPressed = false;
    setTimeout(() => {
      if (!this.subjectPressed) {
        this.onSave();
      }
      this.subjectPressed = false;
    }, 500);
  }
}
