import {Component, EventEmitter, OnInit, Output, Input, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { EAdditionalCardState, EChartTypes } from 'src/app/util/enum';
import {Campaign, Gui, Story} from '../../api/core';

/**
 * Component for risk return chart card
 */
@Component({
  selector: 'app-additional-charts-card',
  templateUrl: './additional-charts-card.component.html',
})
export class AdditionalChartsCardComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean>;
  chartDataLoading$: Observable<boolean>;

  @Input() story: Story;
  @Input() campaign: Campaign;

  @Output()
  selectedChartChanged = new EventEmitter();

  selectedChart: EChartTypes;
  additionalCardState: EAdditionalCardState = EAdditionalCardState.charts;
  guiConfig: Gui = {charts:{}};
  private subscriptions: Subscription[] = []

  constructor(
    private readonly dataService: DataService,
    ) {
    this.loading$ = this.dataService.loading$;
    this.chartDataLoading$ = this.dataService.chartDataLoading$;
    this.subscriptions.push(
      this.dataService.guiConfig$.subscribe(
        gui => (this.guiConfig = gui)
      )
    );
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get chartTypes() {
    return EChartTypes;
  }

  get additionalCardStates() {
    return EAdditionalCardState;
  }

  onAdditionalCardActionClick(): void {
    this.selectedChartChanged.emit(null);
    switch (this.additionalCardState) {
      case EAdditionalCardState.charts:
        this.additionalCardState = EAdditionalCardState.portfolios;
        break;
      case EAdditionalCardState.portfolios:
        this.additionalCardState = EAdditionalCardState.charts;
        break;
      case EAdditionalCardState.assetClass:
      case EAdditionalCardState.currency:
      case EAdditionalCardState.region:
      case EAdditionalCardState.sector:
        this.additionalCardState = EAdditionalCardState.charts;
        this.selectedChart = null;
        break;
      default:
        break;
    }
  }

  setChartType(type: EChartTypes): void {
    this.selectedChart = type;
    switch (type) {
      case EChartTypes.assetClassBar:
      case EChartTypes.assetClassPie:
        this.additionalCardState = EAdditionalCardState.assetClass;
        break;
      case EChartTypes.currencyBar:
      case EChartTypes.currencyPie:
        this.additionalCardState = EAdditionalCardState.currency;
        break;
      case EChartTypes.regionBar:
      case EChartTypes.regionPie:
        this.additionalCardState = EAdditionalCardState.region;
        break;
      case EChartTypes.sectorBar:
      case EChartTypes.sectorPie:
        this.additionalCardState = EAdditionalCardState.sector;
        break;
      default:
        break;
    }
    this.selectedChartChanged.emit(this.selectedChart);
  }
}
