/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PublicationTypeSubscriptionType = 'DIRECT_SEND' | 'OPT_OUT';

export const PublicationTypeSubscriptionType = {
    DIRECTSEND: 'DIRECT_SEND' as PublicationTypeSubscriptionType,
    OPTOUT: 'OPT_OUT' as PublicationTypeSubscriptionType
};

