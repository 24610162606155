import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowSelectedEvent} from "ag-grid-community";
import {GridDataProvider} from "../../../shared/grid/data-source";
import {CampaignService, ClientService, GridFilterOptionsParams, PortfolioService, UserInfo} from "../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {
  genCodeTableColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel
} from "../../../util/grid/grid-renderer.util";
import {ECodeTables} from "../../../util/enum";
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";
import {CodeTableService} from "../../../services/code-table.service";
import {Subscription} from "rxjs";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {ServerSideRowSelectionState} from "ag-grid-community/dist/types/core/interfaces/selectionState";

@Component({
  selector: 'app-campaign-collection-available-entries',
  templateUrl: './campaign-collection-available-entries.component.html',
})
export class CampaignCollectionAvailableEntriesComponent implements OnInit, OnDestroy {

  columnDefs: ColDef[] = [];
  existingColumnDefs: ColDef[] = [];
  existingDoneEntriesCount = "?";

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    paginationAutoPageSize: true,
    rowSelection: 'multiple',
    getRowId: (params) => params.data.id,
    onGridReady: (event: GridReadyEvent<any>) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent<any>) => this.rowSelected(event),
  };
  existingGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    paginationAutoPageSize: true,
    getRowId: (params) => params.data.id,
  };
  gridApi: GridApi;
  campaignId: number;
  collectionId: number;
  gridData: GridDataProvider;
  existingGridData: GridDataProvider;
  relationshipManagers: UserInfo[] = [];
  advisors: UserInfo[] = [];
  userHubs: string[];
  subscriptions: Subscription[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly campaignService: CampaignService,
    private readonly permissionService: PermissionService,
    private readonly codeTableService: CodeTableService,
    private readonly clientService: ClientService,
    private readonly portfolioService: PortfolioService,
    protected readonly dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.campaignId = data.data.campaignId;
    this.collectionId = data.data.collectionId;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.permissionService.user$.subscribe((user) => {
        this.userHubs = user.hubs.map(h => h.ident);
      })
    );
    this.gridData = this.campaignService.getAvailableCollectionEntries.bind(
      this.campaignService, this.campaignId, this.collectionId
    );
    this.existingGridData = this.campaignService.getNonPendingCollectionEntriesWithinCampaign.bind(
      this.campaignService, this.campaignId, this.collectionId
    );
    this.columnDefs = this.generateColDefs(true);
    this.existingColumnDefs = this.generateColDefs(false);
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private gridReady(event: GridReadyEvent<any>) {
    this.gridApi = event.api;

    this.subscriptions.push(
      this.existingGridData({firstResult: 0, pageSize: 0}).subscribe(data => {
        this.existingDoneEntriesCount = data.count.toString();
      })
    );
  }

  private rowSelected(event: RowSelectedEvent<any>) {
    const selection = event.api.getServerSideSelectionState() as ServerSideRowSelectionState;
    const somethingSelected = (selection.selectAll === true || (selection.toggledNodes && selection.toggledNodes.length > 0));
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !somethingSelected;
  }

  private generateColDefs(withCheckboxSelection: boolean): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    return [
      withCheckboxSelection ? {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        lockPosition: true,
        lockVisible: true,
        suppressColumnsToolPanel: true,
        suppressHeaderMenuButton: true,
        width: 60,
      } : undefined,
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'clientPersonNumber',
          headerName: this.translateService.instant('personNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'personNumber',
          },
          isMultiSelect: true,
          filterParamsInfo: {customPath: 'client.personNumber'},
        }),
        floatingFilter: true,
        sortable: true,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'clientFullName',
          headerName: this.translateService.instant('fullName'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'fullName',
          },
          isMultiSelect: true,
          filterParamsInfo: {customPath: 'client.fullName'},
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'portfolioNumber',
          headerName: this.translateService.instant('portfolioNumber'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
            autoCompleteField: 'number',
          },
          isMultiSelect: true,
          filterParamsInfo: {customPath: 'portfolio.number'},
        }),
      },
      {
        ...genTextColumn(
          'portfolioBpName',
          this.translateService.instant('bpName'),
          null,
          {
            customPath: 'portfolio.bpName',
          }
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genCodeTableColumn({
          field: 'role',
          dtoField: 'clientRoleName',
          headerName: this.translateService.instant('clientRole'),
          observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
          filterHubs: () => this.userHubs,
        }),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchRelationshipManagers.bind(this),
          () => this.relationshipManagers,
          'portfolio.relationshipManager.username'
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
        hide: true,
      },
      {
        ...genUserEnumColumn(
          'advisor.username',
          this.translateService.instant('advisor'),
          this.fetchAdvisors.bind(this),
          () => this.advisors,
          'portfolio.advisor.username'
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.advisor),
        hide: true,
      },
      {
        ...genTextColumn(
          'preferredContactChannel',
          this.translateService.instant('preferredChannel'),
          null,
          {
            customPath: 'preferredContactChannel.address',
          }
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
        hide: true,
      }].filter(Boolean);
  }

  private fetchRelationshipManagers(params: any) {
    this.portfolioService
      .getPortfolioRelationshipManagers()
      .subscribe((data) => {
        this.relationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private fetchAdvisors(params: any) {
    this.portfolioService
      .getPortfolioAdvisors()
      .subscribe((data) => {
        this.advisors = data;
        params.success(data.map((d) => d.username));
      });
  }

  addSelectedEntries() {
    const gridSelection = this.gridApi.getServerSideSelectionState() as ServerSideRowSelectionState;
    const selection = {
      selectAll: gridSelection.selectAll,
      toggledNodes: gridSelection.toggledNodes, // array of "CLIENT_ID-PORTFOLIO_ID" entries
    }
    this.dialogRef.close({selection});
  }

  dontAdd() {
    this.dialogRef.close({collectionId: this.collectionId});
  }

}
