<form>
  <div class="h4">{{'publicationTypeSubject' | translate}}</div>
  <mat-chip-listbox [selectable]="false" [disabled]="readonly">
    <mat-chip-option
      *ngFor="let item of exampleValues"
      (click)="onSubjectField(item.key)"
      [title]="example[item.key]"
    >{{subjectKeys[item.key] | translate}}</mat-chip-option>
  </mat-chip-listbox>
  <div class="input-wrapper">
    <div  #publication_type_subject class="input" contenteditable="plaintext-only"
          *ngIf="!readonly"
         (input)="onSubjectEdit()"
          (keydown)="onSubjectKeyPress($event)"
         (blur)="onBlurSubject()"
    ></div>
    <div  #publication_type_subject class="input" contenteditable="false"
          *ngIf="readonly"
    ></div>
  </div>
  <div class="example">
    <label>{{'publicationTypeSubjectExample' | translate}}</label>
    <div class="input-wrapper">
      <div #publication_type_example class="input" >{{exampleStr}}</div>
    </div>
  </div>
</form>
