import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GridColDef, GridFilterConfig, GridFilterModel} from 'src/app/models/grid.model';
import {KeyValue} from 'src/app/models/key-value.model';
import {ModalService} from 'src/app/services/modal.service';
import {EGridFilterType, EModalType} from 'src/app/util/enum';
import {GridFilterFormComponent} from '../grid-filter-form/grid-filter-form.component';
import {GridApi} from "ag-grid-community";
import {BehaviorSubject, fromEvent, Subscription} from "rxjs";
import {MatInput} from "@angular/material/input";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
})
export class GridFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput')
  searchInput: MatInput;

  @Input() dialogTitle: string;

  @Input() showSearch = false;
  @Input() searchPlaceholder: string | undefined = undefined;
  /**
   * Columns that are shown in the filter, even if they are hidden
   */
  @Input() importantColumns: string[] | undefined = undefined;

  @Output()
  searchChanged = new EventEmitter<string>();

  searchInputFocused = false;

  private _gridApi: GridApi;
  private _initialGridFilterModel: GridFilterModel;
  gridFilterConfig: GridFilterConfig[] = [];
  // listen for filter-model updates
  @Input() filterModelSubject: BehaviorSubject<GridFilterModel>;
  private subscriptions: Subscription[] = [];

  activeFilters: KeyValue[] = [];
  shownFilters: KeyValue[] = [];
  activeFilterModel: GridFilterModel;
  anyGridFilterEnabled = false;
  lastSearch: string = '';

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (this.filterModelSubject) {
      this.subscriptions.push(
        this.filterModelSubject.subscribe((model) => {
          // update filters when the model changes
          this._initialGridFilterModel = model;
          this.activeFilterModel = model;
          this.initFilters();
        })
      );
    }
  }

  ngAfterViewInit() {
    if (this.searchInput) {
      const input = (this.searchInput as any).nativeElement;
      fromEvent(input, 'input')
        .pipe(
          map((event: Event) => (event.target as HTMLInputElement).value),
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe(value => {
          this.lastSearch = value;
          this.searchChanged.emit(value)
        })
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  clearSearch() {
    const input = (this.searchInput as any).nativeElement as HTMLInputElement;
    input.value = "";
    input.dispatchEvent(new Event('input'));
  }


  @Input() set gridApi(value: GridApi) {
    this._gridApi = value;
    if (this._gridApi) {
      // initialize filters if the gridApi changes
      this.init();
    } else {
      this.anyGridFilterEnabled = false;
    }
  }

  private init(): void {
    const updateAnyGridFilterEnabled = (api) => (
      this.anyGridFilterEnabled = api.getColumnDefs()
      .some(col => {
        const colDef = col as any;
        return colDef.filter && colDef.floatingFilter !== false;
      }));
    updateAnyGridFilterEnabled(this._gridApi);
    // adds an event listener to ensure client-side filters are initialized
    this._gridApi.addEventListener('firstDataRendered', () => {
      if (this.anyGridFilterEnabled) {
        this._initialGridFilterModel = this._gridApi.getFilterModel();
        this.activeFilterModel = this._initialGridFilterModel;
        this.initFilters();
      }
    });
    // update enabled filters flag if the grid-columns change
    this._gridApi.addEventListener('gridColumnsChanged', (e) => {
      updateAnyGridFilterEnabled(e.api);
    });
  }

  private initFilters(): void {
    if (!this.activeFilterModel || Object.keys(this.activeFilterModel).length == 0)
      this.activeFilterModel = structuredClone(this._initialGridFilterModel);
    // initialize filters based on existing column-defs
    this.gridFilterConfig = this.getGridFilterConfig();
    this.setActiveFilters();
  }

  private getGridFilterConfig() {
    const colDefs: GridColDef[] = this._gridApi.getColumnDefs();
    return colDefs === undefined ? [] : colDefs.map(colDef => {
      if (colDef.floatingFilter === false) {
        return undefined;
      }
      let result: GridFilterConfig = {
        key: colDef.field,
        name: this.translate(colDef.headerName),
        type: EGridFilterType.text,
        hide: colDef.hide,
        important: colDef.important,
        autoCompleteParams: colDef.filterParams?.autoCompleteParams,
        isMultiSelect: colDef.filterParams?.isMultiSelect,
      };
      switch (colDef.filter) {
        case 'agTextColumnFilter':
          if (colDef.filterParams.filterType === 'date') {
            result = {
              ...result,
              type: EGridFilterType.date,
            };
          } else if (colDef.filterParams.filterType === 'number') {
            result = {
              ...result,
              type: EGridFilterType.numeric,
            };
          } else {
            result = {
              ...result,
              type: EGridFilterType.text,
            };
          }
          break;
        case 'agSetColumnFilter':
          let values = colDef.filterParams.values;
          const keyCreator = colDef.filterParams.keyCreator;
          // we need to get the values from the client data if the values aren't present and a keyCreator is provided
          if (!values && keyCreator) {
            values = this.getClientDataValues(colDef.field);
          }
          result = {
            ...result,
            type: colDef.filterParams.onlyOne ? EGridFilterType.radio : EGridFilterType.set,
            values,
            valueFormatter: colDef.filterParams.valueFormatter,
            filterComparator: colDef.filterParams.comparator,
          };
          break;
        default:
          result = undefined;
          break;
      }
      return result;
    }).filter(d => d);
  }
  private translate(headerName: string) {
    if (!headerName || headerName === '') {
      return '';
    }
    return this.translateService.instant(headerName.replace(/\./g, ''));
  }

  openGridFilterDialog(): void {
    this.gridFilterConfig = this.getGridFilterConfig();
    const dialogRef = this.modalService.openDefaultDialog({
      type: EModalType.gridFilter,
      data: {
        config: this.gridFilterConfig,
        model: this.activeFilterModel,
        importantColumns: this.importantColumns,
      },
      title: this.dialogTitle,
      submitBtn: {
        label: this.translateService.instant('apply'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: GridFilterFormComponent,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.activeFilterModel = data;
        this.setActiveFilters();
        this.emitFilterChanged();
      }
    });
  }

  private emitFilterChanged(): void {
    this._gridApi.setFilterModel(this.activeFilterModel);
    this._gridApi.onFilterChanged();
  }

  private setActiveFilters(): void {
    this.activeFilters = identifyActiveFilters(this.activeFilterModel, this.gridFilterConfig);
    this.shownFilters = this.activeFilters.slice(0, 2); // Only show the first two filters
  }

  clearFilter(filter: KeyValue): void {
    delete this.activeFilterModel[filter.key];
    this.setActiveFilters();
    this.emitFilterChanged();
  }

  toggleColumnsMenu() {
    if (this._gridApi.isToolPanelShowing()) {
      this._gridApi.closeToolPanel();
    } else {
      this._gridApi.openToolPanel('columns');
    }
  }

  /**
   * NOTE: ONLY FOR CLIENT-SIDE GRIDS
   * It will navigate through the client data and return the values of the given field.
   * @param field
   * @private
   */
  private getClientDataValues(field: string) {
    let values = [];
    this._gridApi.forEachNode((node) => {
      // we are assuming all have an ID
      const fieldId = node.data && node.data[field] ? node.data[field].id : undefined;
      if (fieldId && !values.some(d => d.id === fieldId)) {
        values.push(node.data[field]);
      }
    });
    return (params) => {params.success(values)};
  }
}

/**
 * Identifies new active filters within the given model and configuration, returning
 * a list of key-value pairs with the key being the filter key and the value being the
 * filter name.
 * @param filterModel
 * @param gridFilterConfig
 */
export function identifyActiveFilters(
  filterModel: GridFilterModel,
  gridFilterConfig: GridFilterConfig[]
): KeyValue[] {
    const newActiveFilters: KeyValue[] = [];
    Object.keys(filterModel).forEach((key) => {
      const name = gridFilterConfig.find(
        (config) => config.key === key
      ).name;
      newActiveFilters.push(new KeyValue(key, name));
    });
    return newActiveFilters;
}
