import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter, finalize, first, Observable, Subscription} from 'rxjs';
import {
  AutoSyncUpdate,
  IntermediaryService,
  ListParams,
  PortfolioService,
  ProductListItem,
  Story,
  StoryService,
} from 'src/app/api/core';
import {ContentData} from 'src/app/models/content.model';
import {ModalData} from 'src/app/models/modal.model';
import {DataService, LoadedChart} from 'src/app/services/data.service';
import {FilterUtilsService} from 'src/app/services/filter-utils.service';
import {ModalService} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ContentFormComponent} from 'src/app/shared/content-form/content-form.component';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EChartTypes, EFilterHeaderActionType, EModalType, StoryProductType,} from 'src/app/util/enum';
import {FilterConfig} from '../../../models/filter.model';
import {convertFilterConfigToFilterBody} from '../../../services/filter.config-body';
import {PermissionService} from '../../../services/permission.service';
import {EProtectedActions} from '../../../util/protected-actions';
import {Tab} from 'src/app/models/tabs.model';
import {STORY_OVERVIEW_TABS} from 'src/app/util/tab.constants';
import {
  productSearchDialog,
  productSearchMerge
} from "../../../product/shared/product-search-dialog/product-search-dialog.component";

/**
 * Component for initial story editing.
 */
@Component({
  selector: 'app-story-overview',
  templateUrl: './story-overview.component.html',
})
export class StoryOverviewComponent implements OnInit, OnDestroy {
  story: Story;
  tabs: Tab[] = STORY_OVERVIEW_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;
  currentFilter: FilterConfig;

  dataLoading$: Observable<boolean>;
  selectedChart?: EChartTypes;
  loadedChart: LoadedChart = {};
  showAllowedUsers = false;
  maxSelectedBuyProducts: number = 20;
  maxSelectedSellProducts: number = 20;

  intermediaryData: GridDataProvider = this.intermediaryDataProvider.bind(this);

  private subscriptions: Subscription[] = [];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected storyService: StoryService,
    protected translateService: TranslateService,
    protected dataService: DataService,
    protected filterService: FilterUtilsService,
    protected notificationService: NotificationService,
    protected portfolioService: PortfolioService,
    protected permissionService: PermissionService,
    protected intermediaryService: IntermediaryService,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.dataService.updateStory(data.story);
    });

    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => {
          this.story = story;
          this.filterService
            .getStoryFilterById(this.story.id)
            .subscribe((f) => this.dataService.updateFilter(f));
        })
    );
    this.subscriptions.push(
      this.dataService.loadedChart$.subscribe((value: LoadedChart) => {
        this.loadedChart = value;
      })
    );

    this.subscriptions.push(
      this.dataService.filter$.subscribe((aFilter) =>
        this.handleFilter(aFilter)
      ),
      this.dataService.guiConfig$.subscribe((gui) => {
        this.maxSelectedBuyProducts = +gui.productSearch.maxSelectedBuy;
        this.maxSelectedSellProducts = +gui.productSearch.maxSelectedSell;
      })
    );
    this.showAllowedUsers = this.permissionService.hasAnyPermission(
      EProtectedActions.viewStoryAllowedUsers
    );
  }

  /**
   * Data Provider for Product grid
   */
  data: GridDataProvider = (listParams: ListParams) =>
    this.portfolioService.getPortfoliosPreview({
      hub: this.story.hub.ident,
      filter: convertFilterConfigToFilterBody(this.currentFilter || {}, this.permissionService.userRoleData),
      listParams,
    });

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.dataService.updateStory(null);
    this.dataService.updateFilter(null);
    this.dataService.updateData(null);
  }

  get storyProductType() {
    return StoryProductType;
  }

  get filterHeaderActionType() {
    return EFilterHeaderActionType;
  }

  get modalType() {
    return EModalType;
  }

  get protectedActions() {
    return EProtectedActions;
  }

  handleAddOrEditContent(contentData: ContentData): void {
    const modalData: ModalData = {
      type: contentData.content
        ? EModalType.updateStoryContent
        : EModalType.createStoryContent,
      title: contentData.content
        ? EModalType.updateStoryContent
        : EModalType.createStoryContent,
      data: contentData,
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ContentFormComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshStory();
        this.notificationService.handleSuccess(
          this.translateService.instant('updateStoryContentSuccess')
        );
      }
    });
  }

  handleDeleteContent(contentId: number): void {
    const modalData: ModalData = {
      title: this.translateService.instant('deleteContent?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('deleteContentQuestion'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.storyService
            .deleteStoryContent(this.story.id, contentId)
            .pipe(
              first(),
              finalize(() =>
                modalRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe({
              next: () => {
                modalRef.close(true);
                this.refreshStory();
                this.notificationService.handleSuccess(
                  this.translateService.instant('deleteStoryContentSuccess')
                );
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  handleEditProducts(productType: StoryProductType): void {
    const modalTitleRef =
      productType === StoryProductType.BUY
        ? 'addBuyProducts'
        : 'addSellProducts';
    const oldProducts = productType == StoryProductType.BUY
      ? this.story.buyProducts
      : this.story.sellProducts;
    const maxSelected = productType === StoryProductType.BUY
      ? this.maxSelectedBuyProducts
      : this.maxSelectedSellProducts;
    productSearchDialog(
      this.modalService,
      this.translateService.instant(modalTitleRef),
      this.translateService.instant('add'),
      this.translateService.instant('cancel'),
      maxSelected,
      [this.story.hub.ident],
    ).subscribe(result => {
      if (!result) return;
      const newProducts = result.selected;
      const allProducts = productSearchMerge(
        oldProducts,
        newProducts,
        p => p.id
      );
      this.dataService.updateLoading(true);
      this.storyService
        .updateStoryProducts(this.story.id, productType, allProducts)
        .pipe(
          first(),
          finalize(() => {
            this.dataService.updateLoading(false);
          })
        )
        .subscribe((storyData) => {
          this.dataService.updateStory(storyData);
        });
    });
  }

  handleDeleteProduct(productId: number, productType: StoryProductType): void {
    const modalData: ModalData = {
      title: this.translateService.instant('removeStoryProduct?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('removeStoryProductQuestion'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          let products: ProductListItem[];
          if (productType === StoryProductType.BUY) {
            products = this.story.buyProducts;
          }
          if (productType === StoryProductType.SELL) {
            products = this.story.sellProducts;
          }
          const productIndex = products.findIndex((a) => a.id === productId);
          products.splice(productIndex, 1);
          this.dataService.updateLoading(true);
          this.storyService
            .updateStoryProducts(this.story.id, productType, products)
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe((story) => {
              this.dataService.updateStory(story);
              this.notificationService.handleSuccess(
                this.translateService.instant('productRemovedSuccess')
              );
              modalRef.close(true);
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  /**
   * Function triggered, when story data should be fetched. Fetches current story data from API
   */
  private refreshStory() {
    this.dataService.updateLoading(true);
    this.storyService
      .getStory(this.story.id)
      .pipe(finalize(() => this.dataService.updateLoading(false)))
      .subscribe({
        next: (story) => {
          this.dataService.updateStory(story);
        },
      });
  }

  private handleFilter(
    aFilter: FilterConfig,
    noScatter: boolean = false
  ): void {
    if (aFilter) {
      this.currentFilter = aFilter;
      if (this.story.filter !== this.currentFilter?.id) {
        this.dataService.updateLoading(true);
        this.storyService
          .updateStory({
            ...this.story,
            filter: this.currentFilter?.id ?? this.story.filter,
          })
          .pipe(finalize(() => this.dataService.updateLoading(false)))
          .subscribe((story) => {
            this.dataService.updateStory(story);
          });
      }
      this.dataService.updateChartDataLoading(true);
      const options = this.dataService.getStatsOptions(
        this.selectedChart,
        noScatter
      );
      if (!noScatter) {
        this.dataService.resetLoadedChart();
      }
      this.filterService
        .getStatFromFilters(
          this.story.hub.ident,
          this.currentFilter,
          ...options
        )
        .pipe(
          first(),
          finalize(() => this.dataService.updateChartDataLoading(false))
        )
        .subscribe({
          next: (data) => this.dataService.updateData(data, noScatter),
        });
    }
  }

  selectedChartChanged(e: EChartTypes) {
    this.selectedChart = e;
    if (!this.story.editable) {
      return;
    }
    if (this.dataService.shouldChartLoad(e, this.loadedChart)) {
      this.handleFilter(this.currentFilter, true);
    }
  }

  handleToggleAutoSyncContent(): void {
    this.handleToggleAutoSync(
      {
        content: !this.story.autoSyncContent,
      },
      this.story.autoSyncContent
    );
  }

  handleToggleAutoSyncProducts(productType: StoryProductType): void {
    if (productType === StoryProductType.BUY) {
      this.handleToggleAutoSync(
        {
          buyProducts: !this.story.autoSyncBuyProducts,
        },
        this.story.autoSyncBuyProducts
      );
    } else {
      this.handleToggleAutoSync(
        {
          sellProducts: !this.story.autoSyncSellProducts,
        },
        this.story.autoSyncSellProducts
      );
    }
  }

  handleToggleAutoSyncAllowedUsers(): void {
    this.handleToggleAutoSync(
      {
        allowedUsers: !this.story.autoSyncAllowedUsers,
      },
      this.story.autoSyncAllowedUsers
    );
  }
  private handleToggleAutoSync(
    syncDto: AutoSyncUpdate,
    isEnabled: boolean
  ): void {
    let msg = this.translateService.instant(
      isEnabled
        ? 'autoSyncToCampaignsDisableMessage'
        : 'autoSyncToCampaignsEnableMessage'
    );
    const campaignCount = this.story.campaigns.length;
    if (campaignCount > 0) {
      msg =
        msg +
        '. ' +
        this.translateService.instant('autoSyncToCampaignsCountMessage', {
          count: campaignCount,
        });
    }
    const modalData: ModalData = {
      title: this.translateService.instant(
        isEnabled ? 'autoSyncToCampaignsDisable' : 'autoSyncToCampaignsEnable'
      ),
      type: EModalType.confirmationDialog,
      data: {
        message: msg,
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('update'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.dataService.updateLoading(true);
          this.storyService
            .autoSync(this.story.id, syncDto)
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe({
              next: (story) => {
                this.dataService.updateStory(story);
                this.notificationService.handleSuccess(
                  this.translateService.instant('autoSyncSettingUpdated')
                );
                modalRef.close(true);
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }

  intermediaryDataProvider(listParams: ListParams) {
    return this.intermediaryService.getIntermediariesPreview({
      hub: this.story.hub.ident,
      filter: convertFilterConfigToFilterBody(
        this.currentFilter || {},
        this.permissionService.userRoleData),
      listParams
    });
  }
}
