import {animate, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {filter, finalize, first} from 'rxjs/operators';
import {IFilterTag} from 'src/app/models/filter-tag.model';
import {FilterConfig, WeightRange} from 'src/app/models/filter.model';
import {IKeyValue, KeyValue} from 'src/app/models/key-value.model';
import {ModalData} from 'src/app/models/modal.model';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {FilterUtilsService} from 'src/app/services/filter-utils.service';
import {ModalService,} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {
  EClientFilter,
  ECodeTables,
  EFilterCategories,
  EFilterHeaderActionType,
  EModalType,
  EPortfolioFilter,
  EPositionFilter,
} from 'src/app/util/enum';
import {FilterDetailsComponent} from '../filter-details/filter-details.component';
import {
  Campaign,
  CampaignService,
  CodeTableEntry,
  FilterService,
  Story,
  UserFilter,
  UserService,
} from '../../api/core';
import {ModalComponent} from '../modal/modal.component';
import {convertFilterBodyToConfig} from '../../services/filter.body-config';
import {convertFilterConfigToFilterBody} from '../../services/filter.config-body';
import {UiFilterConfigService} from "../../services/ui-filter-config.service";
import {PermissionService} from "../../services/permission.service";

/**
 * Active Filter Tags Component.
 * Component for active filter tags
 */
@Component({
  selector: 'app-filter-active-tags',
  templateUrl: './filter-active-tags.component.html',
  animations: [
    trigger('tags', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate(
          '0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class FilterActiveTagsComponent implements OnInit, OnDestroy {
  /**
   * Component input to set the action to be executed on header click
   */
  @Input() headerActionType: EFilterHeaderActionType;
  /**
   * Whether editing of the filter is disabled
   */
  @Input() editDisabled = false;
  /**
   * Component output that emits event when header clicked
   */
  @Output() headerClick: EventEmitter<any> = new EventEmitter<any>();

  chartDataLoading$: Observable<boolean>;
  /**
   * Currently active filter config
   */
  filterConfig: FilterConfig;
  /**
   * Active filter tags
   */
  activeFilters: IFilterTag[] = [];
  currentStory: Story;
  currentCampaign: Campaign;
  /**
   * Subscription to get main filter. Keep reference to be able to unsubscribe
   */
  subscriptions: Subscription[] = [];

  assetClasses: IKeyValue[];
  currencies: IKeyValue[];
  gics: IKeyValue[];
  regions: IKeyValue[];
  enableShowReadOnlyFilter = false;
  userFilters: UserFilter[] = [];
  _selectedFilter: UserFilter;

  constructor(
    protected dataService: DataService,
    protected filterUtilsService: FilterUtilsService,
    protected modalService: ModalService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected campaignService: CampaignService,
    protected userService: UserService,
    protected filterService: FilterService,
    protected uiFilterConfigService: UiFilterConfigService,
    protected permissionService: PermissionService,
  ) {
    this.chartDataLoading$ = this.dataService.chartDataLoading$;
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          this.currentCampaign = campaign;
        })
    );
    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => (this.currentStory = story))
    );
    this.loadFilters(null);
  }

  ngOnInit(): void {
    forkJoin({
      assetClasses: this.codeTableService.getCodeTable(ECodeTables.assetClass),
      currencies: this.codeTableService.getCodeTable(ECodeTables.currency),
      gics: this.codeTableService.getCodeTable(ECodeTables.gics),
      regions: this.codeTableService.getCodeTable(ECodeTables.region),
    }).subscribe((data) => {
      this.assetClasses = data.assetClasses.map(
        (item) => new KeyValue(item.ident, item.name)
      );
      this.currencies = data.currencies.map(
        (item: CodeTableEntry) => new KeyValue(item.ident, item.name)
      );
      this.gics = data.gics.map((item) => new KeyValue(item.ident, item.name));
      this.regions = data.regions.map(
        (item) => new KeyValue(item.ident, item.name)
      );
    });

    this.subscriptions.push(
      this.dataService.filter$.subscribe((aFilter) =>
        this.handleGetFilter(aFilter)
      )
    );
    this.enableShowReadOnlyFilter = this.editDisabled;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Click handler to open filter modal
   */
  onEditFilterClick(): void {
    switch (this.headerActionType) {
      case EFilterHeaderActionType.edit:
        this.showFilterModal(false);
        break;
      default:
        this.headerClick.emit();
        break;
    }
  }

  private showFilterModal(
    readOnly: boolean,
    category: EFilterCategories = EFilterCategories.client
  ) {
    const storyId = this.currentStory?.id;
    const campaignId = this.currentCampaign?.id;
    this.filterService.getUserFilterConfigs(storyId, campaignId).subscribe((userFilterConfigs) => {
      this.uiFilterConfigService.updateConfigFlags(userFilterConfigs);
      this._showFilterModal(readOnly, category);
    });
  }

  private _showFilterModal(
    readOnly: boolean,
    category: EFilterCategories = EFilterCategories.client
  ) {
    // save a copy of the filter config to be able to restore it if the edit is cancelled
    const origFilter = this.filterConfig ? JSON.parse(JSON.stringify(this.filterConfig)) : undefined;
    const mData = {
      story: null,
      campaign: null,
      category,
      readOnly,
    };
    if (this.currentStory) {
      mData.story = this.currentStory;
    }
    if (this.currentCampaign) {
      mData.campaign = this.currentCampaign;
    }

    const modalData: ModalData = {
      type: EModalType.portfolioFilter,
      title: EModalType.portfolioFilter,
      data: mData,
      submitBtn: readOnly
        ? null
        : { label: this.translateService.instant('update') },
      cancelBtn: readOnly
        ? null
        : { label: this.translateService.instant('cancel') },
      component: FilterDetailsComponent,
    };
    const modalRef = this.modalService.openDefaultDialog(modalData);
    modalRef.afterClosed().subscribe((result) => {
      if (result === false && origFilter) { // if the edit was cancelled without updating
        this.dataService.updateFilter(origFilter);
      }
    });
  }

  onShowReadOnlyFilterClick(): void {
    this.showFilterModal(true);
  }

  /**
   * Click handler to reset filter corresponding to clicked filter tag
   * @param tag Clicked filter tag
   */
  onDeleteFilterTagClick(tag: IFilterTag): void {
    if (
      this.headerActionType === EFilterHeaderActionType.edit &&
      !this.editDisabled
    ) {
      switch (tag.category) {
        case EFilterCategories.assetClass:
          this.filterConfig.assetClass.children = this.deleteWeightFilter(
            this.filterConfig.assetClass.children,
            tag.field
          );
          break;
        case EFilterCategories.client:
          if (!tag.subcategory) {
            switch (tag.field) {
              case EClientFilter.ageRange:
                this.filterConfig.client.ageRange.from = 0;
                this.filterConfig.client.ageRange.to = 100;
                break;
              case EClientFilter.domiciles:
              case EClientFilter.gender:
                this.filterConfig.client[tag.field] = [];
                break;
              default:
                this.filterConfig.client[tag.field] = null;
                break;
            }
          }
          break;
        case EFilterCategories.intermediaries:
          this.filterConfig.intermediaries = {
            ...this.filterConfig.intermediaries,
            [tag.field]: false
          };
          break;
        case EFilterCategories.currency:
          this.filterConfig.currency.children = this.deleteWeightFilter(
            this.filterConfig.currency.children,
            tag.field
          );
          break;
        case EFilterCategories.portfolio:
          switch (tag.field) {
            case EPortfolioFilter.model:
            case EPortfolioFilter.portfolioType:
            case EPortfolioFilter.bu:
            case EPortfolioFilter.serviceCenter:
            case EPortfolioFilter.businessDivision:
            case EPortfolioFilter.advisoryType:
            case EPortfolioFilter.sustainabilityProfile:
            case EPortfolioFilter.mifid:
            case EPortfolioFilter.fidleg:
            case EPortfolioFilter.rmLocation:
            case EPortfolioFilter.rmMarket:
            case EPortfolioFilter.manager:
            case EPortfolioFilter.advisor:
            case EPortfolioFilter.referenceCurrency:
              this.filterConfig.portfolio[tag.field] = [];
              break;
            case EPortfolioFilter.riskRange:
            case EPortfolioFilter.returnRange:
            case EPortfolioFilter.valueRange:
            case EPortfolioFilter.liquidityRange:
              this.filterConfig.portfolio[tag.field] = {
                max: null,
                min: null,
              };
              break;
            case EPortfolioFilter.allowOptOut:
              this.filterConfig.portfolio.allowOptOut = false;
              break;
            case EPortfolioFilter.rmDepartment:
              this.filterConfig.portfolio.rmDepartment = null;
              break;
            default:
              this.filterConfig.portfolio[tag.field] = null;
              break;
          }
          break;
        case EFilterCategories.position:
          switch (tag.field) {
            case EPositionFilter.assetClass:
            case EPositionFilter.assetSubClass:
            case EPositionFilter.assetType:
            case EPositionFilter.ids:
            case EPositionFilter.excludeIds:
            case EPositionFilter.ranking:
            case EPositionFilter.ratingMoody:
            case EPositionFilter.ratingSP:
            case EPositionFilter.ratingSourceLGT:
            case EPositionFilter.sustainabilityRating:
            case EPositionFilter.rating:
              this.filterConfig.position[tag.field] = [];
              break;
            case EPositionFilter.nextCallDate:
              this.filterConfig.position.nextCallDate = {
                from: null,
                to: null,
                expires: null,
              };
              break;
            case EPositionFilter.maturityDate:
              this.filterConfig.position.maturityDate = {
                from: null,
                to: null,
                expires: null,
              };
              break;
            case EPositionFilter.value:
              this.filterConfig.position.value = {
                max: null,
                min: null,
              };
              break;
            default:
              this.filterConfig.position[tag.field] = null;
              break;
          }
          break;
        case EFilterCategories.assets:
          if (tag.type == 'assetsInclude') {
            const toRemove = this.filterConfig.assetsInclude.children.findIndex(
              (f) => f.key === tag.field
            );
            if (toRemove !== -1) {
              this.filterConfig.assetsInclude.children.splice(toRemove, 1);
            }
          }
          if (tag.type == 'assetsExclude') {
            const toRemove = this.filterConfig.assetsExclude.children.findIndex(
              (f) => f.key === tag.field
            );
            if (toRemove !== -1) {
              this.filterConfig.assetsExclude.children.splice(toRemove, 1);
            }
          }
          break;
        case EFilterCategories.region:
          this.filterConfig.region.children = this.deleteWeightFilter(
            this.filterConfig.region.children,
            tag.field
          );
          break;
        case EFilterCategories.sector:
          this.filterConfig.gics.children = this.deleteWeightFilter(
            this.filterConfig.gics.children,
            tag.field
          );
          break;
        case EFilterCategories.advanced:
          delete this.filterConfig.advanced;
          break;
        case EFilterCategories.orgPosition:
          this.filterConfig.orgPosition.positions = [];
          break;
        default:
          break;
      }
      this.changedFilter(() =>
        this.currentStory || this.currentCampaign
          ? this.filterUtilsService.upsertFilter(
              this.currentStory,
              this.currentCampaign,
              this.filterConfig
            )
          : of(this.filterConfig)
      );
    }
  }

  private changedFilter(
    channelGetter: () => Observable<FilterConfig>,
    cb?: () => void
  ) {
    this.activeFilters = this.filterUtilsService.getActiveFilterTags(
      this.filterConfig
    );

    // show chart data loading spinner, but don't hide it after this call finishes, hide it in the filter handler subscriptions
    this.dataService.updateChartDataLoading(true);
    const channel = channelGetter();

    channel.pipe(first()).subscribe((f) => {
      this.dataService.updateFilter(f);
      this.notificationService.handleSuccess(
        this.translateService.instant('filterUpdatedSuccess')
      );
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  onClickedFilterTag(tag: IFilterTag): void {
    if (this.editDisabled) {
      if (this.enableShowReadOnlyFilter) {
        this.showFilterModal(true, tag.category);
      }
    } else {
      this.showFilterModal(false, tag.category);
    }
  }
  /**
   * Check if tags can be edited
   */
  canEditTags(): boolean {
    return this.headerActionType === EFilterHeaderActionType.edit;
  }

  restoreFiltersFromStory(): void {
    if (!this.currentCampaign) {
      console.error('Restoring filter is only possible for Campaigns.');
      return;
    }

    const modalData: ModalData = {
      title: this.translateService.instant('restoreFromStory?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant(
          'restoreStoryFiltersDialogMessage'
        ),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('restore'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.dataService.updateLoading(true);
          this.campaignService
            .restoreCampaignFiltersFromStory(this.currentCampaign.id)
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe({
              next: (campaign) => {
                this.dataService.updateCampaign(campaign);
                modalRef.close(true);
                this.notificationService.handleSuccess(
                  this.translateService.instant('filterRestoreSuccess')
                );
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  /**
   * Handles incoming filter id and gets filter config corresponding to filter id
   * @param aFilter
   */
  private handleGetFilter(aFilter: FilterConfig): void {
    if (aFilter) {
      this.filterConfig = aFilter;
      this.activeFilters = this.filterUtilsService.getActiveFilterTags(
        this.filterConfig
      );
    } else {
      this.filterConfig = undefined;
      this.activeFilters = [];
    }
  }

  private deleteWeightFilter(
    baseEntries: Array<WeightRange>,
    field: string
  ): Array<WeightRange> {
    const entries = baseEntries.slice();
    const idx = entries.findIndex((f) => f.key === field);
    if (idx !== -1) {
      entries.splice(idx, 1);
    }
    return entries;
  }

  get selectedFilter(): UserFilter {
    return this._selectedFilter;
  }

  set selectedFilter(value: UserFilter) {
    this._selectedFilter = value;
    if (!value) {
      return;
    }
    this.filterConfig = {
      ...convertFilterBodyToConfig(value?.filter || {}),
      id: this.currentStory?.filter || this.currentCampaign?.filter || 0,
    };
    this.changedFilter(() =>
      this.currentStory || this.currentCampaign
        ? this.filterUtilsService.upsertFilter(
            this.currentStory,
            this.currentCampaign,
            this.filterConfig
          )
        : of(this.filterConfig)
    );
  }

  deleteFilterTemplate() {
    const modalData: ModalData = {
      title: this.translateService.instant('filterTemplateDeleteTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('filterTemplateDeleteMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          const selectedFilter = this.selectedFilter;
          if (!selectedFilter) {
            return;
          }
          this.userService.deleteUserFilter(selectedFilter.id).subscribe(() => {
            this.loadFilters(null);
            modalRef.close();
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  editFilterTemplate() {
    const dialogRef = this.modalService.openPromptText(
      this.translateService.instant('filterTemplateEdit'),
      null,
      this.selectedFilter.name,
      this.translateService.instant('name'),
      'save'
    );
    dialogRef.afterClosed().subscribe((name) => {
      if (!name) {
        return;
      }
      this._saveFilterTemplate(name, this.selectedFilter.id);
    });
  }

  clearFilterTemplate() {
    this.selectedFilter = null;
  }

  saveFilterTemplate() {
    const selectedFilter = this.selectedFilter;
    if (selectedFilter) {
      this._saveFilterTemplate(selectedFilter.name, selectedFilter.id);
      return;
    }
    this.saveAsFilterTemplate();
  }

  saveAsFilterTemplate() {
    const dialogRef = this.modalService.openPromptText(
      this.translateService.instant('filterTemplateSaveAs'),
      null,
      '',
      this.translateService.instant('name'),
      'save'
    );
    dialogRef.afterClosed().subscribe((name) => {
      if (!name) {
        return;
      }
      this._saveFilterTemplate(name, null);
    });
  }

  _saveFilterTemplate(name: string, id?: number) {
    const body = convertFilterConfigToFilterBody(
      this.filterConfig,
      this.permissionService.userRoleData);
    this.userService
      .upsertUserFilter({
        id,
        name,
        filter: body,
      })
      .subscribe((userFilter) => {
        this.loadFilters(userFilter.id);
      });
  }

  private loadFilters(selectedId: number) {
    this.userService
      .getUserFilters()
      .pipe(first())
      .subscribe((filters) => {
        this.userFilters = filters;
        setTimeout(() => {
          this.selectedFilter = filters.find((uf) => uf.id === selectedId);
        });
      });
  }
}
