<form>
  <div class="cc-emails">
    <div class="header">
      <div class="header-text h4">{{ 'publicationTypeCcEmails' | translate}}</div>
      <button
        mat-raised-button
        id="add-email"
        color="primary"
        (click)="onAddCcEmail()"
        [disabled]="readonly"
      >
        <app-config-icon iconIdentifier="add"></app-config-icon>
        {{ 'addEmail' | translate}}
      </button>
    </div>
    <app-grid
      tableId="cc-framework-launch"
      [columnDefs]="columns"
      [rowData]="publicationType.ccEmails"
      [gridOptions]="gridOptions"
    ></app-grid>
  </div>
  <div class="banner">
    <div class="banner-header h4">{{'publicationTypeBanner' | translate}}</div>
    <app-story-details-image
      [coverImageUrl]="publicationType.bannerImageUrl"
      [canEdit]="!readonly"
      [canDelete]="!readonly"
      [onUpload]="onImageUpload"
      [onDelete]="onImageDelete"
    ></app-story-details-image>
  </div>
  <app-publication-type-subject class="subject" field="subject" (changed)="onSubjectChanged($event)" [readonly]="readonly">
  </app-publication-type-subject>
</form>
