import {Component, Input, OnInit} from "@angular/core";
import {EmployeeService, PublicationTypeSubscription, PublicationTypeSubscriptionType} from "../../../../api/core";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";
import {first} from "rxjs/operators";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-employee-publication-types',
  templateUrl: './employee-publication-types.component.html'
})
export class EmployeePublicationTypesComponent implements OnInit {
  @Input()
  employeeId: number;

  publicationTypeDirectSend: PublicationTypeSubscription[] = [];
  publicationTypeOptOut: PublicationTypeSubscription[] = [];
  canEdit: boolean = false;
  showNote = false;
  selectedTabIndex = 0;

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly permissionService: PermissionService,
  ) {
    this.canEdit = this.permissionService.hasAnyPermission(EProtectedActions.editEmployee);
  }

  ngOnInit() {
    this.employeeService.getEmployeePublicationTypes(this.employeeId)
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeDirectSend = this.filter(data.publicationTypesDirectSend);
        this.publicationTypeOptOut = this.filter(data.publicationTypesOptOut);
        this.showNote = data.showNote;
      });
  }

  private filter(list: PublicationTypeSubscription[]): PublicationTypeSubscription[] {
    return list.filter(pt => pt.publicationTypeActive || !pt.type.closed);
  }

  onTabChange(index: number) {
    this.selectedTabIndex = index;
  }

  onDirectSendToggleChanged(ev: MatSlideToggleChange, pt: PublicationTypeSubscription) {
    this.employeeService.setEmployeePublicationTypes(this.employeeId, {
      type: PublicationTypeSubscriptionType.DIRECTSEND,
      publicationTypes: [{
        ...pt,
        publicationTypeActive: ev.checked
      }]
    })
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeDirectSend = this.filter(data.publicationTypesDirectSend);
        this.showNote = data.showNote;
      });
  }

  onOptOutToggleChanged(ev: MatSlideToggleChange, pt: PublicationTypeSubscription) {
    this.employeeService.setEmployeePublicationTypes(this.employeeId, {
      type: PublicationTypeSubscriptionType.OPTOUT,
      publicationTypes: [{
        ...pt,
        publicationTypeActive: ev.checked
      }]
    })
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeOptOut = this.filter(data.publicationTypesOptOut);
      });
  }
}
