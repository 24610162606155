import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {PublicationTypeData} from "../../publication-type-data";
import {
  PublicationType, PublicationTypeCcEmail,
  PublicationTypeEditInternalComms,
  PublicationTypeService
} from "../../../api/core";
import {finalize, of, Subscription, switchMap} from "rxjs";
import {ColDef, GridOptions} from "ag-grid-community";
import {genTextColumn} from "../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../shared/grid/cell-renderers/icon-button.renderer";
import {ModalData} from "../../../models/modal.model";
import {EModalType} from "../../../util/enum";
import {EmailDialogComponent} from "../../../core/views/settings/email-dialog/email-dialog.component";
import {DialogHeight, DialogWidth, ModalService} from "../../../services/modal.service";
import {ToastrService} from "ngx-toastr";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {EProtectedActions} from "../../../util/protected-actions";
import {tap} from "rxjs/operators";
import {PermissionService} from "../../../services/permission.service";


@Component({
  selector: 'app-publication-type-internal-comms',
  templateUrl: './publication-type-internal-comms.component.html'
})
export class PublicationTypeInternalCommsComponent implements OnInit, OnDestroy {
  publicationType: PublicationType;
  subscriptions: Subscription[] = [];
  readonly: boolean = false;

  columns: ColDef[] = [
    {
      ...genTextColumn('email', this.translateService.instant('email')),
      sortable: true,
      sort: "asc",
      flex: 1,
    },
    {
      ...genIconButtonColumn({
        callback: (data: PublicationTypeCcEmail) => this.onEditCcEmail(data),
        icon: 'edit_m',
        tooltip: this.translateService.instant('edit'),
        hidden: () => this.readonly,
      }),
      sortable: false,
      floatingFilter: false,
    },
    {
      ...genIconButtonColumn({
        callback: (data: PublicationTypeCcEmail) => this.onDeleteCcEmail(data),
        icon: 'delete',
        tooltip: this.translateService.instant('delete'),
        hidden: () => this.readonly,
      }),
      sortable: false,
      floatingFilter: false,
    }
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  constructor(
    readonly fb: FormBuilder,
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
    readonly publicationTypeService: PublicationTypeService,
    readonly modalService: ModalService,
    readonly toastService: ToastrService,
    readonly permissionService: PermissionService,
  ) {
    this.onImageUpload = this.onImageUpload.bind(this);
    this.onImageDelete = this.onImageDelete.bind(this);
    this.subscriptions.push(
      this.publicationTypeData.pubType$.subscribe(data =>  this.setPubType(data)),
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get protectedActions() {
    return EProtectedActions;
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    this.readonly = this.publicationTypeData.isReadonly(data);
  }

  onAddCcEmail() {
    const modalData: ModalData = {
      type: EModalType.addEmail,
      title: EModalType.addEmail,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        email: ''
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: EmailDialogComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      undefined,
      undefined,
      DialogWidth.HALF,
      DialogHeight.AUTO,
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      if (result.required) {
        this.toastService.error(this.translateService.instant('required'));
        return;
      }
      if (result.invalid) {
        this.toastService.error(this.translateService.instant('invalidEmail'));
        return;
      }
      if (this.publicationType.ccEmails.indexOf(result) >= 0) {
        return;
      }
      this.publicationType.ccEmails = [
        ...this.publicationType.ccEmails,
        { id: null, email: result.toString() },
      ];
      this.onSave("ccEmails");
    });
  }
  private onEditCcEmail(data: PublicationTypeCcEmail) {
    const modalData: ModalData = {
      type: EModalType.editEmail,
      title: EModalType.editEmail,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        email: data.email
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: EmailDialogComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      undefined,
      undefined,
      DialogWidth.HALF,
      DialogHeight.AUTO,
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      if (result.required) {
        this.toastService.error(this.translateService.instant('required'));
        return;
      }
      if (result.invalid) {
        this.toastService.error(this.translateService.instant('invalidEmail'));
        return;
      }
      if (result === data.email) return; // no change
      data.email = result;
      this.onSave("ccEmails");
    });
  }
  private onDeleteCcEmail(data: PublicationTypeCcEmail) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: EModalType.deleteEmail,
      component: null,
      data: {
        message: this.translateService.instant('deleteEmailMessage').replace('EMAIL', data.email),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.publicationType.ccEmails = this.publicationType.ccEmails
            .filter(e => e.id != data.id);
          this.onSave("ccEmails");
          modalRef.close();
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }

  private getCurrentValues() {
    return {
      bannerImage: this.publicationType.bannerImage,
      subject: this.publicationType.subject,
      ccEmails: this.publicationType.ccEmails,
    } as PublicationTypeEditInternalComms;
  }

  onSave(field: string) {
    const values = this.getCurrentValues();
    this.publicationTypeData.setLoading(true);
    this.publicationTypeService.editPageInternalComms(this.publicationType.id, values)
      .pipe(
        finalize(() => {
          this.publicationTypeData.setLoading(false);
        })
      ).subscribe(data => {
      this.publicationTypeData.setPublicationType(data);
    });
  }

  onImageUpload(documentId: string) {
    const values = {
      ...this.getCurrentValues(),
      bannerImage: documentId,
    };
    this.publicationTypeData.setLoading(true);
    return this.publicationTypeService.editPageInternalComms(this.publicationType.id, values)
      .pipe(
        switchMap(data => {
          this.setPubType(data);
          this.publicationTypeData.setLoading(false);
          return of(data.bannerImageUrl);
        })
      );
  }

  onImageDelete() {
    const values = {
      ...this.getCurrentValues(),
      bannerImage: null,
    };
    this.publicationTypeData.setLoading(true);
    return this.publicationTypeService.editPageInternalComms(this.publicationType.id, values)
      .pipe(tap(data => this.setPubType(data)));
  }

  onSubjectChanged(subject: string) {
    this.publicationType.subject = subject;
    this.onSave('subject');
  }
}
