import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {first, Subscription} from 'rxjs';
import {
  Allowed,
  Campaign,
  CampaignService,
  OrgPosition,
  OrgPositionService,
  Story,
  StoryService,
  User,
} from '../../api/core';
import {ModalData} from '../../models/modal.model';
import {CodeTableService} from '../../services/code-table.service';
import {FeatureFlags, FeatureFlagsService,} from '../../services/feature-flags.service';
import {I18n} from '../../services/i18n.service';
import {ModalService} from '../../services/modal.service';
import {NotificationService} from '../../services/notification.service';
import {PermissionService} from '../../services/permission.service';
import {EModalType} from '../../util/enum';
import {genEnumColumn, genTextColumn} from '../../util/grid/grid-renderer.util';
import {EProtectedActions} from '../../util/protected-actions';
import {genIconButtonColumn} from '../grid/cell-renderers/icon-button.renderer';
import {ModalComponent} from '../modal/modal.component';
import {
  getOrgPositionText,
  getOrgPositionTooltip,
  pickOrgPositionUsersDialog,
} from '../org-position-users/org-position-users.component';

@Component({
  selector: 'app-overview-allowed-users',
  templateUrl: './overview-allowed-users.component.html',
})
export class OverviewAllowedUsersComponent implements OnInit, OnDestroy {
  _story: Story;

  _campaign: Campaign;

  autoSyncEnabled = false;

  autoSyncFeature = false;
  autoSyncAllowedUsers = false;
  isDraft = false;
  canEdit = false;

  autoSyncMessage: string;
  @Output() toggleAutoSync: EventEmitter<void> = new EventEmitter<void>();

  users: User[] = [];
  positions: OrgPosition[] = [];
  featureFlags: FeatureFlags;
  orgPositions: OrgPosition[] = [];

  userColumnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        icon: 'delete',
        iconOutlined: false,
        callback: (user: User) => this.removeUser(user),
        hidden: (user: User) => this.hideUserIcon(user),
      }),
      sortable: false,
    },
    {
      ...genEnumColumn({
        field: 'fullname',
        values: (params) =>
          params.success(this.users.map((u) => u.fullname)),
        headerName: I18n.getColName('user')
      }),
      sortable: true,
      floatingFilter: true,
    },
    {
      ...genEnumColumn({
        field: 'username',
        values: (params) =>
          params.success(this.users.map((u) => u.username)),
        headerName: I18n.getColName('username')
      }),
      sortable: true,
      floatingFilter: true,
    },
  ];

  positionColumnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        icon: 'delete',
        iconOutlined: false,
        callback: (position: OrgPosition) => this.removePosition(position),
        hidden: () => this._campaign && this.autoSyncAllowedUsers,
      }),
      sortable: false,
    },
    {
      ...genTextColumn(
        'ident',
        I18n.getColName('orgPosition'),
        (data) => getOrgPositionText(data.data, this.orgPositions)
      ),
      //TODO: This is a bug, has to be fixed in https://github.com/confinale/aspark/issues/7193
     /* ...genEnumColumn({
        field: 'description',
        values: (params) =>
          params.success([...new Set(this.positions.map((p) => p.description))]),
        headerName: I18n.getColName('orgPosition'),
        valueFormatterFunc: (data) => getOrgPositionText(data.data, this.orgPositions)
      }),*/
      tooltipValueGetter: (data) =>
        getOrgPositionTooltip(data.data, this.orgPositions),
      sortable: true,
      floatingFilter: true,
    },
  ];

  userGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    onGridReady: (event: GridReadyEvent<User>) => this.onGridReadyUser(event),
  };
  positionGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
    groupSelectsFiltered: false,
    onGridReady: (event: GridReadyEvent<OrgPosition>) =>
      this.onGridReadyPosition(event),
  };

  subscriptions: Subscription[] = [];

  constructor(
    readonly translateService: TranslateService,
    readonly storyService: StoryService,
    readonly campaignService: CampaignService,
    readonly orgPositionService: OrgPositionService,
    readonly codeTableService: CodeTableService,
    readonly modalService: ModalService,
    readonly featureFlagsService: FeatureFlagsService,
    readonly permissionService: PermissionService,
    readonly notificationService: NotificationService
  ) {
    this.featureFlagsService.getFeatureFlags().subscribe({
      next: (d: FeatureFlags) => {
        this.featureFlags = d;
      },
    });
    this.orgPositionService
      .getAll()
      .pipe(first())
      .subscribe((orgPositions) => {
        this.orgPositions = orgPositions;
        this.positions = [...this.positions];
      });
  }

  ngOnInit() {
    this.updateData();
  }

  private updateData() {
    const apiCall = this._story
      ? this.storyService.getAllowedUsers(this._story.id)
      : this.campaignService.getAllowedUsers(this._campaign.id);
    apiCall.pipe(first()).subscribe((allowed) => {
      this.users = allowed.users || [];
      this.positions = allowed.positions || [];
    });
    this.autoSyncMessage = this.generateAutoSyncMessage();
  }

  @Input()
  set campaign(other: Campaign) {
    this._campaign = other;
    this.autoSyncFeature = this._campaign.story !== null;
    // For campaign, it's enabled only when story is not autoSync
    this.isDraft = this._campaign.status === 'DRAFT';
    this.autoSyncEnabled =
      !this._campaign.story?.autoSyncAllowedUsers &&
      this.isDraft &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.autoSyncCampaign
      );
    this.autoSyncAllowedUsers =
      this._campaign.story?.autoSyncAllowedUsers ||
      this._campaign.autoSyncAllowedUsers;
    if (this._campaign.decentralized) {
      this.canEdit = this.isDraft && this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit);
    } else {
      this.canEdit =
        !this.autoSyncAllowedUsers &&
        this.isDraft &&
        this.permissionService.hasAnyPermission(
          EProtectedActions.editCampaignAccess
        );
    }
    this.updateData();
  }

  @Input()
  set story(other: Story) {
    this._story = other;
    this.autoSyncFeature = true;
    // For story, it's always enabled
    this.autoSyncEnabled = this.permissionService.hasAnyPermission(
      EProtectedActions.autoSyncStory
    );
    this.autoSyncAllowedUsers = this._story.autoSyncAllowedUsers;
    this.isDraft = true;
    this.canEdit = this.permissionService.hasAnyPermission(
      EProtectedActions.editStoryAccess
    ) && this._story.editable;
    this.updateData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  removeUser(user: User) {
    const modalData: ModalData = {
      title: this.translateService.instant('removeUserTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('removeUserMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          const users = this.users.filter((u) => u.id !== user.id);
          this.updateAllowed({ users }, () =>
            this.notificationService.handleSuccess(
              this.translateService.instant('removeUserSuccess')
            )
          );
          modalRef.close(true);
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  removePosition(position: OrgPosition) {
    const modalData: ModalData = {
      title: this.translateService.instant('removeOrgPositionTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('removeOrgPositionMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          const positions = this.positions.filter(
            (p) => p.ident !== position.ident
          );
          this.updateAllowed({ positions }, () =>
            this.notificationService.handleSuccess(
              this.translateService.instant('removeOrgPositionSuccess')
            )
          );
          modalRef.close(true);
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  updateAllowed(
    opts: { users?: User[]; positions?: OrgPosition[] } = {},
    cb?: () => void
  ) {
    const users = opts.users || this.users;
    const positions = opts.positions || this.positions;
    const allowed: Allowed = {
      users,
      positions,
    };
    const apiCall = this._story
      ? this.storyService.updateAllowedUsers(this._story.id, allowed)
      : this.campaignService.updateAllowedUsers(this._campaign.id, allowed);
    apiCall.pipe(first()).subscribe((result) => {
      this.users = result.users;
      this.positions = result.positions;
      if (typeof cb == 'function') {
        cb();
      }
    });
  }

  onGridReadyUser(params: GridReadyEvent) {
    this.subscriptions.push(I18n.getColumns(this.translateService, params.api));
    if (!this.isDraft) {
      params.api.setGridOption("columnDefs",
        (params.api.getColumnDefs() as ColDef[]).filter(
          (c) => c.colId !== 'icon-button-delete'
        )
      );
    }
  }

  onGridReadyPosition(params: GridReadyEvent) {
    this.subscriptions.push(I18n.getColumns(this.translateService, params.api));
    if (!this.isDraft) {
      params.api.setGridOption("columnDefs",
        (params.api.getColumnDefs() as ColDef[]).filter(
          (c) => c.colId !== 'icon-button-delete'
        )
      );
    }
  }

  async editAllowed() {
    const allowed = await pickOrgPositionUsersDialog(
      this.orgPositionService,
      this.codeTableService,
      this.translateService,
      this.modalService,
      {
        users: this.users,
        positions: this.positions,
      }
    );
    if (allowed) {
      this.updateAllowed(allowed);
    }
  }

  restoreAllowedUsersFromStory() {
    if (!this._campaign) {
      return;
    }
    this.campaignService
      .restoreAllowedUsersFromStory(this._campaign.id)
      .pipe(first())
      .subscribe((result) => {
        this.users = result.users;
        this.positions = result.positions;
      });
  }

  handleToggleAutoSync(event: any) {
    event.source.checked = this.autoSyncAllowedUsers;
    this.toggleAutoSync.emit();
  }

  private generateAutoSyncMessage() {
    let msg: string;
    if (this._story) {
      msg = this.translateService.instant(
        this.autoSyncAllowedUsers
          ? 'autoSyncToCampaignsDisableMessage'
          : 'autoSyncToCampaignsEnableMessage'
      );
      const campaignCount = this._story.campaigns.length;
      if (campaignCount > 0) {
        msg =
          msg +
          '. ' +
          this.translateService.instant('autoSyncToCampaignsCountMessage', {
            count: campaignCount,
          });
      }
    } else if (this.autoSyncEnabled) {
      msg = this.translateService.instant(
        this.autoSyncAllowedUsers
          ? 'autoSyncToStoryDisable'
          : 'autoSyncToStoryEnable'
      );
    }
    return msg;
  }

  private hideUserIcon(user: User): boolean {
    return (
      (this._campaign && this.autoSyncAllowedUsers) ||
      user.username === this.permissionService.currentUsername
    );
  }
}
