import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  GridFilterConfig,
  GridFilterItemComponent,
  GridFilterItemComponentFilter,
  GridFilterModelItem,
} from 'src/app/models/grid.model';
import { KeyValue } from 'src/app/models/key-value.model';
import { TranslateService } from "@ngx-translate/core";
import { of, Subject, switchMap, takeUntil } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { GridFilterOptionsParams } from "../../../../../api/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: 'app-grid-filter-item-text',
  templateUrl: './grid-filter-item-text.component.html',
})
export class GridFilterItemTextComponent implements OnInit, GridFilterItemComponent, OnDestroy {
  @Input() filterConfig: GridFilterConfig;
  @Input() filterModel: GridFilterModelItem;
  @Input() maxAutoCompleteOptions: number;
  @Output() gridFilterItemChanged = new EventEmitter<GridFilterModelItem>();
  @Output() applyFilter = new EventEmitter<void>();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('chipInput', { read: ElementRef, static: false })
  chipInput: ElementRef<HTMLInputElement>;
  chipControl: FormControl = new FormControl({ value: null });

  private ngUnsubscribe = new Subject<void>();
  autoOptions: string[];
  isMultiSelect: boolean;
  multiSelected: string[] = [];

  typeOptions: KeyValue[] = [
    {
      key: this.translateService.instant('contains'),
      value: 'contains',
    },
  ];
  textFilterForm = this.fb.group({
    type: [this.typeOptions[0].value],
    filter: [''],
  });

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.filterConfig.autoCompleteParams) {
      this.isMultiSelect = this.filterConfig.isMultiSelect ?? false;
      this.setupAutoComplete();
    }
    if (this.filterModel) {
      this.textFilterForm.patchValue({
        type: this.filterModel.type,
        filter: this.filterModel.filter,
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setupAutoComplete(): void {
    let optionGetter: (search: string) => any;
    if (this.isMultiSelect && this.filterModel?.filter)
      this.multiSelected = this.filterModel.filter.split(',');
    if ("apiMethod" in this.filterConfig.autoCompleteParams) { // server side table
      let autoCompleteParams = this.filterConfig.autoCompleteParams
      optionGetter = (search: string) => {
        let apiParams: GridFilterOptionsParams = {
          search,
          field: autoCompleteParams.autoCompleteField,
          contextId: autoCompleteParams.autoCompleteContextId,
        };
        return autoCompleteParams.apiMethod(apiParams);
      }
    } else { // client side table
      // If array: Assumed to be sorted, without *** and duplicate entries in colDef or genColumnMethod
      let sorted: string[] = (Array.isArray(this.filterConfig.autoCompleteParams) ?
        this.filterConfig.autoCompleteParams :
        [...new Set(this.filterConfig.autoCompleteParams())]
          .filter(s => s !== '***' && s !== null)
          .sort()) as string[];
      optionGetter = (search: string) => {
        return of<string[]>(
          sorted
            .filter(s => s.toLowerCase().includes(search))
            .slice(0, this.maxAutoCompleteOptions)
        )
      }
    }
    if (this.isMultiSelect) {
      this.chipControl.valueChanges
        .pipe(
          debounceTime(300), // wait a bit if user is still typing
          switchMap(
            (value) =>
              value?.length > 0 ? optionGetter(value.toLowerCase())
                : of<string[]>([])
          ),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((options: string[]) =>
          this.autoOptions = options
        );
    } else {
      this.textFilterForm.controls.filter.valueChanges
        .pipe(
          debounceTime(300), // wait a bit if user is still typing
          switchMap(
            (value) =>
              value?.length > 0 ? optionGetter(value.toLowerCase())
                : of<string[]>([])
          ),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((options: string[]) =>
          this.autoOptions = options
        );
    }
    this.autoOptions = [];
  }

  getModel(): GridFilterItemComponentFilter | undefined {
    let value = this.textFilterForm.value.filter;
    if (value && value.trim().length > 0) {
      const model = {
        ...this.filterModel,
        type: this.textFilterForm.value.type,
        filter: value,
      };
      if (this.filterConfig.isMultiSelect && !!this.filterConfig.autoCompleteParams) {
        model.filter = this.multiSelected.join(',');
      }
      return { config: this.filterConfig, model, form: this.textFilterForm }
    } else {
      return { config: this.filterConfig, model: undefined, form: this.textFilterForm }
    }
  }

  trackByFn(index: number, item: string): number {
    return index;
  }

  removeChip(index: number) {
    this.multiSelected = [...this.multiSelected]
      .filter((s, k) => k !== index);
    this.updateMultiSelect();
  }

  selectChip(event: MatAutocompleteSelectedEvent | MatChipInputEvent) {
    let value: string = event instanceof MatAutocompleteSelectedEvent ? event.option.value : event.value;
    if (value?.length > 0) {
      this.multiSelected.push(value);
      // reset form control to get all values
      this.chipControl.setValue(null);
      // reset html input to show no value
      this.chipInput.nativeElement.value = null;
      this.updateMultiSelect();
    } else {
      return;
    }
  }

  updateMultiSelect() {
    this.textFilterForm.setValue({
      type: this.typeOptions[0].value,
      filter: this.multiSelected.join(',')
    });
  }

  onApplyFilter() {
    this.applyFilter.next();
  }

  // Close panel when enter is pressed, so that close button is visible
  closeAutoCompletePanel($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.autoOptions = [];
    }
  }

  // Clear all chips when clearFilter is called from parent
  clearMultiSelect() {
    this.multiSelected = [];
  }
}
