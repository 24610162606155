import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {CampaignActionExecutionAction, CampaignActionStatus, NoActionTriggerType} from 'src/app/api/core';
import {TranslateService} from '@ngx-translate/core';
import {commonFilterParams, commonParams,} from 'src/app/util/grid/grid-renderer.util';
import {GridFilterParamsInfo,} from 'src/app/models/grid.model';
import {EPortfolioActionStatus} from '../../../util/enum';
import {ColDef, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";

@Component({
  template: `
    <span>{{statusValue}}</span>
  `,
})
export class ActionStatusRenderer implements AgRendererComponent {
  statusValue: string;

  constructor(protected translateService: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    const status = params.value;
    const isCampaignEditable = params.colDef.cellRendererParams.isCampaignEditable;
    if (status) {
      if (status === EPortfolioActionStatus.pending && !isCampaignEditable) {
        this.statusValue = this.translateService.instant('notSent');
      } else {
        this.statusValue = this.translateService.instant(status);
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

/**
 * Generate action noActionTrigger column with the values shown as enum and filters properly translated
 * @param translateService
 * @param field
 * @param headerName
 * @param hide
 */
export function genNoActionTrigger(
  translateService: TranslateService,
  field: string,
  headerName: string,
  hide: boolean = false,
) {
  const valueFmt = (params: ValueFormatterParams) => params?.value ? translateService.instant(params.value) : '';
  return {
    ...commonParams(field, headerName),
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [
        NoActionTriggerType.AUTOMATIC,
        NoActionTriggerType.MANUAL,
        NoActionTriggerType.OPTOUT,
      ],
      valueFormatter: valueFmt,
    },
    valueFormatter: valueFmt,
    hide,
  };
}

/**
 * Generate action execution column with the values shown as enum and filters properly translated
 * @param translateService
 * @param field
 * @param headerName
 * @param hide
 */
export function genExecutionAction(
  translateService: TranslateService,
  field: string,
  headerName: string,
  hide: boolean = false,
) {
  const valueFmt = (params: ValueFormatterParams) => params?.value ? translateService.instant(params.value) : '';
  return {
    ...commonParams(field, headerName),
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [
        CampaignActionExecutionAction.DIRECTSEND,
        CampaignActionExecutionAction.MANUAL,
        CampaignActionExecutionAction.REVERT,
      ],
      valueFormatter: valueFmt,
    },
    valueFormatter: valueFmt,
    hide,
  };
}

export function genActionStatus(
  translateService: TranslateService,
  field: string,
  headerName: string,
  isCampaignEditable: boolean,
  filterParamsInfo?: GridFilterParamsInfo
): ColDef {
  return {
    ...commonParams(field, headerName),
    cellRenderer: ActionStatusRenderer,
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      ...commonFilterParams(filterParamsInfo),
      values: [
        CampaignActionStatus.PENDING,
        CampaignActionStatus.SENT,
        CampaignActionStatus.DUPLICATECLIENT,
        CampaignActionStatus.NOACTION,
      ],
      cellRenderer: ActionStatusRenderer,
      valueFormatter: (params: ValueFormatterParams) =>
        params?.value ? translateService.instant(params.value.toLowerCase()) : '',
    },
    cellRendererParams: {
      isCampaignEditable,
    },
    width: 100,
  };
}
