import {Component, Inject, OnDestroy, OnInit,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {finalize, first,} from 'rxjs/operators';
import {Story, StoryService,} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {DataService} from 'src/app/services/data.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EFormValidators, EModalType,} from 'src/app/util/enum';

/**
 * Component to show the story details (can handle create and edit).
 */
@Component({
  selector: 'app-story-details-form-dialog',
  templateUrl: './story-details-form-dialog.component.html',
})
export class StoryDetailsFormDialogComponent
  implements OnInit, OnDestroy, ModalSubComponent {

  subscriptions: Subscription[] = [];
  story: Story;
  submitStory: Story;

  constructor(
    private storyService: StoryService,
    private dataService: DataService,
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: { story: Story } }
  ) {
    this.story = data.data?.story;
  }

  ngOnInit(): void {
    // Initial check if form is valid
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get formValidators() {
    return EFormValidators;
  }

  /**
   * Triggered by parent component story-modal.component
   * Do not proceed if form is invalid
   */
  modalAction(modalType: EModalType): void {
    if (!this.submitStory) {
      return
    }
    switch (modalType) {
      case EModalType.editStory:
        this.dataService.updateLoading(true);
        this.storyService
          .updateStory(this.submitStory)
          .pipe(
            first(),
            finalize(() => {
              this.dataService.updateLoading(false);
              this.dialogRef.componentInstance.resetToolbarActionButtons();
            })
          )
          .subscribe({
            next: (storyData) => {
              this.dataService.updateStory(storyData);
              this.dialogRef.close({success: true});
            },
          });
        break;
    }
  }

  onFormValidityChange(story: Story) {
    this.submitStory = story;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.submitStory === undefined;
  }

}
