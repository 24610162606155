import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DatePipe} from '@angular/common';
import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap,} from 'rxjs/operators';
import {CodeTableEntry, ExternalLink, Story, Tag, TagService, User, UserService,} from 'src/app/api/core';
import {codeTableEntries, CodeTableService} from 'src/app/services/code-table.service';
import {GlobalService} from 'src/app/services/global.service';
import {MAX_DATE, MIN_DATE} from 'src/app/util/date-formatter';
import {ECodeTables, EFormStatus, EFormValidators,} from 'src/app/util/enum';
import {PermissionService} from '../../../services/permission.service';
import {EProtectedActions} from '../../../util/protected-actions';
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

/**
 * Component to show the story details (can handle create and edit).
 */
@Component({
  selector: 'app-details-form',
  templateUrl: './story-details-form.component.html',
})
export class StoryDetailsFormComponent
  implements OnInit, OnDestroy {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  subscriptions: Subscription[] = [];

  @Input()
  readOnlyMainData = false;

  @Input()
  get story(): Story {
    return this._story;
  }

  set story(value: Story) {
    this.handleStory(value);
  }

  _story: Story;
  // store last submitted data to avoid emitting the same data multiple times
  private lastSubmittedData: Story;
  @Output()
  dataValidityChanged = new EventEmitter<Story>();

  storyForm = this.fb.group({
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(EFormValidators.textFieldMaxLength),
        Validators.pattern(EFormValidators.textFieldNotBlankPattern),
      ],
    ],
    useCase: [null as CodeTableEntry],
    validFrom: [''],
    validTo: [''],
    info: ['', Validators.maxLength(10000)],
    tags: this.fb.array([] as Tag[], this.tagsRequired),
    hot: [false, Validators.required],
    once: [false, Validators.required],
    externalLinks: [[]],
  }, {
    validators: this.externalLinksValidator.bind(this)
  });

  useCases: CodeTableEntry[] = [];
  publicationTypes: CodeTableEntry[] = [];
  hubs: CodeTableEntry[] = [];
  externalLinks: ExternalLink[] = [];

  authorControl = new FormControl('');
  filteredAuthors$: Observable<User[]>;
  tagControl = new FormControl();
  filteredTags$: Observable<Tag[]>;

  actionButtonLabel = 'Create';
  minDate = MIN_DATE;
  maxDate = MAX_DATE;
  canEditInfo = false;

  constructor(
    private fb: FormBuilder,
    private tagService: TagService,
    private userService: UserService,
    private globalService: GlobalService,
    private codeTableService: CodeTableService,
    private datePipe: DatePipe,
    private permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public data: { data: { story: Story } }
  ) {
    this.initCodeTables();
    this.handleStory(data.data?.story);
    this.canEditInfo = this.permissionService.hasAnyPermission(
      EProtectedActions.editStoryInfo
    );
  }

  ngOnInit(): void {
    this.filteredAuthors$ = this.authorControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => this.userService.searchUsers(val))
    );
    this.filteredTags$ = this.tagControl.valueChanges.pipe(
      filter((val: string) => val && val.length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val) => this.tagService.searchTags(val.substring(0, 255)))
    );
    this.subscriptions.push(
      this.storyForm.statusChanges.subscribe(
        (status) => {
          if (status === EFormStatus.VALID) {
            const editStory: Story = {
              ...this._story,
              ...this.storyForm.getRawValue(),
              externalLinks: this.externalLinks,
            };
            // transform dates to server format
            editStory.validFrom = editStory.validFrom
              ? this.datePipe.transform(editStory.validFrom, 'yyyy-MM-dd')
              : null;
            editStory.validTo = editStory.validTo
              ? this.datePipe.transform(editStory.validTo, 'yyyy-MM-dd')
              : null;
            // emit only if data has changed
            if (!this.lastSubmittedData || JSON.stringify(this.lastSubmittedData) !== JSON.stringify(editStory)) {
              this.dataValidityChanged.emit(editStory);
              this.lastSubmittedData = editStory;
            }
          } else {
            this.dataValidityChanged.emit(undefined)
          }
        }
      )
    );
    this.subscriptions.push(
      this.storyForm.controls.validFrom.valueChanges.subscribe((value) => {
        const isInvalid = !this.storyForm.controls.validFrom.valid;
        const parseErrorText: string =
          this.storyForm.controls.validFrom.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.storyForm.controls.validFrom.reset(null, {
            emitEvent: false,
          });
        }
      })
    );
    this.subscriptions.push(
      this.storyForm.controls.validTo.valueChanges.subscribe((value) => {
        const isInvalid = !this.storyForm.controls.validTo.valid;
        const parseErrorText: string =
          this.storyForm.controls.validTo.errors?.matDatepickerParse?.text;
        if (value === null && isInvalid && parseErrorText === '') {
          // remove matDatepickerParse error for empty string since field is not required
          this.storyForm.controls.validTo.reset(null, {
            emitEvent: false,
          });
        }
      })
    );
    if (this.readOnlyMainData) {
      this.storyForm.get('name').disable();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get formValidators() {
    return EFormValidators;
  }

  get tags() {
    return this.storyForm.controls.tags as FormArray;
  }

  addTag(tag: Tag): void {
    if (this.doesTagExist(tag)) {
      return;
    }
    const storyTagForm = this.fb.group({
      id: [null as number, Validators.required],
      name: ['', Validators.required],
    });
    if (tag) {
      storyTagForm.patchValue({...tag});
    }
    this.tags.push(storyTagForm);
  }

  private doesTagExist(value: Tag): boolean {
    return (
      this.tags.getRawValue().findIndex((tag) => tag.name === value.name) !== -1
    );
  }

  getOrCreateTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()?.substring(0, 255);
    if (value) {
      this.tagService.getOrCreateTag(value).subscribe({
        next: (tag) => {
          // ToDo handle errors
          this.addTag(tag);
        },
      });
    }
    event.chipInput.clear();
  }

  removeTag(tag: Tag): void {
    const tags = this.storyForm.get('tags').value;
    tags.forEach((item, index) => {
      if (item === tag) {
        this.tags.removeAt(index);
      }
    });
  }

  onAuthorSelect(event: MatAutocompleteSelectedEvent): void {
  const selectedAuthor = event.option?.value;
  if (selectedAuthor && (!this._story.author || this._story.author.id !== selectedAuthor.id)) {
    this._story.author = selectedAuthor;
    this.authorControl.setValue(selectedAuthor.fullname, {
      onlySelf: true,
      emitEvent: false,
    });
    this.storyForm.updateValueAndValidity({ emitEvent: true });
  } else {
    this._story.author = undefined;
  }
}

  onTagSelect(event: MatAutocompleteSelectedEvent): void {
    if (event.option?.value) {
      this.tagInput.nativeElement.value = '';
      this.addTag(event.option.value);
      this.tagControl.setValue('');
    }
  }

  private initCodeTables() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.useCase),
    ]).subscribe(([useCases]) => {
      this.useCases = useCases;
      const userHubs = this.globalService.getCurrentUserData().hubs;
      const hub = userHubs.find(d => d.id === this._story?.hub?.id) || userHubs[0];
      this.hubs = codeTableEntries(userHubs, hub).sort((a, b) => b.name.localeCompare(a.name));
    });
  }

  private handleStory(story: Story): void {
    if (story) {
      this.actionButtonLabel = 'Save';
      this._story = story;
      this.storyForm.patchValue({
        name: story.name,
        author: story.author,
        validFrom: story.validFrom,
        validTo: story.validTo,
        hot: story.hot,
        info: story.info,
        useCase: (story.useCase ? this.useCases.find(d => story.useCase.id === d.id) : null),
      });
      if (story.author) {
        // auto-complete author is not part of the story-form, set it manually
        this.authorControl.setValue(story.author.fullname, {
          onlySelf: true,
          emitEvent: false,
        });
      }
      // add tags
      story.tags.forEach((t) => this.addTag(t));
      this.externalLinks = [...(story.externalLinks || [])].map(l => ({...l}));
    }
  }

  private tagsRequired(formControl: AbstractControl): ValidationErrors {
    if (this._story) {
      Validators.required(formControl);
    } else {
      return null;
    }
  }

  handleExternalLinksChanged(links: ExternalLink[]) {
    this.externalLinks = links;
    this.storyForm.updateValueAndValidity();
  }

  externalLinksValidator(_): ValidationErrors | null {
    if (!this?.externalLinks) return null;
    const valid = (this?.externalLinks || []).every(l => !!l.url);
    if (valid) return null;
    return {
      externalLinks: true,
    };
  }

}
