import {Params} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {CampaignStatus, SuitabilityState} from 'src/app/api/core';
import {TranslateService} from '@ngx-translate/core';
import {EGridFilterType} from '../util/enum';
import {FormGroup} from "@angular/forms";
import {AutoCompleteApiParams} from "../util/grid/grid-renderer.util";
import {ColDef, ValueFormatterParams} from "ag-grid-community";
import {SetFilterValuesFuncParams} from "ag-grid-enterprise";

export interface GridFilterParamsInfo {
  /*
   * A path to use for sorting/filtering.
   * Will be overridden by filterPath/sortPath if set.
   */
  customPath?: string;
  /*
   * A path to use for filtering.
   */
  filterPath?: string;
  /*
   * A path to use for sorting.
   */
  sortPath?: string;
  /**
   * Value formatter function
   */
  valueFormatter?: (params: ValueFormatterParams) => string;

  /**
   * Text formatter function used to transform the values before being sent for filtering
   * @param d
   */
  textFormatter?: (d: any) => any;
  /**
   * Value getter function
   */
  valueGetter?: (params: any) => any;
  /**
   * Parameters to generate autocomplete options for text filters
   * Api method must be provided in arrow form
   * string[] assumed to be sorted, without *** and duplicate entries in colDef or genColumnMethod (values of potential options)
   * Use () => string[] if the table entries change (e.g. user collection picker) or problem with data loading
   */
  autoCompleteParams?: AutoCompleteApiParams | string[] | (() => string[]);
  /**
   * If true the filter will be a multi-select filter (only works with text filters)
   */
  isMultiSelect?: boolean;

  [key: string]: any;
}

export interface GridDateInfo {
  field: string;
  headerName?: string;
  dateFormatter?: (data: any) => string;
  filterParamsInfo?: GridFilterParamsInfo;
}

export interface GridSuitabilityInfo {
  translateService: TranslateService;
  field: string;
  headerName?: string;
  colId?: string;
  filterParamsInfo?: GridFilterParamsInfo;
  stateInfo: (data: any) => GridStateInfo;
  callback?: (data: any) => void;
}

export interface GridStateInfo {
  state: GridStateEnum;
}

export interface GridSuitabilityIconAndColorClass {
  icon: string;
  colorClass: string;
}

export type GridStateEnum = SuitabilityState;

export interface GridIconInfo {
  field: string;
  headerName?: string;
  tooltip?: string | ((data: any) => string);
  icon: string | ((data: any) => string);
  iconClass?: string | ((data: any) => string);
  cellClass?: string | string[];
  colId?: string;
  customFilter?: any;
  filterParamsInfo?: GridFilterParamsInfo;
}

export interface GridIconButtonInfo {
  getRouterUrl?: (data: any) => string;
  getQueryParams?: (data: any) => Params;
  callback?: (data: any) => void;
  tooltip?: string | ((data: any) => string);
  icon: string | ((data: any) => string);
  iconOutlined?: boolean;
  hidden?: (data: any) => boolean;
  headerName?: string;
  finishedLoading?: PerRowObservable;
  disabled?: (data: any) => boolean;
}

export class PerRowObservable {
  private subjectMap: { [key: number]: Subject<void> } = {};

  next(id: number) {
    this.getOrCreateSubject(id).next();
  }

  subscribe(id: number, observer: () => void): Subscription {
    return this.getOrCreateSubject(id).asObservable().subscribe(observer);
  }

  private getOrCreateSubject(id: number): Subject<void> {
    let subject = this.subjectMap[id];
    if (!subject) {
      subject = new Subject<void>();
      this.subjectMap[id] = subject;
    }
    return subject;
  }
}

export interface GridDialogContentInfo {
  field: string;
  labelValue: (data: any) => string;
  translateLabel?: boolean;
  headerName?: string;
  colId?: string;
  customFilter?: any;
  filterParamsInfo?: GridFilterParamsInfo;
  callback?: (data: any, action: string) => void;
  iconGetter?: (data: any) =>  string | undefined;
}

export interface GridCustomContentInfo {
  field: string;
  headerName?: string;
  colId?: string;
  customFilter?: any;
  filterParamsInfo?: GridFilterParamsInfo;
  hidden?: (data: any) => boolean;
  callback?: (data: any, action: string) => void;
  label?: string | ((data: any) => string);
}

export interface GridIconLabelInfo {
  field: string;
  headerName?: string;
  icon?: string | ((data: any) => string);
  colId?: string;
  customFilter?: any;
  filterParamsInfo?: GridFilterParamsInfo;
  hidden?: (data: any) => boolean;
  callback?: (data: any, action: string) => void;
  label?: string | ((data: any) => string);
  labelCallback?: (data: any, action: string) => void;
  iconClass?: string;
  outlined?: boolean;
  align?: 'left' | 'right' | 'suffix' | undefined,
  tooltip?: string | ((data: any) => string);
  iconTooltip?: string | ((data: any) => string);
}

export interface GridActionLanguageInfo {
  field: string;
  headerName?: string;
  colId?: string;
  customFilter?: any;
  filterParamsInfo?: GridFilterParamsInfo;
  hidden?: (data: any) => boolean;
  campaignStatus: CampaignStatus;
}
export interface GridViewedStatusInfo {
  field: string;
  headerName: string;
  icon: (data: any) => string;
  tooltip: (data: any) => string;
  values: string[] | ((params: SetFilterValuesFuncParams) => void);
  customPath?: string;
}

// =begin GridFilter - dialog components that are used in the grid filter dialog
export interface GridFilterConfig {
  key: string;
  name: string;
  type: EGridFilterType;
  values?: any[] | ((params: any) => void); // values for the set
  valueFormatter?: ((params: any) => string); // value formatter for the set
  hide?: boolean;
  important?: boolean;
  autoCompleteParams?: AutoCompleteApiParams | string[] | (() => string[]);
  isMultiSelect?: boolean;
  filterComparator?: (a: any, b: any) => number;
}

export interface GridFilterModelItem {
  filterType: string;
  type?: string;
  filter?: string;
  filterTo?: string;
  values?: string[];
  value?: string;
}

export interface GridFilterModel {
  [key: string]: GridFilterModelItem;
}

export interface GridFilterItemComponentFilter {
  config: GridFilterConfig;
  model: GridFilterModelItem;
  form: FormGroup;
}
export interface GridFilterItemComponent {
   getModel(): GridFilterItemComponentFilter | undefined;
}

// local grid interface with additional properties
export interface GridColDef extends ColDef {
  important?: boolean;
}

// =end GridFilter
