/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PublicationTypeSendApproach = 'FORCED_DIRECT' | 'VIA_RM';

export const PublicationTypeSendApproach = {
    FORCEDDIRECT: 'FORCED_DIRECT' as PublicationTypeSendApproach,
    VIARM: 'VIA_RM' as PublicationTypeSendApproach
};

