<app-card [showSpinner]="loading$ | async">
  <div card-header>
    <span>{{ cardHeader | translate }} ({{ products.length }})</span>
  </div>
  <app-config-icon
    iconIdentifier="add"
    [tooltip]="'editProducts' | translate"
    (click)="handleEditProducts()"
    card-action
    *ngIf="allowEditProducts && (story || !autoSyncChecked)"
  ></app-config-icon>
  <app-config-icon
    card-action
    iconIdentifier="restore"
    [tooltip]="restoreProductsTooltip | translate"
    (click)="restoreProductsFromStory()"
    *ngIf="campaign?.story && allowEditProducts && !autoSyncChecked"
  ></app-config-icon>

  <mat-slide-toggle
    card-action
    color="primary"
    [disabled]="!autoSyncBuyProductsEnabled"
    [checked]="autoSyncBuyProducts"
    (change)="handleToggleAutoSyncProducts($event, campaignProductType.BUY)"
    [matTooltip]="autoSyncBuyProductsMsg"
    *ngIf="autoSyncFeature && allowEditProducts && productType === campaignProductType.BUY"
  >
  </mat-slide-toggle>
  <mat-slide-toggle
    card-action
    color="primary"
    [disabled]="!autoSyncSellProductsEnabled"
    [checked]="autoSyncSellProducts"
    (change)="handleToggleAutoSyncProducts($event, campaignProductType.SELL)"
    [matTooltip]="autoSyncSellProductsMsg"
    *ngIf="autoSyncFeature && allowEditProducts && productType === campaignProductType.SELL"
    >
  </mat-slide-toggle>
  <div card-body>
    <app-grid
      *ngIf="products"
      tableId="products-card"
      [columnDefs]="productsColumns"
      [gridOptions]="productsGridOptions"
      [rowData]="products"
    ></app-grid>
  </div>
</app-card>
