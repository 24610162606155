import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, Observable, Subscription, switchMap } from 'rxjs';
import { DocumentService, Story } from 'src/app/api/core';
import { ModalData } from 'src/app/models/modal.model';
import { DataService } from 'src/app/services/data.service';
import {
  DialogHeight,
  DialogWidth,
  ModalService,
} from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StoryDetailsFormComponent } from 'src/app/story/shared/story-details-form/story-details-form.component';
import { EModalType } from 'src/app/util/enum';
import { DocumentTransferService } from 'src/app/services/document-transfer.service';
import { PermissionService } from '../../../../services/permission.service';
import { CampaignInfoComponent } from '../../../../campaign/views/campaign-overview/campaign-info/campaign-info.component';
import { EProtectedActions } from '../../../../util/protected-actions';
import { GlobalService } from '../../../../services/global.service';
import { ExternalLinkListComponent } from '../../../../shared/external-link-list/external-link-list.component';
import {tap} from "rxjs/operators";
import {
  StoryDetailsFormDialogComponent
} from "../../../shared/story-details-form-dialog/story-details-form-dialog.component";

/**
 * Component story detail showing and editing.
 */
@Component({
  selector: 'app-story-overview-details',
  templateUrl: './story-overview-details.component.html',
})
export class StoryOverviewDetailsComponent {
  loading$: Observable<boolean>;

  story: Story;
  canEditInfo = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private documentService: DocumentService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dataService: DataService,
    protected permissionService: PermissionService,
    private globalService: GlobalService
  ) {
    this.canEditInfo = this.permissionService.hasAnyPermission(
      EProtectedActions.editStoryInfo
    );
    this.loading$ = this.dataService.loading$;
    this.subscriptions.push(
      this.dataService.story$
        .pipe(filter((story) => !!story))
        .subscribe((story) => (this.story = story))
    );
    this.onImageUpload = this.onImageUpload.bind(this);
    this.onImageDelete = this.onImageDelete.bind(this);
  }

  get protectedActions() {
    return EProtectedActions;
  }

  get validPeriod() {
    return this.globalService.formatDatePeriod(
      this.story.validFrom,
      this.story.validTo
    );
  }

  get combinedStatus() {
    return this.story.status;
  }

  editStoryDetails() {
    const modalData: ModalData = {
      type: EModalType.editStory,
      title: EModalType.editStory,
      data: { story: this.story },
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: StoryDetailsFormDialogComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      'story-detail-dialog'
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.notificationService.handleSuccess(
          this.translateService.instant('updateStoryContentSuccess')
        );
      }
    });
  }

  onImageUpload(documentId: string) {
    return this.documentService.updateStoryImage(this.story.id, documentId)
      .pipe(tap(url => this.story.coverImageUrl = url));
  }

  onImageDelete() {
    return this.documentService.removeStoryImage(this.story.id)
      .pipe(tap(() => this.story.coverImageUrl = null));
  }

  showInfo() {
    const modalData: ModalData = {
      type: EModalType.storyInfo,
      title: EModalType.storyInfo,
      data: {
        info: this.story.info,
      },
      submitBtn: {
        label: this.translateService.instant('ok'),
        callback: () => {
          dialogRef.close();
        },
      },
      component: CampaignInfoComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, false, false, DialogWidth.HALF, DialogHeight.HALF
    );
  }
  showErrors() {
    const modalData: ModalData = {
      type: EModalType.storyInfo,
      title: 'errors',
      data: {
        info: this.story.errors,
      },
      submitBtn: {
        label: this.translateService.instant('ok'),
        callback: () => {
          dialogRef.close();
        },
      },
      component: CampaignInfoComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, false, false, DialogWidth.HALF, DialogHeight.HALF
    );
  }
  showExternalLinks() {
    const modalData: ModalData = {
      type: EModalType.storyInfo,
      title: this.translateService.instant('externalLinkLabel'),
      data: {
        links: this.story.externalLinks,
      },
      submitBtn: {
        label: this.translateService.instant('ok'),
        callback: () => {
          dialogRef.close();
        },
      },
      component: ExternalLinkListComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, false, false, DialogWidth.HALF, DialogHeight.HALF
    );
  }
}
