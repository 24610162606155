<app-tabs-panel
  [tabs]="tabs"
  [activeTab]="activeTab"
  [defaultTab]="defaultTab"
  [tabChangeAllowed]="true"
  (tabChanged)="onTabChange($event)"
>
</app-tabs-panel>
<app-config-icon class="publication-type-loading" iconIdentifier="save" *ngIf="loading"></app-config-icon>
<app-publication-type-main-data *ngIf="activeTabIndex==0"></app-publication-type-main-data>
<app-publication-type-internal-comms *ngIf="activeTabIndex==1"></app-publication-type-internal-comms>
<app-publication-type-send-controls *ngIf="activeTabIndex==2"></app-publication-type-send-controls>
<app-publication-type-client-comms *ngIf="activeTabIndex==3"></app-publication-type-client-comms>
<app-publication-type-monitoring *ngIf="activeTabIndex==4"></app-publication-type-monitoring>

<div class="tab-buttons">
  <button mat-raised-button [disabled]="loading || !canPrev" (click)="onPrev()">
    {{ 'back' | translate }}
  </button>
  <button mat-raised-button color="primary" [disabled]="loading || !canNext" (click)="onNext()">
    {{ 'next' | translate }}
  </button>
</div>
