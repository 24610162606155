<div class="toolbar-container">
  <div class="toolbar-left">
    <div class="available-toolbar">
      <div class="dropdown-selection-bar">
        <div class="header-label">
          {{ 'quickSelection' | translate }}:
        </div>
        <app-dropdown-selection-bar
          #selectionDropdown
          useDropdownSelect="true"
          label="{{ 'select' | translate }}"
          [actions]="[]"
          [hiddenActions]="selectionHiddenActions"
          (clearSelection)="clearSelection()"
        ></app-dropdown-selection-bar>
      </div>
      <div class="href-link-container">
        <div class="href-link font-small" (click)="showTargetedClients()">{{ 'whichClientsAreHere' | translate }}
      </div>
      </div>
    </div>
  </div>
  <div class="toolbar-spacer"></div>
  <div class="toolbar-right">
    <div class="href-link font-small" (click)="viewSwitched.emit()">&lt; {{ 'switchToDetailedScreen' | translate }}
    </div>
  </div>
</div>
<div class="selected-available-container">
  <app-card class="selected">
    <div card-header>
      <span>{{ 'chooseListClientsAndShiftToTheRight' | translate }}</span>
    </div>
    <div card-body>
      <app-grid
        #campaign_compact_available_list
        tableId="campaign-compact-available-list"
        [showSearch]="true"
        [data]="availableData"
        [columnDefs]="availableColumnDefs"
        [gridOptions]="availableGridOptions"
        searchPlaceholder="searchQuickView"
        disableSidebar="true"
        [importantColumns]="['portfolioContainsBuyProducts', 'assignee.username']"
      ></app-grid>
    </div>
  </app-card>
  <div class="actions">
    <button
      mat-flat-button
      color="primary"
      [class.select-btn]="dataIsSelected"
      [disabled]="!dataIsSelected"
      (click)="addSelected()"
    >
      <app-config-icon iconIdentifier="double_arrow"></app-config-icon>
    </button>
  </div>
  <app-card class="available">
    <div card-header>
      <span>{{ 'listedClientsWillReceiveYourCampaign' | translate }}</span>
    </div>
    <div card-body>
      <app-grid
        tableId="campaign-compact-selected-list"
        [rowData]="selectedData"
        [columnDefs]="selectedColumnDefs"
        [gridOptions]="selectedGridOptions"
        disableSidebar="true"
        [importantColumns]="['portfolioContainsBuyProducts']"
      ></app-grid>
    </div>
  </app-card>
</div>
<div class="compact-footer" *ngIf="permissionService.hasAnyPermission(EProtectedActions.executeActionCampaign)">
  <button mat-raised-button (click)="goBack()">
    {{ 'backFastTrack' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="executeSelectedActions()" [disabled]="selectedData.length === 0">
    {{ 'send' | translate }}
  </button>
</div>
