import {BehaviorSubject, Observable} from "rxjs";
import {PublicationType, PublicationTypeTimeFrame} from "../api/core";
import {Injectable} from "@angular/core";
import {PermissionService} from "../services/permission.service";
import {FormControl, FormGroup} from "@angular/forms";
import {EProtectedActions} from "../util/protected-actions";

@Injectable({providedIn: 'root'})
export class PublicationTypeData {
  private pubTypeSource: BehaviorSubject<PublicationType>;
  private loadingSource: BehaviorSubject<boolean>;
  pubType$: Observable<PublicationType>;
  loading$: Observable<boolean>;
  oldPubType: PublicationType | undefined;

  constructor(
    protected permissionService: PermissionService,
  ) {
    this.pubTypeSource = new BehaviorSubject<PublicationType>(null);
    this.loadingSource = new BehaviorSubject(false);
    this.pubType$ = this.pubTypeSource.asObservable();
    this.loading$ = this.loadingSource.asObservable();
  }

  setPublicationType(data: PublicationType) {
    if (this.oldPubType && data) {
      if (this.oldPubType.bannerImage === data.bannerImage) {
        data.bannerImageUrl = this.oldPubType.bannerImageUrl;
      }
      if (this.oldPubType.clientBannerImage === data.clientBannerImage) {
        data.clientBannerUrl = this.oldPubType.clientBannerUrl;
      }
    }
    this.oldPubType = data;
    this.pubTypeSource.next(data);
  }

  isReadonly(data: PublicationType) {
    const searchParams = new URLSearchParams(location.search);
    const readonly = searchParams.get('readonly') === '1';
    const closed = data.closed;
    const canEdit = this.permissionService.hasAnyPermission(EProtectedActions.publicationTypeEdit);
    return !canEdit || readonly || !closed;
  }

  setLoading(value: boolean) {
    this.loadingSource.next(value);
  }

  getFormControls(group: FormGroup, ...names: string[]): FormControl[] {
    return names.map(name => group.get(name)).filter(Boolean) as FormControl[];
  }

  timeFrameTranslationKey(src: PublicationTypeTimeFrame): string {
    switch (src) {
      case PublicationTypeTimeFrame.NONE:
        return 'publicationTypeTimeFrameNone';
      case PublicationTypeTimeFrame.NUMBEROFDAYS:
        return 'publicationTypeTimeFrameNumberOfDays';
      case PublicationTypeTimeFrame.NUMBEROFWEEKS:
        return 'publicationTypeTimeFrameNumberOfWeeks';
      case PublicationTypeTimeFrame.NUMBEROFMONTHS:
        return 'publicationTypeTimeFrameNumberOfMonths';
      case PublicationTypeTimeFrame.ENDOFCURRENTMONTH:
        return 'publicationTypeTimeFrameEndOfCurrentMonth';
      case PublicationTypeTimeFrame.ENDOFNEXTMONTH:
        return 'publicationTypeTimeFrameEndOfNextMonth';
      case PublicationTypeTimeFrame.STARTOFNEXTMONTH:
        return 'publicationTypeTimeFrameStartOfNextMonth';
      default:
        return 'publicationTypeTimeFrameNone';
    }
  }

  timeFrameHasValue(src: PublicationTypeTimeFrame): boolean {
    switch (src) {
      case PublicationTypeTimeFrame.NUMBEROFDAYS:
        return true;
      case PublicationTypeTimeFrame.NUMBEROFWEEKS:
        return true;
      case PublicationTypeTimeFrame.NUMBEROFMONTHS:
        return true;
      default:
        return false;
    }
  }
}
