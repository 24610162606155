import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, filter, finalize, first, Observable, Subscription} from 'rxjs';
import {
  AutoSyncUpdate,
  Campaign,
  CampaignProductType,
  CampaignService,
  CampaignStats,
  CampaignStatus,
  ContentDefinition,
  ContentService,
  IntermediaryService,
  ListParams,
  PortfolioService,
  ProductListItem,
  UserInteraction,
  UserService,
} from 'src/app/api/core';
import {ContentData} from 'src/app/models/content.model';
import {CustomStatisticsData} from 'src/app/models/custom_data.model';
import {ModalData} from 'src/app/models/modal.model';
import {DataService, LoadedChart} from 'src/app/services/data.service';
import {FilterUtilsService} from 'src/app/services/filter-utils.service';
import {DialogHeight, DialogWidth, ModalService} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ContentFormComponent} from 'src/app/shared/content-form/content-form.component';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EChartTypes, EFilterHeaderActionType, EModalType, EReferenceType, StoryProductType,} from 'src/app/util/enum';
import {FilterConfig} from '../../../models/filter.model';
import {convertFilterConfigToFilterBody} from '../../../services/filter.config-body';
import {PermissionService} from '../../../services/permission.service';
import {GridDataProvider} from '../../../shared/grid/data-source';
import {EProtectedActions} from '../../../util/protected-actions';
import {
  CAMPAIGN_OVERVIEW_PREVIEW_TABS,
  CAMPAIGN_OVERVIEW_TABS,
  DECENTRALIZED_CAMPAIGN_OVERVIEW_PREVIEW_TABS,
  DECENTRALIZED_CAMPAIGN_OVERVIEW_TABS,
} from 'src/app/util/tab.constants';
import {Tab} from 'src/app/models/tabs.model';
import {CampaignIntermediaryAddComponent} from "./campaign-intermediary-add/campaign-intermediary-add.component";
import {CampaignIntermediaryListComponent} from "./campaign-intermediary-list/campaign-intermediary-list.component";
import {
  productSearchDialog,
  productSearchMerge
} from "../../../product/shared/product-search-dialog/product-search-dialog.component";
import {CampaignSendToMyselfComponent} from "./campaign-send-to-myself/campaign-send-to-myself.component";
import {PageBarButton} from "../../../services/page.bar.service";
import {PipeLogicService} from "../../../services/pipe.logic.service";
import {ActionType} from "./campaign-actions-list-utils";

export type PortfolioListType = 'list' | 'hierarchy';

/**
 * Component for campaign overview.
 */
@Component({
  selector: 'app-campaign-overview',
  templateUrl: './campaign-overview.component.html',
})
export class CampaignOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(CampaignIntermediaryListComponent)
  intermediaryList: CampaignIntermediaryListComponent;
  intermediaryButtons: PageBarButton[] = [
    {
      icon: 'add',
      text: 'addIntermediaries',
      click: () => {this.addIntermediaries();},
      show: () => this.permissionService.hasAnyPermission(EProtectedActions.addIntermediaryCampaign) &&
        this.pipeLogicService.isCampaignEditablePipe(this.campaign.status),
    },
    {
      icon: 'mail',
      text: 'sendToMyself',
      click: () => {this.onSendToMyself();},
      show: () => this.permissionService.hasAnyPermission(EProtectedActions.executeActionCampaign) &&
        this.campaign.status === CampaignStatus.LAUNCHED,
    },
    {
      icon: 'expand',
      text: 'expandAll',
      click: () => this.expandAllSource.next(),
    },
    {
      icon: 'compress',
      text: 'collapseAll',
      click: () => this.collapseAllSource.next(),
    }
  ]

  dataLoading$: Observable<boolean>;
  selectedChart?: EChartTypes;
  loadedChart: LoadedChart = {};
  showAllowedUsers = false;
  portfolioListType: PortfolioListType = 'list';

  campaign: Campaign;
  canEditFilters = false;
  tabs: Tab[] = CAMPAIGN_OVERVIEW_PREVIEW_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;
  detailsTab = 0;
  contentTab = 1;
  suitabilityTab = 2;
  portfoliosTab = 3;
  intermediariesTab = 4;
  usersTab = 5;

  currentFilter: FilterConfig;
  private subscriptions: Subscription[] = [];
  maxSelectedBuyProducts: number = 20;
  maxSelectedSellProducts: number = 20;

  contentDefinitions: ContentDefinition[] = null;
  isDeleting = false;
  useCompactSend = false;

  collapseAll$: Observable<void>;
  expandAll$: Observable<void>;
  private collapseAllSource = new BehaviorSubject<void>(undefined);
  private expandAllSource = new BehaviorSubject<void>(undefined);

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly modalService: ModalService,
    protected readonly campaignService: CampaignService,
    protected readonly translateService: TranslateService,
    protected readonly dataService: DataService,
    protected readonly filterService: FilterUtilsService,
    protected readonly notificationService: NotificationService,
    protected readonly portfolioService: PortfolioService,
    protected readonly permissionService: PermissionService,
    protected readonly intermediaryService: IntermediaryService,
    protected readonly contentService: ContentService,
    protected readonly userService: UserService,
    protected readonly pipeLogicService: PipeLogicService,
  ) {
    this.expandAll$ = this.expandAllSource.asObservable();
    this.collapseAll$ = this.collapseAllSource.asObservable();
  }

  /**
   * Data Provider for Product grid
   */
  data: GridDataProvider = (listParams: ListParams) =>
    this.portfolioService.getPortfoliosPreview({
      hub: this.campaign.hub.ident,
      filter: convertFilterConfigToFilterBody(
        this.currentFilter || {},
        this.permissionService.userRoleData),
      listParams,
    });

  ngOnInit(): void {
    this.updateFlatView();
    this.dataLoading$ = this.dataService.chartDataLoading$;
    this.activatedRoute.data.pipe(first()).subscribe((data) => {
      this.dataService.updateCampaign(data.campaign);
    });
    this.subscriptions.push(
      this.dataService.loadedChart$.subscribe((value: LoadedChart) => {
        this.loadedChart = value;
      })
    );
    this.subscriptions.push(
      this.dataService.campaign$
        .pipe(filter((campaign) => !!campaign))
        .subscribe((campaign) => {
          const prevStatus = this.campaign?.status;
          this.campaign = campaign;
          this.canEditFilters = this.campaign.status === this.campaignStatus.DRAFT
            && (this.campaign.decentralized
                ? this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit)
                : this.permissionService.hasAnyPermission(EProtectedActions.editCampaignFilters)
            );
          this.updateTabs(prevStatus, campaign.status);
          this.filterService
            .getCampaignFilterById(this.campaign.id)
            .subscribe((f) => this.dataService.updateFilter(f));
          if (
            this.campaign.status !== this.campaignStatus.DRAFT &&
            !this.campaign.processing
          ) {
            this.dataService.updateLoading(true);
            this.campaignService
              .getCampaignStats(this.campaign.id)
              .pipe(
                first(),
                finalize(() => this.dataService.updateLoading(false))
              )
              .subscribe({
                next: (stats: CampaignStats) => {
                  const newStats: CustomStatisticsData = {
                    totalPortfolios: stats.totalCount,
                    filteredPortfolios: stats.selectedCount,
                    totalVolume: stats.totalValue,
                    filteredVolume: stats.selectedValue,
                    totalCampaignActions: stats.totalCampaignActions,
                    pendingActionsForCurrentUser:
                    stats?.pendingActionsForCurrentUser,
                    pendingActions: stats?.pendingCampaignActions,
                    totalActionsForCurrentUser:
                    stats?.totalActionsForCurrentUser,
                    pie: stats.pie,
                  };
                  this.dataService.updateData({
                    statistics: {...newStats},
                  });
                },
              });
          }
          if (this.contentDefinitions === null) {
            this.contentService.getContentDefinitions(this.campaign.id, EReferenceType.campaign)
              .subscribe((data) => {
                this.contentDefinitions = data;
              });
          }
        })
    );
    this.showAllowedUsers = this.permissionService.hasAnyPermission(
      EProtectedActions.viewCampaignAllowedUsers
    );
    this.subscriptions.push(
      this.dataService.filter$.subscribe(
        (f) => this.handleFilter(f)
      ),
      this.dataService.guiConfig$.subscribe(
        (gui) => {
          this.maxSelectedBuyProducts = +gui.productSearch.maxSelectedBuy;
          this.maxSelectedSellProducts = +gui.productSearch.maxSelectedSell;
        }
      )
    );
    this.subscriptions.push(
      this.permissionService.user$.subscribe(user => {
        this.useCompactSend = user.useCompactSend;
      })
    )
    setTimeout(() => {
      this.updateFlatView();
    }, 500);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.dataService.updateCampaign(null);
    this.dataService.updateFilter(null);
    this.dataService.updateData(null);
  }

  get storyProductType() {
    return StoryProductType;
  }

  get filterHeaderActionType() {
    return EFilterHeaderActionType;
  }

  get campaignStatus() {
    return CampaignStatus;
  }

  get modalType() {
    return EModalType;
  }

  get chartTypes() {
    return EChartTypes;
  }

  get protectedActions() {
    return EProtectedActions;
  }

  private updateFlatView() {
    const campaignOverviewTab = localStorage.getItem('campaignOverviewTab');
    if (!campaignOverviewTab) return;
    switch (campaignOverviewTab) {
      case 'flat':
        this.portfolioListType = 'list';
        break;
      case 'tree':
        this.portfolioListType = 'hierarchy';
        break;
      case 'intermediaries':
        this.defaultTab = this.tabs.find(d => d.text.includes('intermediaries')).text;
        this.activeTab = this.defaultTab;
        break;
    }
    localStorage.removeItem('campaignOverviewTab');
  }

  checkContentsValidAfterRemoval(contentId: number): boolean {
    let contents = this.campaign.contents.filter(c => c.id !== contentId);
    return contents.length === 0;
  }

  handleAddOrEditContent(contentData: ContentData): void {
    const modalData: ModalData = {
      type: contentData.content
        ? EModalType.updateCampaignContent
        : EModalType.createCampaignContent,
      title: contentData.content
        ? EModalType.updateCampaignContent
        : EModalType.createCampaignContent,
      data: contentData,
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ContentFormComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshCampaign();
        this.notificationService.handleSuccess(
          this.translateService.instant('updateCampaignContentSuccess')
        );
      }
    });
  }

  handleDeleteContent(contentId: number): void {
    if (this.checkContentsValidAfterRemoval(contentId)) {
      this.modalService.openConfirmationDialog({
        title: `${this.translateService.instant(
          'warning'
        )} - ${this.translateService.instant('invalid')}`,
        type: EModalType.confirmationDialog,
        data: {
          message: this.translateService.instant(
            'warningNoContentAfterRemoval'
          ),
        },
        cancelBtn: {label: this.translateService.instant('cancel')},
      } as ModalData);
      return;
    }
    const hasActiveActions = [CampaignStatus.FROZEN, CampaignStatus.LAUNCHED].includes(this.campaign.status);
    const modalData: ModalData = {
      title: this.translateService.instant('deleteContent?'),
      type: EModalType.confirmationDialog,
      data: {
        message: hasActiveActions ?
          this.translateService.instant('deleteContentQuestionWithActions')
          : this.translateService.instant('deleteContentQuestion'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.campaignService
            .deleteCampaignContent(this.campaign.id, contentId)
            .pipe(
              first(),
              finalize(() =>
                modalRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe({
              next: () => {
                modalRef.close(true);
                this.refreshCampaign();
                this.notificationService.handleSuccess(
                  this.translateService.instant('deleteCampaignContentSuccess')
                );
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  handleRestoreContent(): void {
    const modalData: ModalData = {
      title: this.translateService.instant('restoreFromStory?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant(
          'restoreCampaignContentDialogMessage'
        ),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('restore'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.campaignService
            .restoreCampaignContentFromStory(this.campaign.id)
            .pipe(
              first(),
              finalize(() =>
                modalRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe({
              next: (campaign) => {
                this.dataService.updateCampaign(campaign);
                this.notificationService.handleSuccess(
                  this.translateService.instant('restoreCampaignContentSuccess')
                );
                modalRef.close(true);
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  handleEditProducts(productType: StoryProductType): void {
    const modalTitleRef =
      productType === StoryProductType.BUY
        ? 'addBuyProducts'
        : 'addSellProducts';
    const oldProducts = productType == StoryProductType.BUY
      ? this.campaign.buyProducts
      : this.campaign.sellProducts;
    const maxSelected = productType === StoryProductType.BUY
      ? this.maxSelectedBuyProducts
      : this.maxSelectedSellProducts;
    productSearchDialog(
      this.modalService,
      this.translateService.instant(modalTitleRef),
      this.translateService.instant('add'),
      this.translateService.instant('cancel'),
      maxSelected,
      [this.campaign.hub.ident],
    ).subscribe(result => {
      if (!result) return;
      const newProducts = result.selected;
      const allProducts = productSearchMerge(
        oldProducts,
        newProducts,
        p => p.id
      );
      this.dataService.updateLoading(true);
      this.campaignService
        .updateCampaignProducts(this.campaign.id, {
          type: productType,
          products: allProducts,
        })
        .pipe(
          first(),
          finalize(() => {
            this.dataService.updateLoading(false);
          })
        )
        .subscribe((campaignData) => {
          this.dataService.updateCampaign(campaignData);
        });
    });
  }

  handleDeleteProduct(productId: number, productType: StoryProductType): void {
    const modalData: ModalData = {
      title: this.translateService.instant('removeCampaignProduct?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('removeCampaignProductQuestion'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          let products: ProductListItem[];
          if (productType === StoryProductType.BUY) {
            products = this.campaign.buyProducts;
          }
          if (productType === StoryProductType.SELL) {
            products = this.campaign.sellProducts;
          }
          const productIndex = products.findIndex((a) => a.id === productId);
          products.splice(productIndex, 1);
          this.dataService.updateLoading(true);
          this.campaignService
            .updateCampaignProducts(this.campaign.id, {
              type: productType,
              products,
            })
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe((campaign) => {
              this.dataService.updateCampaign(campaign);
              this.notificationService.handleSuccess(
                this.translateService.instant('productRemovedSuccess')
              );
              modalRef.close(true);
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  handleRestoreProducts(productType: StoryProductType): void {
    const typeName = productType === StoryProductType.BUY ? 'buy' : 'sell';
    const modalData: ModalData = {
      title: this.translateService.instant('restoreFromStory?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant(
          'restoreCampaignProductsDialogMessage',
          {typeNameVar: typeName}
        ),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('restore'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.dataService.updateLoading(true);
          this.campaignService
            .restoreCampaignProductsFromStory(this.campaign.id, {
              type: productType,
            })
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe({
              next: (campaign) => {
                this.dataService.updateCampaign(campaign);
                this.notificationService.handleSuccess(
                  this.translateService.instant(
                    'restoreCampaignProductsSuccess'
                  )
                );
                modalRef.close(true);
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  handleToggleAutoSyncContent(): void {
    this.handleToggleAutoSync(
      {
        content: !this.campaign.autoSyncContent,
      },
      this.campaign.autoSyncContent
    );
  }

  private handleToggleAutoSync(
    syncDto: AutoSyncUpdate,
    isEnabled: boolean
  ): void {
    const modalData: ModalData = {
      title: this.translateService.instant(
        isEnabled ? 'autoSyncToStoryDisable' : 'autoSyncToStoryEnable'
      ),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant(
          isEnabled
            ? 'autoSyncToStoryDisableMessage'
            : 'autoSyncToStoryEnableMessage'
        ),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('update'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.dataService.updateLoading(true);
          this.campaignService
            .autoSync(this.campaign.id, syncDto)
            .pipe(
              first(),
              finalize(() => {
                this.dataService.updateLoading(false);
                modalRef.componentInstance.resetToolbarActionButtons();
              })
            )
            .subscribe({
              next: (campaign) => {
                this.dataService.updateCampaign(campaign);
                this.notificationService.handleSuccess(
                  this.translateService.instant('autoSyncSettingUpdated')
                );
                modalRef.close(true);
              },
            });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  handleToggleAutoSyncProducts(productType: StoryProductType): void {
    if (productType === StoryProductType.BUY) {
      this.handleToggleAutoSync(
        {
          buyProducts: !this.campaign.autoSyncBuyProducts,
        },
        this.campaign.autoSyncBuyProducts
      );
    } else {
      this.handleToggleAutoSync(
        {
          sellProducts: !this.campaign.autoSyncSellProducts,
        },
        this.campaign.autoSyncSellProducts
      );
    }
  }

  handleToggleAutoSyncAllowedUsers(): void {
    this.handleToggleAutoSync(
      {
        allowedUsers: !this.campaign.autoSyncAllowedUsers,
      },
      this.campaign.autoSyncAllowedUsers
    );
  }

  onPortfolioListRefresh(): void {
    if (
      this.campaign.status !== this.campaignStatus.DRAFT &&
      !this.campaign.processing
    ) {
      this.dataService.updateLoading(true);
      this.campaignService
        .getCampaignStats(this.campaign.id)
        .pipe(
          first(),
          finalize(() => this.dataService.updateLoading(false))
        )
        .subscribe({
          next: (stats: CampaignStats) => {
            const newStats: CustomStatisticsData = {
              totalPortfolios: stats.totalCount,
              filteredPortfolios: stats.selectedCount,
              totalVolume: stats.totalValue,
              filteredVolume: stats.selectedValue,
              totalCampaignActions: stats.totalCampaignActions,
              pendingActionsForCurrentUser: stats?.pendingActionsForCurrentUser,
              pendingActions: stats?.pendingCampaignActions,
              totalActionsForCurrentUser: stats?.totalActionsForCurrentUser,
              pie: stats.pie,
            };
            this.dataService.updateData({
              statistics: {...newStats},
            });
          },
        });
    } else {
      this.handleFilter(this.currentFilter);
    }
  }

  private handleFilter(
    aFilter: FilterConfig,
    noScatter: boolean = false
  ): void {
    if (aFilter) {
      this.currentFilter = aFilter;
      if (
        this.campaign.filter !== this.currentFilter?.id &&
        this.campaign.status === CampaignStatus.DRAFT
      ) {
        this.dataService.updateLoading(true);
        this.campaignService
          .updateCampaign({
            ...this.campaign,
            filter: this.currentFilter?.id,
          })
          .pipe(finalize(() => this.dataService.updateLoading(false)))
          .subscribe((campaign) => this.dataService.updateCampaign(campaign));
      }
      this.updateData(noScatter);
    }
  }

  private updateData(noScatter: boolean = false) {
    if (this.campaign.status === CampaignStatus.DRAFT) {
      this.dataService.updateChartDataLoading(true);
      const options = this.dataService.getStatsOptions(
        this.selectedChart,
        noScatter
      );
      if (!noScatter) {
        this.dataService.resetLoadedChart();
      }
      this.filterService
        .getStatFromFilters(
          this.campaign.decentralized ? '' : this.campaign.hub.ident,
          this.currentFilter,
          ...options
        )
        .pipe(
          first(),
          finalize(() => this.dataService.updateChartDataLoading(false))
        )
        .subscribe({
          next: (data) => this.dataService.updateData(data, noScatter),
        });
    }
  }

  private refreshCampaign(): void {
    this.dataService.updateLoading(true);
    this.campaignService
      .getCampaign(this.campaign.id)
      .pipe(finalize(() => this.dataService.updateLoading(false)))
      .subscribe((campaign) => this.dataService.updateCampaign(campaign));
  }

  selectedChartChanged(e: EChartTypes) {
    this.selectedChart = e;
    if (this.dataService.shouldChartLoad(e, this.loadedChart)) {
      this.handleFilter(this.currentFilter, true);
    }
  }

  allowEditProducts(type: CampaignProductType): boolean {
    const isDecentralized = this.campaign.decentralized;
    if (isDecentralized) {
      return this.campaign.status === CampaignStatus.DRAFT &&
        this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit)
    }
    return (
      this.campaign.status === CampaignStatus.DRAFT &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.editCampaignProducts
      ) &&
      ((type === CampaignProductType.SELL &&
          !this.campaign.story?.autoSyncSellProducts) ||
        (type === CampaignProductType.BUY &&
          !this.campaign.story?.autoSyncBuyProducts))
    );
  }

  private hasValidCampaignStatus(): boolean {
    return ![
      CampaignStatus.CLOSED,
      CampaignStatus.TERMINATED,
    ].includes(this.campaign.status);
  }

  allowEditContent(): boolean {
    const isDecentralized = this.campaign.decentralized;
    if (isDecentralized) {
      return this.hasValidCampaignStatus() &&
        this.permissionService.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit);
    }
    return (
      this.hasValidCampaignStatus() &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.editCampaignContents
      ) &&
      !this.campaign.story?.autoSyncContent &&
      !this.campaign.autoSyncContent
    );
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }

  /**
   * Updates the tabs and current tab based on previous and current campaign status, in the case
   * of the same status, nothing happens.
   * @param prevStatus
   * @param status
   */
  private updateTabs(prevStatus: CampaignStatus, status: CampaignStatus) {
    const isDecentralized = this.campaign.decentralized;
    if (prevStatus === status) {
      return;
    }
    if (status === this.campaignStatus.DRAFT) {
      this.tabs = [...(isDecentralized ? DECENTRALIZED_CAMPAIGN_OVERVIEW_PREVIEW_TABS : CAMPAIGN_OVERVIEW_PREVIEW_TABS)];
      this.defaultTab = this.tabs[0].text;
      this.activeTab = this.defaultTab;
      this.tabs[this.suitabilityTab].hidden = status != 'FROZEN'
    } else {
      this.tabs = [...(isDecentralized ? DECENTRALIZED_CAMPAIGN_OVERVIEW_TABS : CAMPAIGN_OVERVIEW_TABS)];
      if (this.activeTab != 'intermediariesPreview') {
        this.defaultTab = this.tabs[this.portfoliosTab].text;
      } else {
        this.defaultTab = this.tabs[this.intermediariesTab].text;
      }
      this.tabs[this.suitabilityTab].hidden = status != 'FROZEN'
      this.activeTab = this.defaultTab;
    }
  }

  intermediaryData: GridDataProvider = (listParams: ListParams) => {
    return this.intermediaryService.getIntermediariesPreview({
      hub: this.campaign.hub.ident,
      filter: convertFilterConfigToFilterBody(
        this.currentFilter || {},
        this.permissionService.userRoleData),
      listParams
    });
  }

  addIntermediaries() {
    const modalTitleRef = 'addIntermediaries';
    const modalData: ModalData = {
      type: EModalType.addCampaignIntermediaries,
      title: modalTitleRef,
      data: {entity: this.campaign},
      submitBtn: {
        label: this.translateService.instant('addIntermediaries'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: CampaignIntermediaryAddComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData, undefined, true, true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.intermediaryList.refreshGrid();
      }
    });
  }

  onSendToMyself() {
    const modalData: ModalData = {
      type: EModalType.sendToMyself,
      title: EModalType.sendToMyself,
      data: {
        campaign: this.campaign,
      },
      submitBtn: {
        label: this.translateService.instant('send')
      },
      cancelBtn: {
        label: this.translateService.instant('cancel')
      },
      component: CampaignSendToMyselfComponent,
    };
    this.modalService.openDefaultDialog(
      modalData, undefined, true, true, DialogWidth.HALF, DialogHeight.HALF
    );
  }

  onDeleting() {
    this.isDeleting = true;
  }

  protected switchToCompactOverview() {
    const data = {...this.permissionService.userRoleData, useCompactSend: !this.useCompactSend};
    this.userService.updateUserSettings(data).subscribe(updated => {
      this.permissionService.updateUser(updated);
    });
    this.userService.recordUserInteraction(
      data.useCompactSend ? UserInteraction.SWITCHEDTOQUICKSCREEN : UserInteraction.SWITCHEDTODETAILEDSCREEN
    ).pipe(first()).subscribe();
  }

  protected readonly ActionType = ActionType;
}
