import {Component, Input, OnInit} from "@angular/core";
import {ClientService, PublicationTypeSubscription, PublicationTypeSubscriptionType} from "../../../../api/core";
import {first} from "rxjs";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-client-publication-types',
  templateUrl: './client-publication-types.component.html'
})
export class ClientPublicationTypesComponent implements OnInit{

  @Input()
  clientId: number;

  publicationTypeDirectSend: PublicationTypeSubscription[] = [];
  publicationTypeOptOut: PublicationTypeSubscription[] = [];
  canEdit: boolean = false;
  showNote: boolean = false;
  selectedTabIndex = 0;

  constructor(
    private readonly clientService: ClientService,
    private readonly permissionService: PermissionService,
  ) {
    this.canEdit = this.permissionService.hasAnyPermission(EProtectedActions.editClient);
  }

  ngOnInit() {
    this.clientService.getClientPublicationTypes(this.clientId)
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeDirectSend = this.filter(data.publicationTypesDirectSend);
        this.publicationTypeOptOut = this.filter(data.publicationTypesOptOut);
        this.showNote = data.showNote;
      });
  }

  private filter(list: PublicationTypeSubscription[]): PublicationTypeSubscription[] {
    return list.filter(pt => pt.publicationTypeActive || !pt.type.closed);
  }

  onTabChange(index: number) {
    this.selectedTabIndex = index;
  }

  onDirectSendToggleChanged(ev: MatSlideToggleChange, pt: PublicationTypeSubscription) {
    this.clientService.setClientPublicationTypes(this.clientId, {
      type: PublicationTypeSubscriptionType.DIRECTSEND,
      publicationTypes: [{
        ...pt,
        publicationTypeActive: ev.checked
      }]
    })
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeDirectSend = this.filter(data.publicationTypesDirectSend);
      });
  }

  onOptOutToggleChanged(ev: MatSlideToggleChange, pt: PublicationTypeSubscription) {
    this.clientService.setClientPublicationTypes(this.clientId, {
      type: PublicationTypeSubscriptionType.OPTOUT,
      publicationTypes: [{
        ...pt,
        publicationTypeActive: ev.checked
      }]
    })
      .pipe(first())
      .subscribe(data => {
        this.publicationTypeOptOut = this.filter(data.publicationTypesOptOut);
      });
  }
}
