import {Component, OnDestroy} from "@angular/core";
import {
  CodeTableEntry,
  MonitoringBucket,
  MonitoringService,
  PublicationType,
  PublicationTypeEditMonitoring,
  PublicationTypeService
} from "../../../api/core";
import {finalize, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {PublicationTypeData} from "../../publication-type-data";
import {CodeTableService} from "../../../services/code-table.service";
import {ECodeTables, EModalType} from "../../../util/enum";
import {EProtectedActions} from "../../../util/protected-actions";
import {ColDef, GridOptions} from "ag-grid-community";
import {genCodeTableColumn, genTextColumn} from "../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../shared/grid/cell-renderers/icon-button.renderer";
import {DialogHeight, DialogWidth, ModalService} from "../../../services/modal.service";
import {ModalData} from "../../../models/modal.model";
import {BucketDialogComponent} from "../../../core/views/settings/buckets/bucket-dialog/bucket-dialog.component";
import {PermissionService} from "../../../services/permission.service";

type Option = {
  key: string;
  value: string;
}

@Component({
  selector: 'app-publication-type-monitoring',
  templateUrl: './publication-type-monitoring.component.html'
})
export class PublicationTypeMonitoringComponent implements OnDestroy {
  publicationType: PublicationType;
  subscriptions: Subscription[] = [];
  addBuckets: MonitoringBucket[] = [];
  hubs: CodeTableEntry[] = [];
  readonly: boolean = false;
  canAddBucket: boolean = false;

  columnDefs: ColDef[] = [
    genTextColumn('name', this.translateService.instant('name')),
    {
      ...genCodeTableColumn({
        field: 'hub',
        headerName: this.translateService.instant('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }),
    },
    genIconButtonColumn({
      callback: (data: MonitoringBucket) => this.onRemoveBucket(data),
      icon: 'playlist_remove',
      tooltip: this.translateService.instant('publicationTypeBucketRemoveTooltip'),
      hidden: (data) => data.hidden || this.readonly
    }),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  constructor(
    readonly translateService: TranslateService,
    readonly publicationTypeData: PublicationTypeData,
    readonly publicationTypeService: PublicationTypeService,
    readonly codeTableService: CodeTableService,
    readonly monitoringService: MonitoringService,
    readonly modalService: ModalService,
    readonly permissionService: PermissionService,
  ) {
    this.subscriptions.push(
      this.publicationTypeData.pubType$.subscribe(data =>  this.setPubType(data)),
    );
    this.codeTableService.getCodeTable(ECodeTables.hub).subscribe(data  => this.hubs = data);
    this.canAddBucket = this.permissionService.hasAnyPermission(EProtectedActions.isAdmin, EProtectedActions.publicationTypeEdit);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get protectedActions() {
    return EProtectedActions;
  }

  private setPubType(data: PublicationType) {
    this.publicationType = data;
    this.readonly = this.publicationTypeData.isReadonly(data);
    this.updateAddBuckets();
  }

  onSave(edit: PublicationTypeEditMonitoring) {
    this.publicationTypeData.setLoading(true);
    this.publicationTypeService.editPageMonitoring(this.publicationType.id, edit)
      .pipe(
        finalize(() => {
          this.publicationTypeData.setLoading(false);
        })
      ).subscribe(data => {
      this.publicationTypeData.setPublicationType(data);
    });
  }

  private updateAddBuckets() {
    this.publicationTypeService.getAvailableMonitoringBuckets(this.publicationType.id)
      .subscribe(data =>
      this.addBuckets = data.filter(b => !b.hidden)
    )
  }

  onAddBucket(bucket: MonitoringBucket) {
    this.onSave({addMonitoringBucket: bucket.id});
  }

  onCreateBucket() {
    const modalData: ModalData = {
      type: EModalType.bucketAdd,
      title: EModalType.bucketAdd,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        bucket: {
          name: '',
          hub: null,
          publicationTypes: [
            this.publicationType
          ]
        },
        hubs: this.hubs,
        publicationTypes: [
          this.publicationType
        ],
        hidePubTypes: true,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: BucketDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO,
      DialogHeight.AUTO,
    );
    ref.afterClosed().subscribe((result: MonitoringBucket | null) => {
      if (!result) return;
      this.monitoringService.createBucket(result).subscribe(_ => {
        this.publicationTypeService.getPublicationType(this.publicationType.id).subscribe(data => {
          this.publicationTypeData.setPublicationType(data);
        });
      });
    });
  }

  private onRemoveBucket(bucket: MonitoringBucket) {
    this.onSave({delMonitoringBucket: bucket.id});
  }
}
